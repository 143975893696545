/**
 * Created by haluk on 3/23/17.
 */

export default {
  getRouteFullPath: state => {
    return state.route.fullPath;
  },
  getRouterQueryActivationCode: state => {
    return state.route.query.code
  },
  getRouterQueryUUID: state => {
    return state.route.query.u
  },
  getRouterQueryType: state => {
    return state.route.query.type
  }
}
