import * as types from './mutation-types';
import helper from './helper';

export default {

  [types.SET_USER] (state, user) {
    state.isLoggedIn = true;
    state.user.uuid = user.uuid;
    state.user.id = user.id;
    state.user.info = user.info;
    state.user.status = user.status;
    state.user.firstname = user.firstname;
    state.user.lastname = user.lastname;
    state.user.username = user.username;
    state.user.email = user.email;
    state.user.avatar_url = user.avatar_url ? user.avatar_url : 'https://gcdn.bionluk.com/uploads/general/avatar_empty.png';
    state.user.editorRoles = user.editor_roles;

    state.user.seller = state.user.seller ? state.user.seller : {};
    for (let key in user.seller) {
      state.user.seller[key] = user.seller[key];
    }
    state.user.created_at = user.created_at;
    state.user.socialAccounts = user.socialAccounts;

    // lucky orange integration
    let customData = {
      'name' : user.username,
      'email' : user.email,
      'user_id' : user.id
    };

    //window._loq = window._loq || [];
    //window._loq.push(['custom', customData])
  },

  [types.UNSET_USER](state) {
    state.isLoggedIn = false;
    state.user.uuid = null;
    state.user.id = null;
    state.user.avatar_url = null;
    state.user.status = null;
    state.user.firstname = null;
    state.user.lastname = null;
    state.user.username = null;
    state.user.email = null;
    state.user.info = null;
    state.user.seller = {
      isSeller: null,
      seller_categories: []
    };
    state.user.created_at = null;
    state.slog = false;
  },

  [types.SET_USER_PUSHER_INFO](state, user) {
    user = helper.preparePusherInfo(user);
    state.user.channelID = user.channelID;
    state.user.unreadMessageCount = user.unreadMessageCount;
    state.user.unreadMessages = user.unreadMessages;
    state.user.unreadNotificationCount = user.unreadNotificationCount;
    state.user.requestCount = user.requestCount;
    state.user.followerCount = user.followerCount;
    state.user.followingCount = user.followingCount;
    state.user.likeCount = user.likeCount;
  },
  [types.SET_PERMISSIONS_CHANGES] (state, payload) {
    state.user.info.permissions[payload.key] = payload.value;
  },

  [types.SET_USER_CHANGES] (state, payload) {
    state.user[payload.key] = payload.value;
  },

  [types.SET_USER_INFO_CHANGES] (state, payload) {
    state.user.info[payload.key] = payload.value;
  },

  [types.SET_NAME_CHANGES] (state, payload) {
    state.user.firstname = payload.firstname;
    state.user.lastname = payload.lastname;
  },

  [types.SET_EMAIL_CHANGES] (state, payload) {
    state.user.email = payload.email;
  },

  [types.SET_PASSWORD_CHANGES] (state, payload) {
    state.user.password = payload.password;
  },
  [types.SET_COMPONENT_MAP] (state, componentMap) {
    state.componentMap = helper.specifyComponentType(componentMap);
  },
  [types.SET_MODAL_COMPONENT_MAP] (state, value) {
    state.modalComponentMap = value;
  },

  [types.SET_MESSAGE_COMPONENT] (state, value) {
    state.activeMessageComponent = value;
  },

  [types.ACTIVE_MODAL] (state, payload) {
    state.activeModal = {
      modalType: payload.modalType,
      description: payload.description,
      info: payload.info
    }
  },

  [types.DISABLE_MODAL] (state) {
    state.activeModal = {
      modalType: null,
      description: null
    }
  },

  [types.SET_VISITOR_ID](state, visitor_id) {
    state.visitorInfo.id = visitor_id
  }
}

