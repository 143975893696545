/**
 * Created by haluk on 6/29/17.
 */

import helper from './axiosHelper';

const retrieveFeedsURL = "/feed/get/";
const retrieveCommentsURL = "/feed/get_comments/";
const saveCommentURL = "/feed/new_comment/";
const retrieveBiaInfoURL = "/feed/get_bia_info/";
const retrieveBiaURL = "/feed/get_bia/";
const sendBiaAnalyticsURL = "/feed/bia_analytics/";
const deletePostURL = "/feed/delete_post/";
const featuredGigAnalyticsURL = "/feed/featured_analytics/";
const getFeedInfoURL = "/feed/get_feed_info/";
const newNpsURL = "/feed/new_nps/";
const newMainPageURL = "/feed/new_main_page/";
const deleteLastSeenItemURL = "/feed/delete_last_seen_item/";
const clickedURL = "/feed/clicked/";
const getOnlineUsersURL = "/feed/get_online_users/";
const addDynamicSegmentURL = "/feed/add_dynamic_segment/";
const markBuyerRequestSeenURL = "/feed/mark_buyer_request_seen/";
const hideBuyerRequestBoxURL = "/feed/hide_buyer_request_box/";
const retrieveBiaV2URL = "/feed/get_bia_v2/";
const getShowCaseItemsURL = "/feed/get_show_case_items/";

export default {
  retrieveFeeds(limit, offset) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveFeedsURL, false, helper.createFormData({ limit, offset }));
  },

  retrieveComments(type, id, limit, offset, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveCommentsURL, progress, helper.createFormData({ type, id, limit, offset }));
  },

  saveComment(type, id, comment, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", saveCommentURL, progress, helper.createFormData({ type, id, comment }));
  },

  retrieveBiaInfo(progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveBiaInfoURL, progress);
  },

  retrieveBiaUsers(category_id, category_sub_id, service_type_id, limit, offset, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveBiaURL, progress, helper.createFormData({ category_id, category_sub_id, service_type_id, limit, offset }));
  },

  retrieveBiaUsersV2(category_id, category_sub_id, service_type_id, service_kind_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveBiaV2URL, progress, helper.createFormData({ category_id, category_sub_id, service_type_id,service_kind_id }));
  },

  sendBiaAnalytics(username, type, category_sub_id=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", sendBiaAnalyticsURL, null, helper.createFormData({ username, type, category_sub_id }));
  },

  deletePost(post_id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deletePostURL, null, helper.createFormData({ post_id }));
  },

  featuredGigAnalytics(featured_gigs_id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", featuredGigAnalyticsURL, false, helper.createFormData({ featured_gigs_id }))
  },

  retrieveFeedInfo() {
    return helper.axiosWrapper(helper.axiosInstance, "post", getFeedInfoURL, false)
  },

  newMainPage(type=null, kind=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", newMainPageURL, false, helper.createFormData({type, kind}))
  },

  newNps(id, score, description) {
    return helper.axiosWrapper(helper.axiosInstance, "post", newNpsURL, false, helper.createFormData({ id, score, description }))
  },

  deleteLastSeenItem(id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteLastSeenItemURL, false, helper.createFormData({ id }))
  },

  clicked(what) {
    return helper.axiosWrapper(helper.axiosInstance, "post", clickedURL, false, helper.createFormData({ what }))
  },

  getOnlineUsers(what) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getOnlineUsersURL, false, helper.createFormData({ what }))
  },
  addDynamicSegment() {
    return helper.axiosWrapper(helper.axiosInstance, "post", addDynamicSegmentURL, false)
  },
  markBuyerRequestSeen(request_id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", markBuyerRequestSeenURL, false, helper.createFormData({ request_id }))
  },
  getShowCaseItems(obj, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getShowCaseItemsURL, progress, helper.createFormData(obj))
  },
  hideBuyerRequestBox() {
    return helper.axiosWrapper(helper.axiosInstance, "post", hideBuyerRequestBoxURL, false)
  },


}
