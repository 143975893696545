export default {
  'activate-outer-modal-scroll': {
    bind(el, binding, vnode, oldvnode) {

      document.body.style.overflow = "hidden";
      let modal = el.childNodes[0];

      function wheel(event) {
        function handle(delta) {
          let top = modal.scrollTop - delta;
          if (modal.scroll) {
            modal.scroll(0, top);
          } else {
            modal.scrollTop = top;
          }
        }

        let delta = 0;
        if (!event) /* IE. için */
          event = window.event;
        if (event.wheelDelta) { /* IE/Opera. */
          delta = event.wheelDelta / 5;
          /**sayı düşerse hız artar **/
        } else if (event.detail) {
          delta = -event.detail * 10;
          /* Mozilla */
        }

        if (delta) handle(delta);

        //          if (event.preventDefault) event.preventDefault(); /** Bu kod parçaçığı body nin eventini false döndürüyor, aynı etki body'i hidden yaparak alınabliyor **/
        //          event.returnValue = false;
      }

      if (window.addEventListener) {
        window.addEventListener('DOMMouseScroll', wheel, false);
        window.addEventListener('mousewheel', wheel, false);
      }
      window.onmousewheel = document.onmousewheel = wheel;
    },
    unbind(el) {
      document.body.style.overflow = "";

      function wheel(event) {
        function handle(delta) {
          let top = modal.scrollTop - delta;
          if (modal.scroll) {
            modal.scroll(0, top);
          } else {
            modal.scrollTop = top;
          }
        }

        let delta = 0;
        if (!event) /* IE. için */
          event = window.event;
        if (event.wheelDelta) { /* IE/Opera. */
          delta = event.wheelDelta / 5;
          /**sayı düşerse hız artar **/
        } else if (event.detail) {
          delta = -event.detail * 10;
          /* Mozilla */
        }

        if (delta) handle(delta);

        //          if (event.preventDefault) event.preventDefault(); /** Bu kod parçaçığı body nin eventini false döndürüyor, aynı etki body'i hidden yaparak alınabliyor **/
        //          event.returnValue = false;
      }

      window.removeEventListener('DOMMouseScroll', wheel, false);
      window.removeEventListener('mousewheel', wheel, false);
    }

  },

  'disable-body-scroll': {
    bind(el, binding, vnode, oldvnode) {
      function mouseWheel(event) {
        let delta = 0;

        if (!event) /* IE. için */
          event = window.event;
        if (event.wheelDelta) { /* IE/Opera. */
          delta = event.wheelDelta / 5;
          /**sayı düşerse hız artar **/
        } else if (event.detail) {
          delta = -event.detail * 10;
          /* Mozilla */
        }

        if (delta) {
          let top = el.scrollTop - delta;
          if (el.scroll) {
            el.scroll(0, top);
          } else {
            el.scrollTop = top;
          }
        }
        event.preventDefault();

      }

      el.addEventListener('mousewheel', mouseWheel, false);
      el.addEventListener('DOMMouseScroll', mouseWheel, false);

    },
    unbind(el, binding, vnode, oldVnode) {
      function mouseWheel(event) {
        let delta = 0;

        if (!event) /* IE. için */
          event = window.event;
        if (event.wheelDelta) { /* IE/Opera. */
          delta = event.wheelDelta / 10;
          /**sayı düşerse hız artar **/
        } else if (event.detail) {
          delta = -event.detail * 10;
          /* Mozilla */
        }

        if (delta) {
          let top = el.scrollTop - delta;
          if (el.scroll) {
            el.scroll(0, top);
          } else {
            el.scrollTop = top;
          }
        }
        event.preventDefault();

      }

      el.removeEventListener('mousewheel', mouseWheel, false);
      el.removeEventListener('DOMMouseScroll', mouseWheel, false);

    }
  },

  'click-outside': {
    bind(el, binding, vnode, oldvnode) {
      el.addEventListener('click', stopProp);
      setTimeout(function () {
        document.body.addEventListener('click', binding.value)
      }, 0);
    },

    unbind(el, binding, vnode, oldvnode) {
      el.removeEventListener('click', stopProp);
      document.body.removeEventListener('click', binding.value)
    },
  },


  'loader-screen': {
    componentUpdated(el, binding, vnode, oldvnode) {
      let container = el.parentNode;

      let node = document.createElement("div");
      let hasChild = container.querySelector("#loader-with-blur") !== null;
      if (binding.value && !hasChild) {
        node.setAttribute("id", "loader-with-blur");
        node.className = "loader";
        node.style.position = "absolute";
        node.style.width = "50px";
        node.style.height = "50px";
        node.style.top = "0";
        node.style.bottom = "0";
        node.style.right = "0";
        node.style.left = "0";
        node.style.margin = "auto";
        node.style.zIndex = "9999999";
        el.style.filter = "blur(5px)";
        // document.body.style.pointerEvents = "none";
        if (container && node) {
          node.setAttribute("contentEditable", "true");
          container.appendChild(node);
        }
      }
      let removeDiv = document.getElementById("loader-with-blur");
      if (binding.value === false && hasChild) {
        container.removeChild(removeDiv);
        // document.body.style.pointerEvents = "";
        el.style.filter = "";
      }
    },
    unbind(el, binding, vnode, oldVnode) {
      let container = el.parentNode;
      let hasChild = container.querySelector("#loader-with-blur") !== null;
      let removeDiv = document.getElementById("loader-with-blur");
      if (binding.value === false && hasChild) {
        container.removeChild(removeDiv);
        // document.body.style.pointerEvents = "";
        el.style.filter = "";
      }
    }
  },

  'click-away': {
    bind(el, binding, vnode) {
      unbind(el);

      let vm = vnode.context;

      let callback = binding.value;
      let initialMacrotaskEnded = false;
      setTimeout(function () {
        initialMacrotaskEnded = true;
      }, 0);

      el[HANDLER] = function (ev) {
        let path = ev.path || (ev.composedPath ? ev.composedPath() : undefined);
        if (initialMacrotaskEnded && (path ? path.indexOf(el) < 0 : !el.contains(ev.target))) {
          return callback.call(vm, ev);
        }
      };

      document.documentElement.addEventListener('click', el[HANDLER], false);
    },

    unbind(el) {
      document.documentElement.removeEventListener('click', el[HANDLER], false);
      delete el[HANDLER];
    }

  }
}

let HANDLER = 'click-away';
function unbind(el) {
  document.documentElement.removeEventListener('click', el[HANDLER], false);
  delete el[HANDLER];
}

function stopProp(event) {
  event.stopPropagation();
}
