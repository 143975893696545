/**
 * Created by haluk on 3/23/17.
 */


export const SET_USER = "SET_USER";

export const SET_NAME_CHANGES = "SET_NAME_CHANGES";
export const SET_EMAIL_CHANGES = "SET_EMAIL_CHANGES";
export const SET_PASSWORD_CHANGES = "SET_PASSWORD_CHANGES";
export const SET_PERMISSIONS_CHANGES = "SET_PERMISSIONS_CHANGES";
export const SET_USER_CHANGES = "SET_USER_CHANGES";
export const SET_USER_INFO_CHANGES = "SET_USER_INFO_CHANGES";
export const SET_USER_PUSHER_INFO = "SET_USER_PUSHER_INFO";


export const UNSET_USER = "UNSET_USER";

export const SET_COMPONENT_MAP = "SET_COMPONENT_MAP";
export const SET_MESSAGE_COMPONENT = "SET_MESSAGE_COMPONENT";

export const SET_MODAL_COMPONENT_MAP = "SET_MODAL_COMPONENT_MAP";
export const ACTIVE_MODAL = "ACTIVE_MODAL";
export const DISABLE_MODAL = "DISABLE_MODAL";

export const SET_VISITOR_ID = "SET_VISITOR_ID";
