<template>
  <div id="fountainG">
    <div id="fountainG_1" class="fountainG"></div>
    <div id="fountainG_2" class="fountainG"></div>
    <div id="fountainG_3" class="fountainG"></div>
    <div id="fountainG_4" class="fountainG"></div>
    <div id="fountainG_5" class="fountainG"></div>
  </div>
</template>

<script>

	export default {
		name: "lineLoader",
		data() {
			return {}
		}
	}

</script>


<style scoped>
  /*

   */
  #fountainG{
    position:relative;
    width:72px;
    height:9px;
    margin:auto;
  }

  .fountainG{
    position:absolute;
    top:0;
    background-color:rgb(0,0,0);
    width:9px;
    height:9px;
    animation-name:bounce_fountainG;
    -o-animation-name:bounce_fountainG;
    -ms-animation-name:bounce_fountainG;
    -webkit-animation-name:bounce_fountainG;
    -moz-animation-name:bounce_fountainG;
    animation-duration:2.28s;
    -o-animation-duration:2.28s;
    -ms-animation-duration:2.28s;
    -webkit-animation-duration:2.28s;
    -moz-animation-duration:2.28s;
    animation-iteration-count:infinite;
    -o-animation-iteration-count:infinite;
    -ms-animation-iteration-count:infinite;
    -webkit-animation-iteration-count:infinite;
    -moz-animation-iteration-count:infinite;
    animation-direction:normal;
    -o-animation-direction:normal;
    -ms-animation-direction:normal;
    -webkit-animation-direction:normal;
    -moz-animation-direction:normal;
    transform:scale(.3);
    -o-transform:scale(.3);
    -ms-transform:scale(.3);
    -webkit-transform:scale(.3);
    -moz-transform:scale(.3);
    border-radius:6px;
    -o-border-radius:6px;
    -ms-border-radius:6px;
    -webkit-border-radius:6px;
    -moz-border-radius:6px;
  }

  #fountainG_1{
    left:0;
    animation-delay:0.91s;
    -o-animation-delay:0.91s;
    -ms-animation-delay:0.91s;
    -webkit-animation-delay:0.91s;
    -moz-animation-delay:0.91s;
  }

  #fountainG_2{
    left:9px;
    animation-delay:1.14s;
    -o-animation-delay:1.14s;
    -ms-animation-delay:1.14s;
    -webkit-animation-delay:1.14s;
    -moz-animation-delay:1.14s;
  }

  #fountainG_3{
    left:18px;
    animation-delay:1.37s;
    -o-animation-delay:1.37s;
    -ms-animation-delay:1.37s;
    -webkit-animation-delay:1.37s;
    -moz-animation-delay:1.37s;
  }

  #fountainG_4{
    left:27px;
    animation-delay:1.59s;
    -o-animation-delay:1.59s;
    -ms-animation-delay:1.59s;
    -webkit-animation-delay:1.59s;
    -moz-animation-delay:1.59s;
  }


  #fountainG_5{
    left:36px;
    animation-delay:1.82s;
    -o-animation-delay:1.82s;
    -ms-animation-delay:1.82s;
    -webkit-animation-delay:1.82s;
    -moz-animation-delay:1.82s;
  }



  @keyframes bounce_fountainG{
    0%{
      transform:scale(1);
      background-color:rgb(0,0,0);
    }

    100%{
      transform:scale(.3);
      background-color:rgb(255,255,255);
    }
  }

  @-o-keyframes bounce_fountainG{
    0%{
      -o-transform:scale(1);
      background-color:rgb(0,0,0);
    }

    100%{
      -o-transform:scale(.3);
      background-color:rgb(255,255,255);
    }
  }

  @-ms-keyframes bounce_fountainG{
    0%{
      -ms-transform:scale(1);
      background-color:rgb(0,0,0);
    }

    100%{
      -ms-transform:scale(.3);
      background-color:rgb(255,255,255);
    }
  }

  @-webkit-keyframes bounce_fountainG{
    0%{
      -webkit-transform:scale(1);
      background-color:rgb(0,0,0);
    }

    100%{
      -webkit-transform:scale(.3);
      background-color:rgb(255,255,255);
    }
  }

  @-moz-keyframes bounce_fountainG{
    0%{
      -moz-transform:scale(1);
      background-color:rgb(0,0,0);
    }

    100%{
      -moz-transform:scale(.3);
      background-color:rgb(255,255,255);
    }
  }
</style>
