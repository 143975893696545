/**
 * Created by haluk on 5/25/17.
 */

import pathToRegexp from 'path-to-regexp';
import store from '../store';

export default {
  specifyRoute(path, routerMap) {
    let componentMap = routerMap['/*'];
    store.state.routerParams = [];
    if (routerMap.hasOwnProperty(path)) {
      componentMap = routerMap[path];
      if (!componentMap.redirect_url) {
        // statedeki değişimlere göre yeni değer app.vue üzerinde atandığı için her statik path için statede güncelleme yapmak gerekiyor. Aşağıdaki document.title setlemesine gerek kalmayabilir kontrol edilmeli.
       store.state.indexHTMLTitle = componentMap.metaTitle;
       store.state.indexHTMLMetaDescription = componentMap.metaDescription;
       document.title = componentMap.metaTitle;
       document.head.querySelector('meta[name=description]').content = componentMap.metaDescription;

      }

    } else {
      let reMap = {};

      for (let key in routerMap) {
        if (key.split(':id').length === 2) {
          reMap[key] = pathToRegexp(key);
        }
      }

      let foundPath = null;
      for (let key in reMap) {
        foundPath = reMap[key].exec(path);
        if (foundPath) {
          foundPath = {
            actualPath: key,
            result: foundPath
          };
          break;
        }
      }

      if (foundPath) {
        componentMap = routerMap[foundPath.actualPath];
        foundPath.result.shift();
        store.state.routerParams = foundPath.result;
      } else {
        let reMap = {};

        for (let key in routerMap) {
          if (key.split(':id').length === 3) {
            reMap[key] = pathToRegexp(key);
          }
        }

        for (let key in reMap) {
          foundPath = reMap[key].exec(path);
          if (foundPath) {
            foundPath = {
              actualPath: key,
              result: foundPath
            };
            break;
          }
        }

        if (foundPath) {
          componentMap = routerMap[foundPath.actualPath];
          foundPath.result.shift();
          store.state.routerParams = foundPath.result;
        }

      }
    }

    return componentMap;
  }
}
