// asenkron istekler burda yapılacak


export default {
  /*
  login({commit}) {
    commit(types.USER_LOGIN);
  },
  */

}
