/**
 * Created by haluk on 6/15/17.
 */

import helper from './axiosHelper';

const giveOrderURL = "/orders/create_order/";
const checkoutURL = "/orders/checkout/";
const makePaymentURL = "/orders/pay/";
const makeSinglePaymentURL = "/orders/single_pay/";
const makeItemPaymentURL = "/orders/item_pay_cg/";
const getOrderURL = "/orders/detail/";
const getOrderURLV2 = "/orders/detailv2/";
const giveShowRealJobPermissionURL = "/orders/give_show_real_job_permission/";
const hideShowRealJobBoxURL =  "/orders/hide_show_real_job_box/";
const retrieveOrdersURL = "/orders/get_all/";
const startOrderURL = "/orders/start/";
const startOrderForBuyerURL= "/orders/start_for_buyer/";
const orderCronActionURL= "/general/order_cron_action/";
const deliverOrderURL = "/orders/deliver/";
const approveOrderURL = "/orders/approve/";
const revisionOrderURL = "/orders/revision/";
const addToPortfolioURL = "/orders/add_to_portfolio/";
const rateSellerOrderURL = "/orders/rateSeller/";
const rateBuyerOrderURL = "/orders/rateBuyer/";
const payCreditURL = "/orders/pay_credit/";
const saveContactInfoURL = "/orders/save_contact_info/";
const editOrderURL = "/orders/edit_order/";
const forgetCardURL = "/orders/forget_card/";
const cardBinURL = "/orders/bin/";
const saveCardURL = "/orders/save_card/";



export default {
  editOrder(order_id, quantity, words, extras, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", editOrderURL, progress, helper.createFormData({ order_id, quantity, words, extras }));
  },

  giveOrder(type, external_id, quantity, words, gig_extras, package_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", giveOrderURL, progress, helper.createFormData({ type, external_id, quantity, words, gig_extras, package_id }));
  },

  checkout(order_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", checkoutURL, progress, helper.createFormData({ order_id }));
  },

  makePayment(payment, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", makePaymentURL, progress, helper.createFormData(payment));
  },

  makeSinglePayment(payment, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", makeSinglePaymentURL, progress, helper.createFormData(payment));
  },

  makeItemPayment(payment, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", makeItemPaymentURL, progress, helper.createFormData(payment));
  },

  getOrder(order_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getOrderURL, progress, helper.createFormData({ order_id }));
  },

  getOrderv2(order_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getOrderURLV2, progress, helper.createFormData({ order_id }));
  },
  giveShowRealJobPermission(order_id, show_real_job, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", giveShowRealJobPermissionURL, progress, helper.createFormData({ order_id, show_real_job }));
  },

  hideShowRealJobBox(order_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", hideShowRealJobBoxURL, progress, helper.createFormData({ order_id }));
  },


  retrieveOrders(type, status, limit, offset, username, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveOrdersURL, progress, helper.createFormData({ type, status, limit, offset, username }));
  },

  startOrder(message, upload_ids, order_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", startOrderURL, progress, helper.createFormData({ message, upload_ids, order_id }));
  },

  startOrderForBuyer(order_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", startOrderForBuyerURL, progress, helper.createFormData({ order_id }));
  },

  orderCronAction(id, order_id, type, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", orderCronActionURL, progress, helper.createFormData({ id, order_id, type }));
  },

  deliverOrder(message, upload_ids, order_id, cover_upload_id = null, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deliverOrderURL, progress, helper.createFormData({ message, upload_ids, order_id, cover_upload_id }));
  },

  approveOrder(order_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", approveOrderURL, progress, helper.createFormData({ order_id }));
  },

  revisionOrder(message, upload_ids, order_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", revisionOrderURL, progress, helper.createFormData({ message, upload_ids, order_id }));
  },

  addToPortfolio(selected_portfolio_id, upload_ids, order_id, portfolio_title, portfolio_description, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", addToPortfolioURL, progress, helper.createFormData({ selected_portfolio_id, upload_ids, order_id, portfolio_title, portfolio_description }));
  },

  rateSeller(rateInfo, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", rateSellerOrderURL, progress, helper.createFormData(rateInfo));
  },

  rateBuyer(rateInfo, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", rateBuyerOrderURL, progress, helper.createFormData(rateInfo));
  },

  payCredit(payment, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", payCreditURL, progress, helper.createFormData(payment));
  },

  saveContactInfo(firstname, lastname, gsm) {
    return helper.axiosWrapper(helper.axiosInstance, "post", saveContactInfoURL, false, helper.createFormData({firstname, lastname, gsm}));
  },

  forgetCard(card_id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", forgetCardURL, false, helper.createFormData({card_id}));
  },

  cardBin(bin) {
    return helper.axiosWrapper(helper.axiosInstance, "post", cardBinURL, false, helper.createFormData({bin}));
  },

  saveCard(cc_name, cc_no, cc_cvc2, cc_month, cc_year) {
    return helper.axiosWrapper(helper.axiosInstance, "post", saveCardURL, false, helper.createFormData({cc_name, cc_no, cc_cvc2, cc_month, cc_year}));
  },

}
