/**
 * Created by haluk on 5/25/17.
 */
import axios from 'axios'
import EventBus from '../eventBus'
import vue from "../main"

axios.defaults.responseType= 'json'

export default {
  axiosInstance: axios.create({
      baseURL: "/api"
    }),

  axiosWrapper(instance, method, url, progress, params, headers, options) {
    return new Promise(function (resolve, reject) {
      let config = options ? options : {}


      config.headers = headers ? headers : {
        'Content-Type': 'application/x-www-form-urlencoded; Charset=utf-8',
        //'Content-Type': 'multipart/form-data',
        'SUPER-TOKEN': localStorage.getItem('token') || null,
        'SUPER-KEY': '1e291318-f4b6-4a65-8323-a1823dbd7564',
      }

      if (!headers) {
        let visitorId = localStorage.getItem('visitor_id')
        if (visitorId) {
          config.headers['SUPER-VISITOR'] = visitorId
        }

        if (sessionStorage.getItem('referrer') === '' || sessionStorage.getItem('referrer') === null) {
          sessionStorage.setItem("referrer", document.referrer)
          //_for_first_user_event"
          sessionStorage.setItem("temp_referrer", document.referrer)
          config.headers['SUPER-REFERRER'] = document.referrer
        }

      }

      if (progress) {
        EventBus.$emit("activateProgressBar")
      }

      if (method === "post" || method === "put") {
        instance[method](url, params, config)
        .then(result => {
          if (progress) {
            EventBus.$emit("deactivateProgressBar")
          }
          resolve(result)
        })
        .catch(function(err) {
          if (err && err.response && err.response.status === 401) {
            vue.$toasted.global.errorToast({ description: 'Oturumun sona erdi' })
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('visitor_id')
            window.location.reload(true)
          }
          if (err && err.code === "ECONNABORTED") {
            EventBus.$emit("timeout")
            reject(err)
          } else {
            if (progress)
              progress.fail()

            if (axios.isCancel(err)) {
              reject(err)
            } else {
              reject(err)
            }
          }
        })
      } else {
        instance[method](url, config)
        .then(result => {

          if (progress) {
            EventBus.$emit("deactivateProgressBar")
          }

          resolve(result)
        })
        .catch(function(err) {
          if (err && err.response && err.response.status === 401) {
            vue.$toasted.global.errorToast({ description: 'Oturumun sona erdi' })
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('visitor_id')
            window.location.reload(true)
          }

          if (progress)
            progress.fail()

          if (axios.isCancel(err)) {
            reject(err)
          } else {
            reject(err)
          }
        })
      }
    })
  },

  createFormData(sentData) {
    sentData = JSON.parse(JSON.stringify(sentData))
    const Data = new FormData()
    for(let key in sentData) {
      if (sentData.hasOwnProperty(key)) {
        Data.append(key, sentData[key])
      }
    }
    return Data
  }
}
