export default {
  ERROR : "error",
  PORTFOLIO : "portfolio",
  EDIT_PORTFOLIO: "editPortfolio",
  CUSTOM_OFFER: "customOffer",
  CUSTOM_OFFER_DETAIL: "customOfferDetail",
  GET_LIKED_USERS: "getLikedUsers",
  WITHDRAW_FUNDS: "withdrawFunds",
  CROPPER: "cropper",
  CHECKOUT: "checkout",
  GIG_CATEGORY_FILTER: "gigCategoryFilter",
  SEARCH: "search",
  USER_SEARCH: "userSearch",
  ORDER_CONTACT_INFO: "orderContactInfo",
  ORDER_ACTION: "orderAction",
  CLOSE_ACCOUNT: "closeAccount",
  COMPLAINT: "complaint",
  REPORT: "report",
  DISINTERMEDIATION: "disintermediationModal",
  USE_BICOIN: "useBicoin",
  BICOIN_FAQ: "bicoinFaq",
  FOR_FREELANCERS: "forFreelancers",
  ORDER_INFO: "orderInfo",
  UNLOCK_CHAT: "unlockChat",
  BLOG_DETAIL: "blogDetail",
  FREELANCER_LING_FAQ: "freelancerLineFaq",
  FREELANCER_CHAT_LINE: "freelancerChatLine",
  INFO_MODAL: "infoModal",
  PRICING: "pricing",
  GALLERY: "gallery",
  WARNING_MODAL: 'globalWarningModal',
  ADD_NEW_CARD: 'addNewCard',
  PHONE_VERIFICATION: 'phoneVerification',
  CHANGE_EMAIL: 'changeEmail',
  CHANGE_LINE: 'changeLine',
  CLOSE_SESSION: 'closeSession',
  WELCOME_SELLER: 'welcomeSeller',
  BIA_START: "biaStart",
  ONBOARDING: "onboarding",
  UPLOAD_GALERY: "uploadGalery",
  EDIT_GIG_FEATURED_PORTFOLIO: "editGigFeaturedPortfolio",
  EDITOR_GIGS: "editorGigs",
  ADD_LIST:"addList"

}
