<template>
  <div style="margin-top: 20px; margin-left: 10px">Bu pencere otomatik olarak kapanacak.</div>
</template>

<script>
export default {
  name: "newDevice",
  data() {
    return {
      isStoreCallback: false
    }
  },
  mounted() {
    if(this.$store.getters.getRouterQueryActivationCode && this.$store.getters.getRouterQueryUUID) {
      this.api.user.dl(this.$store.getters.getRouterQueryActivationCode, this.$store.getters.getRouterQueryUUID,this.$store.getters.getRouterQueryType )
        .then(({data}) => {
          let result = data;
          if(result.success) {
            this.$toasted.global.infoToast({description: result.message});
            if(confirm(result.message)){
              location.href = '/'
            } else {
              location.href = '/'
            }
          } else {
            this.$toasted.global.errorToast({description: result.message});
            if(confirm(result.message)){
              location.href = '/'
            } else {
              location.href = '/'
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    } else {
      this.$toasted.global.errorToast({description: 'hata 203'});
    }
  }
}
</script>

<style scoped>
.btn{background-color:#00bed5 ;border:2px solid #00bed5 ;color:#fff;display:inline-block;font-weight:700;letter-spacing: 0.1em;max-width:200px;padding:10px;text-decoration:none;transition:all .3s ease-in-out;width:80%}
p{color:#b8b8b8 ;margin-top:5vh;padding:0 20px}
</style>
