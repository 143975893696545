import Vue from 'vue'
import Router from 'vue-router'

/* DETAY BURADA: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378*/
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
};

import store from '../store';
import * as types from '../store/mutation-types';

import routerHelper from './helper';
import pageHelper from '../pages/helper';
import EventBus from '../eventBus';
import globalMixin from '../globalMixin';

// lazy load
const Main = resolve => require(['../pages/Main.vue'], resolve);

import LoginCallback from '../pages/body/auth/loginCallback'
import NewDevice from '../pages/body/auth/new_device'

Vue.use(Router);

let routes = [
  {
    path: '/login/callback',
    component: LoginCallback
  },
  {
    path: '/dl',
    component: NewDevice
  },
  {
    path: '*',
    component: Main
  }
];


const router = new Router({
  routes: routes,
  mode: 'history'
});


function guardRoute(to, from, next) {
  globalMixin.methods.init(true, to, from)
    .then(function (result) {
      if (result.redirect_url) { //initten false gelmisse ve redirect url varsa
        next(result.redirect_url)
      } else {
        //for tracking
        let previousPage = routerHelper.specifyRoute(from.path, store.state.routerMap);
        store.state.previousComponentPage = previousPage.page || null;
        let componentMap = routerHelper.specifyRoute(to.path, store.state.routerMap);
        if (componentMap.redirect_url) {
          // query ve bir sayfaya yonlendirme var ise query ile beraber yonlendirsin
          let query = '';
          for (let key in to.query) {
            query = query + (query.length > 0 ? '&' : '') + key + '=' + to.query[key];
          }
          if(query.length > 0) {
            next(componentMap.redirect_url + '?' + query);
          } else {
            next(componentMap.redirect_url);
          }
        } else {
          store.commit(types.SET_COMPONENT_MAP, componentMap);
          if (!componentMap.dontSavePreviousURL) {
            store.state.redirectPreviousURL = {path: to.path, query: to.query};
          }
          next();
        }
      }
      EventBus.$emit("deactivateProgressBar");
    })
    .catch(function (err) {

      pageHelper.trackEvents.pageView({err: JSON.stringify(err), to: to.path, from: from.path}, "errorpage", "v1");
      store.state.calledInit = true;
      next('/500');
    });
}

router.beforeEach((to, from, next) => {
  if (to.path === '/login/callback') {
    store.state.calledInit = true
    EventBus.$emit("deactivateProgressBar")
    return next()
  }
  if (to.path.includes('/blog/') || to.path === '/blog') {
    location.href = `https://bionluk.com${to.fullPath}`
    EventBus.$emit("deactivateProgressBar")
    return next()
  }
  if (to.query.openapp === 'true') {
    return next({
      query: to.query,
      path: '/login/callback'
    })
  }
  if (to.path.length > 1 && to.path[to.path.length - 1] === "/") {
    const redirectedPath = to.path.slice(0, to.path.length - 1)
    globalMixin.methods.sendPrerender302(redirectedPath, '301', true)
    next(redirectedPath)
  } else {
    // to prevent progress on gig detail hash change
    const hasOnlyHashChange = (to.path === from.path) && (to.hash && to.hash.length)
    if (store.getters.getRouteFullPath !== to.fullPath && !hasOnlyHashChange) {
      EventBus.$emit("activateProgressBar");
    }


    if (to.path === "/biyzib") {
      EventBus.$emit("deactivateProgressBar");
      store.commit(types.SET_COMPONENT_MAP, {body: "iyzi3ds/v1", footer: "shared/vno", header: "shared/vno"});
      next();
      return;
    }

    if (to.path === "/biyzibApplication") {
      EventBus.$emit("deactivateProgressBar");
      store.commit(types.SET_COMPONENT_MAP, {body: "iyzi3ds/v1", footer: "shared/vno", header: "shared/vno"});
      next();
      return;
    }


    if (to.path !== "/500" || !store.state.calledInit) {
      guardRoute(to, from, next);
    } else {
      EventBus.$emit("deactivateProgressBar");
      store.commit(types.SET_COMPONENT_MAP, {body: "serverError/v1", footer: "shared/vno", header: "shared/simple_error"});
      next();
    }
  }
});

export default router
