import { differenceInDays, differenceInSeconds, format, getMonth, getYear, getDay, formatDistance } from 'date-fns'
import { tr } from 'date-fns/locale'

export function customDateFormat(date, formatContext = 'dd MMM yyyy HH:mm') {
  if (!date) {
    return '...'
  }
  return format(Number(date), formatContext, { locale: tr })
}

export function fromNow(date) {
  if (!date) {
    return '-'
  }

  let formatted = formatDistance(date, new Date(), { addSuffix: true, locale: tr })

  if (formatted.includes('dan fazla önce')) {
    formatted = formatted.replace('dan fazla', '')
    const split = formatted.split(' ')
    split[0] = String(Number(split[0]) + 1)
    formatted = split.join(' ')
  }

  return formatted
    .replace('neredeyse ', '') // converts 'neredeyse ... önce' to '... önce'
    .replace('bir dakikadan ', '') // converts 'bir dakikadan az önce' to 'az önce'
    .replace('yaklaşık ', '') // converts 'yaklaşık ... önce' to '... önce'
}

export function diffFromNow(date, type) {
  if (type === 'days') {
    return Math.abs(differenceInDays(date, new Date()))
  } else if (type === 'seconds') {
    return Math.abs(differenceInSeconds(date, new Date()))
  }
}

export function getCurrentYear() {
  return getYear(new Date())
}

export function getCurrentMonth() {
  return getMonth(new Date()) + 1
}

export function getDayOfWeek(date = new Date()) {
  return getDay(date)
}
