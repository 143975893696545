
import helper from './axiosHelper';

let callURL = '/chat/call/';
let isCallableURL = '/chat/is_callable/';
let callCheckURL = '/chat/call_check/';
let pushCallInfoURL = '/chat/pushCallInfo/';
let changeArchiveStatusURL = '/chat/change_status/';
let reportConversationURL = '/chat/report_conversation/';
let chatDisintermediationURL = "/chat/disintermediation_agree/";
let unlockChatURL = "/chat/unlock/";
let deleteMessageURL = "/chat/delete_message/";

export default {
  retrieveCallUserToken(r_username, callType, control, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", callURL, false, helper.createFormData({ r_username, callType, control }));
  },

  checkIsCallable(r_username, callType) {
    return helper.axiosWrapper(helper.axiosInstance, "post", isCallableURL, false, helper.createFormData({ r_username, callType }));
  },

  checkCallToConnectSession(receiver_username, caller_username, conversation_id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", callCheckURL, false, helper.createFormData({ receiver_username, caller_username, conversation_id }));
  },

  pushCallInfo(repostInfo) {
    return helper.axiosWrapper(helper.axiosInstance, "post", pushCallInfoURL, false, helper.createFormData(repostInfo));
  },

  changeArchiveStatus(r_username, status) {
    return helper.axiosWrapper(helper.axiosInstance, "post", changeArchiveStatusURL, false, helper.createFormData({r_username, status}));
  },

  reportConversation(c_uuid, type, message) {
    return helper.axiosWrapper(helper.axiosInstance, "post", reportConversationURL, false, helper.createFormData({c_uuid, type, message}));
  },

  chatDisintermediation() {
    return helper.axiosWrapper(helper.axiosInstance, "post", chatDisintermediationURL, false)
  },

  unlockChat(c_uuid) {
    return helper.axiosWrapper(helper.axiosInstance, "post", unlockChatURL, false, helper.createFormData({ c_uuid }))
  },

  deleteMessage(id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteMessageURL, false, helper.createFormData({ id }))
  }
}
