/**
 * Created by haluk on 5/25/17.
 */

import general from './general';
import seller from './seller';
import user from './user';
import workstation from './workstation';
import content from './content';
import orders from './orders';
import feeds from './feed';
import external from './external';
import chat from './chat';
import request from './request';


export default {
  general,
  seller,
  user,
  workstation,
  content,
  orders,
  feeds,
  external,
  chat,
  request
}
