/**
 * Created by haluk on 6/6/17.
 */

import loader from './loader.vue';
import lineLoader from './lineLoader.vue';

export default {
  loader,
  lineLoader
}
