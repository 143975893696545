<template>
  <div v-if="!isStoreCallback" style="margin-top: 20px; margin-left: 10px">Bu pencere otomatik olarak kapanacaktır.</div>
  <div v-else style="margin-top: 100px; text-align: center">
    <a href="https://apps.apple.com/tr/app/bionluk/id1330144140?l=tr" class="btn">Uygulamayı İndir</a>
    <p>Uygulamanıza devam etmek için lütfen düğmeye basın</p>
  </div>
</template>

<script>
export default {
  name: "LoginCallback",
  data() {
    return {
      isStoreCallback: false
    }
  },
  mounted() {
    if (this.$route.query.openapp === 'true') {
      this.isStoreCallback = true
      window.location.href = 'https://apps.apple.com/tr/app/bionluk/id1330144140?l=tr'
      return
    }
    if (this.$route.query.id_token && this.$route.query.g_access_token && window.opener) {
      window.opener.postMessage({
        id_token: this.$route.query.id_token,
        g_access_token: this.$route.query.g_access_token,
        type: 'callback'
      }, 'https://bionluk.com')
    } else {
      window.close()
      location.href = '/'
    }
  }
}
</script>

<style scoped>
.btn{background-color:#00bed5 ;border:2px solid #00bed5 ;color:#fff;display:inline-block;font-weight:700;letter-spacing: 0.1em;max-width:200px;padding:10px;text-decoration:none;transition:all .3s ease-in-out;width:80%}
p{color:#b8b8b8 ;margin-top:5vh;padding:0 20px}
</style>
