/**
 * Created by haluk on 8/15/17.
 */

import helper from './axiosHelper';

const trackVisitorURLAWS = "https://bionluk.com/api/general/l/";


export default {
  uploadFileToGoogle(url, data, header, method, options) {
    return helper.axiosWrapper(helper.axiosInstance, method, url, false, data, header, options);
  },
  trackEvents(payload) {

    if(payload && payload.payloadType && payload.payloadType === 'event'){
      payload.visitor_id = payload.id;
      delete payload.id;
    }

    return helper.axiosWrapper(helper.axiosInstance, "post", trackVisitorURLAWS, false, payload, {
      'SUPER-TOKEN': localStorage.getItem('token') || null,
      'SUPER-KEY': '1e291318-f4b6-4a65-8323-a1823dbd7564',
    });

  },





  searchOnAzure(index, payload) {
    return helper.axiosWrapper(helper.axiosInstance, "post", "https://super.search.windows.net/indexes/"+ index +"/docs/search?api-version=2016-09-01", false, payload, {
      'Accept': 'application/json',
      'api-key': 'B9808A8CB3E58C01C6639863016C3216'
    });
  }
}
