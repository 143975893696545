import { customDateFormat } from '@/utils/dateFns'

export default {
  customDateFormat,
  getInfoText(text, type) {
    if (type === "url") {
      return text.substring(text.lastIndexOf("*|")+2,text.lastIndexOf("|*"));
    }
    if (type === "text") {
      return text.substr(0, text.indexOf('*'));
    }
  },
  specifyCurrency(price, currency) {
    return (currency.place === 'before') ? (currency.icon + price) : (price + currency.icon);
  },
  specifyPeriod(value, period, type) {
    return value + ' ' + period[type];
  },
  calculateElapsedTime(date) {
    let now = new Date()
    let receivedDate = new Date(date)
    const diffMaps = [
      { tr_TR: ' yıl', value: 31536000000 }, // years
      { tr_TR: ' ay', value: 2678400000 }, // months
      { tr_TR: ' gün', value: 86400000 }, // days
      { tr_TR: ' saat', value: 3600000 }, // hours
      { tr_TR: ' dakika', value: 60000 }, // minutes
      { tr_TR: ' saniye', value: 1000 } // seconds
    ]
    let selected = null
    diffMaps.forEach(function (map) {
      if (!selected) {
        let diff = Math.floor((now - receivedDate) / map.value)
        if (diff > 0 ) {
          selected = diff + map.tr_TR
        }
      }
    })

    return selected || 'az önce'
  },
  lastSeen(date) {
    let now = new Date()
    let receivedDate = new Date(date)

    const diffMaps = [
      { tr_TR: ' yıl', value: 31536000000 }, // years
      { tr_TR: ' ay', value: 2678400000 }, // months
      { tr_TR: ' gün', value: 86400000 }, // days
      { tr_TR: ' saat', value: 3600000 }, // hours
      { tr_TR: ' dakika', value: 60000 }, // minutes
      { tr_TR: ' saniye', value: 1000 } // seconds
    ]

    let selected = null
    diffMaps.forEach(function (map) {
      if (!selected) {
        let diff = Math.floor((now - receivedDate) / map.value)
        if (diff > 0 ) {
          selected = diff + map.tr_TR
        }
      }
    })

    return selected
  },
  convertNumberToFraction(number) {
    if (!number.toString().includes(".")) {
      number = number + ".0";
      return number;
    } else  {
      return number;
    }
  },
  number_format (number, decimals = 0, decPoint = ',', thousandsSep = '.') {

    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    var n = !isFinite(+number) ? 0 : +number
    var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    var s = ''

    var toFixedFix = function (n, prec) {
      if (('' + n).indexOf('e') === -1) {
        return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
      } else {
        var arr = ('' + n).split('e')
        var sig = ''
        if (+arr[1] + prec > 0) {
          sig = '+'
        }
        return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
      }
    }

    // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }

    return s.join(dec)
  },
}
