/**
 * Created by haluk on 7/31/17.
 */

export default {
  preparePusherInfo(user) {
    let arrangedObject = {};
    arrangedObject.channelID = user.channel;
    arrangedObject.todoCount = user.todoCount;
    arrangedObject.unreadMessageCount = user.unreadMessageCount;
    arrangedObject.unreadMessages = user.unreadMessages;
    arrangedObject.unreadNotificationCount = user.unreadNotificationCount;
    arrangedObject.likeCount = user.likeCount;
    arrangedObject.requestCount = user.requestCount;
    arrangedObject.followerCount = user.followerCount;
    arrangedObject.followingCount = user.followingCount;
    return arrangedObject;
  },
  specifyComponentType(componentMap) {
    let preparedComponentMap = {};
    for (let key in componentMap) {
      if (key === "body" || key === "footer" || key === "header") {
        let split = componentMap[key].split('/');
        preparedComponentMap[key] = {
          version: split[1],
          pageType: split[0]
        }
      } else {
        preparedComponentMap[key] = componentMap[key];
      }
    }
    return preparedComponentMap;
  }
}
