import axios from 'axios';
import fileTyper from 'file-type';
import Api from './api';
import EventBus from './eventBus';
import Helper from './pages/helper';
import Constants from './constants';
import * as Types from './store/mutation-types';
import Modals from './constants/CustomModals';

import { mapState } from 'vuex'
import store from './store'

//import LogRocket from 'logrocket';

export default {
  methods: {
    slugify(text) {
      const trMap = {
        'çÇ':'c',
        'ğĞ':'g',
        'şŞ':'s',
        'üÜ':'u',
        'ıİ':'i',
        'öÖ':'o'
      };
      for(const key in trMap) {
        text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
      }
      return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
        .replace(/\s/gi, "-") // convert spaces to dashes
        .replace(/[-]+/gi, "-") // trim repeated dashes
        .toLowerCase();

    },
    handleGlobalClick(whereFrom, clickedFrom, type= '', impressionUUID = null, info= {}){
      if (type === 'gig'){
        this.Helper.trackEvents.customEvent("gig_clicks", {title:impressionUUID, ...info});
      }
      window.localStorage.setItem("whereFrom",  this.$store.state.componentMap.page);
      window.localStorage.setItem("clickedFrom", clickedFrom);
      if(clickedFrom){
        this.$store.state.clickedFrom = clickedFrom;
      }
    },
    placeCaretAtEnd(el) {
      el.focus();
      if (typeof window.getSelection !== 'undefined'
        && typeof document.createRange !== 'undefined') {
        let range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange !== 'undefined') {
        let textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },
    uploadFileToGoogle(file, type, externalID, stamp = +new Date(), r_id = '', r_uuid = '', videoDuration = 0) {

      let cancel;
      let cancelToken = axios.CancelToken;

      const uploadType = type === 'message' ? externalID : type;

      let cloneUploadStatus = {...this.$store.state.uploadStatus};
      let clonePercentCompleted = {...this.$store.state.percentCompleted};

      if(!cloneUploadStatus[uploadType]) cloneUploadStatus[uploadType] = {[stamp]: "started"};
      else cloneUploadStatus[uploadType][stamp] =  "started";
      this.$store.state.uploadStatus = {...cloneUploadStatus};

      if(!clonePercentCompleted[uploadType]) clonePercentCompleted[uploadType] = {[stamp]:  0.1};
      else clonePercentCompleted[uploadType][stamp] =  0.1;
      this.$store.state.percentCompleted = {...clonePercentCompleted};

      return new Promise((resolve, reject) => {
        EventBus.$on('cancelUpload', receivedStamp => {
          if (receivedStamp === 'all') {
            for (const key in cloneUploadStatus) {
              if (['portfolio', 'ticket', 'request', 'order', 'onboarding', 'avatar', 'blog', 'test'].includes(key)) {
                cloneUploadStatus[key] = {}
                clonePercentCompleted[key] = {}
              }
            }
          } else if (receivedStamp === stamp) {
            cloneUploadStatus[uploadType][stamp] = null;
            clonePercentCompleted[uploadType][stamp] = 0;
          }
          this.$store.state.percentCompleted = {...clonePercentCompleted};
          this.$store.state.uploadStatus = {...cloneUploadStatus};
        });
        let maxBlockSize = 256 * 1024;
        let currentFilePointer = 0;
        let totalBytesRemaining = 0;
        let blockIds = [];
        let blockIdPrefix = "block-";
        let submitUri = null;
        let bytesUploaded = 0;


        let fileSize = file.size;
        let fileName = file.name;
        let fileType = file.type;

        if (fileSize < maxBlockSize) {
          maxBlockSize = fileSize;
        }
        totalBytesRemaining = fileSize;



        let tempFileReader = new FileReader();
        let uploadingFileBlockCount = 0;


        const onUploadProgress = (uploadPercentage) => {
          //console.log('uploadPercentage',uploadPercentage);

          if (this.$store.state.uploadStatus[uploadType][stamp]) {
            clonePercentCompleted[uploadType][stamp] = uploadPercentage;
            this.$store.state.percentCompleted = {...clonePercentCompleted};

            this.$forceUpdate();
          } else {
            cloneUploadStatus[uploadType][stamp] = null;
            this.$store.state.uploadStatus = {...cloneUploadStatus};

            clonePercentCompleted[uploadType][stamp] = 0;
            this.$store.state.percentCompleted = {...clonePercentCompleted};
            cancel();
            reject({message: 'Dosya yükleme işlemin iptal edildi.'});
          }
        };


        tempFileReader.onloadend = (event) => {
          fileType = fileTyper(new Uint8Array(event.target.result));
          if (fileType) {
            fileType = fileType.mime
          } else {
            fileType = "application/octet-stream";
          }

          Api.general.uploadRequest(type, fileName, fileType, fileSize, (type === 'avatar' || type === 'application' ? null : externalID),'google')
          .then(({data}) => {
            let result = data;
            if (result.success) {


              if(result.data.upload_type === 'portfolio' && result.data.file_type === 'video'){

                let vid = document.createElement('video');
                let fileURL = URL.createObjectURL(file);
                vid.src = fileURL;
                // wait for duration to change from NaN to the actual duration
                vid.ondurationchange = function() {
                  if(!this.duration || this.duration > 299)
                  reject({message:"Video süresi en fazla 4 dakika 59 saniye olabilir."})
                };
              }


              submitUri = result.data.url;
              let uploadID = result.data.upload_id;
              let uploadName = result.data.upload_name;
              let filename_slug =  result.data.filename_slug;

              let reader = new FileReader();

              this.$store.state.percentCompleted.temp_icon_png[stamp] = result.data.icon_png;
              this.$store.state.percentCompleted.fileNames[stamp] = fileName;
              this.$forceUpdate();


              let googleUploadRequestPostOptionsHeaders = {
                  'Content-Type': file.type,
                  'x-goog-resumable': 'start'
              };

              if(result.data.upload_type && result.data.upload_type === 'message'){
                if(result.data.file_type === 'video' && videoDuration > 299){
                  googleUploadRequestPostOptionsHeaders['Content-Disposition'] = 'attachment;filename="'+encodeURIComponent(fileName)+'"';
                } else if(result.data.file_type !== 'video' && result.data.file_type !== 'audio' && result.data.file_type !== 'image'){
                  googleUploadRequestPostOptionsHeaders['Content-Disposition'] = 'attachment;filename="'+encodeURIComponent(fileName)+'"';
                }
              }

              if(result.data.is_not_image && result.data.upload_type && result.data.upload_type === 'request'){
                googleUploadRequestPostOptionsHeaders['Content-Disposition'] = 'attachment;filename="'+encodeURIComponent(fileName)+'"';
              }

              if(result.data.is_not_image && result.data.upload_type && result.data.upload_type === 'ticket'){
                googleUploadRequestPostOptionsHeaders['Content-Disposition'] = 'attachment;filename="'+encodeURIComponent(fileName)+'"';
              }

              if(result.data.upload_type && result.data.upload_type === 'order' && result.data.file_type !== 'audio' && result.data.file_type !== 'image'){
                googleUploadRequestPostOptionsHeaders['Content-Disposition'] = 'attachment;filename="'+encodeURIComponent(fileName)+'"';
              }


              googleUploadRequestPostOptionsHeaders['Cache-Control'] = 'public, max-age=31536000';

              Api.external.uploadFileToGoogle(submitUri, null, googleUploadRequestPostOptionsHeaders,"post")
              .then(result => {
                let putUrl = result.headers.location;
                let googleUploadRequestPutOptions = {
                  headers: {
                    'Content-Type': "multipart/form-data"
                  },
                  onUploadProgress: (progressEvent) => {
                    let uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onUploadProgress(uploadPercentage)
                    if(uploadPercentage % 3 === 0 && type === 'message'){
                      this.api.workstation.sendWriting(externalID, 'uploading', uploadPercentage, r_id, r_uuid);
                    }
                  },
                  cancelToken: new cancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                  })
                };

                let googleUploadRequestPutOptionHeaders = {'Content-Type': "multipart/form-data"};

                Api.external.uploadFileToGoogle(putUrl, file, googleUploadRequestPutOptionHeaders,"put", googleUploadRequestPutOptions)
                .then(function (res2) {

                  Api.general.updateUploadStatus(uploadID, 1, videoDuration)
                  .then(({data}) => {
                    let result = data;
                    let cloneUploadStatus = {...store.state.uploadStatus};
                    let clonePercentCompleted = {...store.state.percentCompleted};

                    cloneUploadStatus[uploadType][stamp] = null;
                    store.state.uploadStatus = {...cloneUploadStatus};

                    clonePercentCompleted[uploadType][stamp] = 0;
                    store.state.percentCompleted = {...clonePercentCompleted};


                    resolve({icon_png:result.data.icon_png,uploadName: uploadName, externalID: result.data.external_id, url: result.data.url, filename: result.data.filename, file_type: result.data.file_type, video_url: result.data.video_url, c_uuid: result.data.c_uuid, image_url:result.data.image_url, image_url_small:result.data.image_url_small,image_url_original:result.data.image_url_original, uuid:result.data.uuid});

                  })
                  .catch(err => {
                    reject(err);
                  });


                })
                .catch(function (err) {
                  reject(err);
                });

              })
              .catch(err => {
                reject(err);
              });
            } else {
              reject(result)
            }
          })
          .catch(err => {
            reject(err)
          });
        };

        tempFileReader.readAsArrayBuffer(file);

      });
    },
    cancelUploadRequest(stamp) {
      EventBus.$emit('cancelUpload', stamp);
    },
    calculateElapsedTime(date) {
      let now = new Date()
      let receivedDate = new Date(date)
      const diffMaps = [
        { tr_TR: ' yıl', value: 31536000000 }, // years
        { tr_TR: ' ay', value: 2678400000 }, // months
        { tr_TR: ' gün', value: 86400000 }, // days
        { tr_TR: ' saat', value: 3600000 }, // hours
        { tr_TR: ' dakika', value: 60000 }, // minutes
        { tr_TR: ' saniye', value: 1000 } // seconds
      ]
      let selected = null
      diffMaps.forEach(function (map) {
        if (!selected) {
          let diff = Math.floor((now - receivedDate) / map.value)
          if (diff > 0 ) {
            selected = diff + map.tr_TR
          }
        }
      })

      return selected || 'az önce'
    },
    init(forcedInit, to, from) {
      return new Promise(function (resolve, reject) {
        if (!forcedInit || forcedInit && !store.state.calledInit) {
          Helper.setInitialValuesBeforeInit(to);

          ga('send', 'event', {
            'eventCategory': 'users',
            'eventAction': 'visit'
          });
          store.state.categoriesPromise = Api.general.getAllCategories()
            .then(({ data }) => {
              const categories = data.data.map(category => {
                if (!category.parent_id) {
                  category.subCategory = [];
                  data.data.forEach(tempCategory => {
                    if (category.id === tempCategory.parent_id) {
                      category.subCategory.push(tempCategory)
                    }
                  })
                }
                return category
              })
              store.state.categories = categories
              return categories
            })



          Api.user.speedInit(store.state.deviceToken, store.state.appType, store.state.appVersion, document.referrer, window.location.href)
            .then(({data}) => {
              let received = data;
              if(received.success) {
                if (received.token) {
                  window.localStorage.setItem("token", received.token);
                }

                Helper.setInitialValuesAfterInit(received, to, from);


                if (received.visitor_id) {
                  window.localStorage.setItem("visitor_id", received.visitor_id);
                }

                if (received.data.user) {
                  EventBus.$emit("listenPusherChannelToGetInfoForUser");
                }
                resolve(false);
              } else {
                Api.user.logout().then(({data}) => {
                  let result = data;
                  //LogRocket.identify();

                  amplitude.regenerateDeviceId();
                  amplitude.setUserId(null);
                  localStorage.removeItem("token");
                  localStorage.removeItem("visitor_id");
                  resolve({redirect_url:received.data.redirect_url});
                });
              }
            })
            .catch(function (err) {
              reject(err);
            });
        } else {
          resolve(false);
        }
      })
    },


    playSound(soundUrl) {
      if (this.$store.state.isMobile) return false;
      const playSound = this.$store.state.user.info.permissions.play_sound
      if (playSound && this.$store.state.userInteractedWithDOM) {
        let snd = new Audio(soundUrl)
        let playPromise = snd.play()
        if (playPromise !== undefined) {
          playPromise.catch(function(error) {
            console.error(error + 'Failed to start your sound, retrying.')
          })
        }
      }
    },
    playReceivedMessageSound() {
      this.playSound('https://gcdn.bionluk.com/site/general/gelen.mp3')
    },
    playSentMessageSound() {
      this.playSound('https://gcdn.bionluk.com/site/general/giden.mp3')
    },
    playNotificationSound() {
      this.playSound('https://gcdn.bionluk.com/site/general/gelen.mp3')
    },
    sendPrerender302(redirect_url, type= "301", calledByRouter = false) {

      let meta = document.createElement('meta');
      meta.name = "render:status_code";
      meta.content = type;
      document.getElementsByTagName('head')[0].appendChild(meta);


      let metaLocation = redirect_url;
      if(metaLocation === '/'){
        metaLocation = '';
      }

      //let meta2 = document.createElement('meta');
      //let meta2content = "0,url=https://bionluk.com" + metaLocation;
      //meta2.setAttribute("http-equiv", "refresh");
      //meta2.setAttribute("content", meta2content);
      //document.getElementsByTagName('head')[0].appendChild(meta2);
      let meta3 = document.createElement('meta');
      let meta3content = "Location:https://bionluk.com" + metaLocation;
      meta3.setAttribute("name", "render:header");
      meta3.setAttribute("content", meta3content);
      document.getElementsByTagName('head')[0].appendChild(meta3);
      if(!Helper.isIndexerBotOrDevelopmentEnv() && !calledByRouter){
        this.$router.push(redirect_url)
      }
    },
    escapeTurkishCharacters(word) {
      let turkishMappedChars = {'Ç': 'C', 'ç': 'c', 'Ğ': 'G', 'ğ': 'g', 'İ': 'I', 'ı': 'i', 'Ö': 'O', 'ö': 'o', 'Ş': 'S', 'ş': 's', 'Ü': 'U', 'ü': 'u'};
      let rule = new RegExp(Object.keys(turkishMappedChars).join("|"), "g");

      return word.replace(rule, function (matched) {
        return turkishMappedChars[matched];
      });
    },
    revertLikeStatus(collection) {
      if (collection.likeStatus.action === this.imageUrls.likeIcon.action) {
        collection.likeStatus = {
          actionIcon: this.imageUrls.likeIcon.url,
          action: this.imageUrls.likeIcon.action,
          actionText: this.imageUrls.likeIcon.text
        };
        collection.totalLikes -= 1;
      } else {
        collection.likeStatus = {
          actionIcon: this.imageUrls.likedIcon.url,
          action: this.imageUrls.likedIcon.action,
          actionText: this.imageUrls.likedIcon.text
        };
        collection.totalLikes += 1;
      }
    },
    reverseLikeStatus(collection) {
      if (collection.likeStatus.action === this.imageUrls.likeIcon.action) {
        collection.likeStatus = {
          actionIcon: this.imageUrls.likedIcon.url,
          action: this.imageUrls.likedIcon.action,
          actionText: this.imageUrls.likedIcon.text
        };
        collection.totalLikes += 1;
      } else {
        collection.likeStatus = {
          actionIcon: this.imageUrls.likeIcon.url,
          action: this.imageUrls.likeIcon.action,
          actionText: this.imageUrls.likeIcon.text
        };
        collection.totalLikes -= 1;
      }
    },
    routeBack(path, isForced) {
      this.$store.state.isRoutedBack = true;
      if (isForced) {
        this.$router.push(path);
      } else {
        if (this.$store.state.route.from.fullPath === "/" || this.$store.state.mobileRoutes.length === 0) {
          this.$router.push('/');
        } else {
          let path = this.$store.state.mobileRoutes.pop();
          this.$router.push(path);
        }
      }
    },
    trackConversionForRegisteredUsers() {
      if (!Helper.isIndexerBotOrDevelopmentEnv()) {
        try {
          window.google_trackConversion({
            google_conversion_id: 962810877,
            google_conversion_language: "tr",
            google_conversion_format: "3",
            google_conversion_color: "ffffff",
            google_conversion_label: "lphnCIzRmWAQ_aeNywM",
            google_remarketing_only: false
          });

          ga('send', 'event', {
            'eventCategory': 'users',
            'eventAction': 'signup'
          });

          fbq('track', 'CompleteRegistration');

          //LogRocket.track('Registered');
        } catch (err) {

        }
      }
    },
    trackConversionForCheckoutedOrders(amount, currency) {
      if (!Helper.isIndexerBotOrDevelopmentEnv()) {
        try {
          window.google_trackConversion({
            google_conversion_id: 962810877,
            google_conversion_language: "tr",
            google_conversion_format: "3",
            google_conversion_color: "ffffff",
            google_conversion_label: "mLlRCKWX9HoQ_aeNywM",
            google_conversion_value: amount,
            google_conversion_currency: currency,
            google_remarketing_only: false
          });
          fbq('track', 'InitiateCheckout', {
            value: amount,
            currency: currency
          });
          ga('send', 'event', {
            'eventCategory': 'users',
            'eventAction': 'checkout',
            'eventValue': amount
          });

          //LogRocket.track('InitiateCheckout');
        } catch (err) {

        }
      }
    },
    trackConversionForPayedOrders(amount, currency, paymentType='order') {

     let conversion_label = paymentType === 'order' ? 'FzE-CPPdhWsQ_aeNywM' : 'k3KzCKrak_UBEP2njcsD';
     let ga_event = paymentType === 'order' ? 'order payed' : 'freelancer payment';
      if (!Helper.isIndexerBotOrDevelopmentEnv()) {
        if(window && window.google_trackConversion){
          window.google_trackConversion({
            google_conversion_id: 962810877,
            google_conversion_language: "tr",
            google_conversion_format: "3",
            google_conversion_color: "ffffff",
            google_conversion_label: conversion_label,
            google_conversion_value: amount,
            google_conversion_currency: currency,
            google_remarketing_only: false
          });

          if( paymentType === 'order'){
            fbq('track', 'Purchase', {
              value: amount,
              currency: currency
            });
          } else {
            fbq('trackCustom', 'FreelancerPayment', {value:amount, currency: currency});
          }


          ga('send', 'event', {
            'eventCategory': 'users',
            'eventAction': ga_event,
            'eventValue': amount
          });
        }

        //LogRocket.track('Purchase');
      }
    },
    setFacebookMetaProperties(facebookMeta) {
      for (let key in facebookMeta) {
        document.querySelector(`meta[property="${key}"]`).setAttribute("content", facebookMeta[key]);
      }
    },
    setRedirectURLToRouterMap(path, redirectURL) {
      for (let key in this.$store.state.routerMap) {
        if (key === path) {
          this.$store.state.routerMap[path].redirect_url = redirectURL;
        }
      }
    },
    reloadToMainPage() {
      window.location.href = window.location.origin;
    },
    findCategoryBySlug(slug) {
      return store.state.categoriesPromise.then(() => {
        return this.categories.find(category => category.slug === slug)
      })
    },
    findCategoryByID(id) {
      return store.state.categoriesPromise.then(() => {
        return this.categories.find(category => category.id === id)
      })
    },
    findCategoryBySlugSync(slug) {
      return this.categories.find(category => category.slug === slug)
    },
    findCategoryByIDSync(id) {
      return this.categories.find(category => category.id === id)
    },
    //bu yenisi
    searchDeeds2(option) {
      return new Promise(async (resolve, reject) => {
        let query = {
          top: option.limit ? option.limit : 10,
          skip: option.offset ? option.offset : 0,
          count: option.count,
          scoringProfile : option.scoringProfile || 'sub_slug',
        }

        if(option.searchTitleOnly){
          query.searchFields = 'title';
        }


        if (option.slug) {
          let foundCategory = await this.findCategoryBySlug(option.slug);
          query.filter = `${!foundCategory.parent_id ? 'categorySlug' : 'subCategorySlug'} eq '${option.slug}'`;
          if (!foundCategory.parent_id) {
            query.filter = `categorySlug eq '${option.slug}'`;
          } else {
            query.filter = `subCategorySlug eq '${option.slug}'`;
          }
        }

        if (option.term) {
          // option.term = "\\\""+option.term+"\\\"";
          query.queryType = "full";
          //option.term = encodeURIComponent(option.term);
          //   query.search = `\\\"${option.term}\\\"*`;
          option.term = option.term.toLocaleLowerCase('tr-TR')
          if (option.term.indexOf(" ") !== -1) {
            query.search = `\\\"${option.term}\\\"~1 AND ${option.term}`;
          } else {
            query.search = `\\\"${option.term}\\\"~1 OR ${option.term}*`;
          }
        }

        if (option.orderBy) {
          if (option.orderBy === 2) {
             query.orderby = "score desc"
          } else if (option.orderBy === 3) {
            query.orderby = "createdAt desc"
          }
        }

        if (option.facets) {
          query.facets = option.facets;
        }

        if (option.service_type_id) {
          query.filter = query.filter ? query.filter + ' and service_type_id eq ' + option.service_type_id : ' service_type_id eq ' + option.service_type_id;
        }

        let advancedFilterSuffix = ' ';
        let advancedFilter = '';

       // advancedFilter = ' and service_includes/any(s: s/duration le '+option.duration+')'
        if (option.duration) {
          advancedFilter += ' s/duration le '+option.duration;
          advancedFilterSuffix = ' and ';
          //query.filter = query.filter ? query.filter + ' and service_includes/any(s: s/duration le '+option.duration+')' : 'service_includes/any(s: s/duration le '+option.duration+')';
        }

        if (option.minBudget) {
          advancedFilter += advancedFilterSuffix + ' s/price ge '+option.minBudget;
          advancedFilterSuffix = ' and ';
        }

        if (option.maxBudget) {
          advancedFilter += advancedFilterSuffix + ' s/price le '+option.maxBudget;
        }

        if(option.service_includes && Array.isArray(option.service_includes) && option.service_includes.length > 0 ){
          let kindsfilter = '(';
          if(advancedFilter) { advancedFilter = ' and ' + advancedFilter}
          option.service_includes.forEach( (k, index) => {
            kindsfilter = kindsfilter+  ` service_includes/any(s: s/id eq ${k} ${advancedFilter}) `;
            if((index+1) < option.service_includes.length){
              kindsfilter = kindsfilter+' and ';
            }
          });
          kindsfilter = kindsfilter+ ' )';
          query.filter = query.filter ? query.filter + ' and '+kindsfilter : kindsfilter;
        } else {
          if(advancedFilter) {
            query.filter = query.filter ? query.filter + ' and service_includes/any(s: '+advancedFilter+')' : ' service_includes/any(s: '+advancedFilter+')';
          }
        }

        if(option.meta_options && Array.isArray(option.meta_options) && option.meta_options.length > 0 ){

          option.meta_options.forEach(metaOption => {
            let masterMetaOptionsFilter = '';
            if(!option.excluded_meta_id || option.excluded_meta_id !== metaOption.id ){
              if(metaOption.options && metaOption.options.length){
                let metaOptionsFilter = ' meta_options/any(o: ';
                metaOption.options.forEach((o,i) => {
                  metaOptionsFilter = metaOptionsFilter+  ` o eq ${o} `;
                  if((i+1) < metaOption.options.length){
                    metaOptionsFilter = metaOptionsFilter+' or ';
                  }
                })
                metaOptionsFilter = metaOptionsFilter+ ' ) ';
                masterMetaOptionsFilter = masterMetaOptionsFilter + metaOptionsFilter;
              }
            }
            if(masterMetaOptionsFilter){
              query.filter = query.filter ? query.filter + ' and '+masterMetaOptionsFilter : masterMetaOptionsFilter;
            }
          });
        }

        if(option.onlineOnly){
          query.filter = query.filter ? query.filter + ' and seen_score eq 11' : ' seen_score eq 11';
        }


        if (option.isReallyFeatured) {
          query.filter = query.filter ? query.filter + ' and isFeatured eq true' : ' seen_score eq 11';
        }


        azureClient2.search('gigs3', query, (err, results, raw) => {
          // raw argument contains response body as described here:
          // https://msdn.microsoft.com/en-gb/library/azure/dn798927.aspx
          if (err) {
            reject(err);
          } else {
            let count = raw["@odata.count"] ? raw["@odata.count"] : null;
            let facets = raw["@search.facets"] ? raw["@search.facets"] : null;
            let result = {results};
            if (count) {
              result.count = count;
            }
            if (facets) {
              result.facets = facets;
            }

            resolve(result);
          }
        })
      });
    },
    searchUsers(option) {
      return new Promise((resolve, reject) => {
        let query = {
          top: option.limit ? option.limit : 10,
          skip: option.offset ? option.offset : 0,
          count: option.count
        };

        let searchValue = option.term.trim().replace(/ +/g, "");
        if (searchValue) {
          query.queryType = "full";
          query.search = `${searchValue}~2 OR ${searchValue}*`;
        }

        query.orderby = "ratingCount desc";

        azureClient2.search('users', query, (err, results, raw) => {
          if (err) {
            reject(err);
          } else {
            let result = {results};

            let count = raw["@odata.count"] ? raw["@odata.count"] : null;

            if (count) {
              result.count = count;
            }

            resolve(result)
          }
          // raw argument contains response body as described here:
          // https://msdn.microsoft.com/en-gb/library/azure/dn798927.aspx
        });
      })
    },
    isRetinaDisplay() {
      if (window.matchMedia) {
        let mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
        return (mq && mq.matches || (window.devicePixelRatio > 1));
      }
    }
  },
  computed: {
    parentCategories() {
      return this.categories.filter(cat => !cat.parent_id)
    },
    windowHeight() {
      if (this.$store) {
        return this.$store.state.windowHeight;
      }
    },
    api() {
      return Api;
    },
    EventBus() {
      return EventBus;
    },
    Helper() {
      return Helper;
    },
    Constants() {
      return Constants;
    },
    Modals() {
      return Modals;
    },
    types() {
      return Types;
    },
    ...mapState([
      'isLoggedIn',
      'route',
      'user',
      'prefixPath',
      'percentCompleted',
      'footer_links',
      'units',
      'categories',
      'imageUrls',
      'isMobile',
      'deviceType',
      'isRoutedBack',
      'activeTabBar',
      'routerParams',
      'indexHTMLTitle',
      'indexHTMLMetaDescription'
    ])
  }
}
