<template>
  <div id="smartbanner-wrapper">
    <div class="q-box q-left-aligned banner-context slide-up">
      <div class="close" @click="$emit('close')">
        <i class="q-icon icon-close black close-icon" />
      </div>
      <div class="content">
        <img src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png" alt="smart banner image" style="margin-top: 20px">
        <p class="q-typography q-body bold black" style="margin-top: 10px">
          Daha iyi bir deneyim için
        </p>
        <button class="q-primary q-button-small q-button cta" @click="redirectToApp">
              <span>
                Uygulamada Aç
              </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmartBanner",
  methods: {
    redirectToApp() {
      window.open(`https://bionluk.com/napi/auth/app${this.$route.fullPath}`, '_blank')
      // window.location.href = `https://bionluk.com/napi/auth/app${this.$route.fullPath}`
    }
  }
}
</script>

<style lang="scss" scoped>
#smartbanner-wrapper {
  position: fixed;
  z-index: 1025;
  bottom: 0;
  width: 100%;
}
#smartbanner-wrapper .banner-context.slide-up {
  transform: translateY(-100%);
}
#smartbanner-wrapper .banner-context {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 168px;
  margin-bottom: -168px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.5);
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all .3s;
}
#smartbanner-wrapper *, #smartbanner-wrapper :after, #smartbanner-wrapper :before {
  box-sizing: border-box;
}

#smartbanner-wrapper .banner-context .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
}
#smartbanner-wrapper .banner-context .close .close-icon{
  padding:10px
}
#smartbanner-wrapper .banner-context .content{
  display:flex;
  flex-direction:column;
  align-items:center;
  min-width: unset !important;
  background-color: unset !important;
}
#smartbanner-wrapper .banner-context .content img{
  max-height:48px;max-width:48px
}
#smartbanner-wrapper .banner-context .content *{
  margin-bottom:10px
}
#smartbanner-wrapper .banner-context .content p{
  text-align:center;max-width:90%;word-break:break-word
}
#smartbanner-wrapper .banner-context .content .cta{
  text-overflow:ellipsis;white-space:nowrap;overflow:hidden;height:32px;width:90%;font-weight:600
}

.q-box {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  padding: 10px;
  position: relative;
}
.q-box.q-left-aligned{
  text-align:left
}

.q-icon{
  display:inline-block;line-height:1
}
.q-icon.black:before{
  color:#333
}
.q-icon>span{
  font-family:inherit
}
.q-button{
  cursor:pointer;
  display:inline-block;
  position:relative;
  user-select:none;
  padding-left:10px;
  padding-right:10px;
  font-weight:400;
  font-family:Source Sans Pro,sans-serif;font-style:normal;
  font-stretch:normal;
  line-height:normal;
  letter-spacing:normal;
  text-align:center;
  border-radius:3px
}
.q-button.q-button-small{
  height:38px;font-size:14px
}
.q-button.q-primary{
  background-color: rgb(230, 50, 98);
  color:#fff;
  border:1px solid rgb(230, 50, 98)
}
.q-button.q-primary i:before{
  color:#fff
}
.q-button:focus{
  outline:none!important
}
.q-button:hover{
  text-decoration:none
}
.q-button .q-icon{
  padding-left:0;padding-right:5px
}
.q-button .q-icon:only-child{
  padding-right:0
}
.q-typography{
  margin:0;font-family:Source Sans Pro,Helvetica,Arial,sans-serif;font-style:normal;font-stretch:normal
}
.q-typography.bold{
  font-weight:600
}
.q-typography.q-body{
  font-size:16px;
  line-height:20px
}
.q-typography.black{
  color:#333
}
</style>
