
import helper from './axiosHelper';

const initSpeedURL = '/users/speed_init/';
const initURL = '/users/init/';
const initLightURL = '/users/light_init/';
const registerURL = '/users/register/';
const loginURL = '/users/login/';
const loginWithGoogleURL = '/users/login_google/';
const loginWithFacebookURL = '/users/login_facebook/';
const logoutURL = '/users/logout/';
const activationURL  = 'users/activation/';
const dlURL  = 'users/dl/';
const resendActivationURL  = 'users/resend_activation/';
const forgotPasswordURL  = 'users/forgot_password/';
const changePasswordURL = '/users/change_password/';
const settingsEditNameURL  = 'users/edit_name/';
const settingsEditPasswordURL  = 'users/change_password_profile/';
const settingsEditGSMURL  = 'users/edit_gsm/';
const changeEmailURL  = 'users/change_email/';
const editNotificationURL = "/users/edit_notification/";
const retrieveNotificationsURL = "/users/retrieve_notifications/";
const readNotificationsURL = "/users/clicked_notification/";
const retrieveTodosURL = "/users/retrieve_todos/";
const retrieveTodoCountURL = "/users/get_todo_count/";
const orderOrdersURL = "/users/get_open_orders/";
const flushTodosURL = "/users/flush_todo/";
const retrievePaymentHistoryURL = "/users/get_payment_history/";
const retrieveCGPaymentHistoryURL = "/content/user_payment_history/";
const withdrawFromWalletURL = "/content/withdraw_from_wallet/";
const cancelWithdrawURL = "/content/cancel_withdraw/";
const retrieveCGWalletURL =  "/content/user_get_wallet/";
const retrieveCGWalletDetailURL = "/content/user_wallet_detail/";
const retrieveRequestsURL = "/users/get_requests/";
const createRequestURL = "/users/create_request/";
const changeRequestStatusURL = "/users/change_request_status/";
const deleteUserAvatarURL = "/users/delete_avatar/";
const updateFollowStatusURL = "/users/follow/";
const updateUserBasicInfoURL = "/users/update_basic_info/";
const deleteUserURL = "/users/hold/";
const requestOffersURL = "/users/request_offers/";
const requestWithdrawURL = "/users/request_withdraw/";
const createRequestFromModalURL = "/users/create_request_from_modal/";
const editRequestURL = "/users/edit_request/";
const dismissTodoURL = "/users/dismiss_todo/";
const usersHoldURL = "/users/hold/";
const activateGSMURL = "/users/activate_gsm/";
const completeGSMActivationURL = "/users/complete_gsm_activation/";
const startLineURL = "/users/start_line/";
const changeLineSettingsURL = "/users/change_line_settings/";
const changeLineURL = "/users/change_line/";
const extendLineURL = "/users/extend_line/";
const useItemURL = "/users/use_item/";
const tokenRedirectURL = "/users/token_redirect/";
const editPlaySoundURL = "/users/edit_play_sound/";
const getUserCardsURL = "/users/get_user_cards/";
const getUserSessionsURL = "/users/get_user_sessions/";
const deleteSessionURL = "/users/delete_session/";
const setClickedURL = "/content/is_clicked/";

export default {

  login(email, password, progress, redirect_url, redirect_query, called_from=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", loginURL, progress, helper.createFormData({ email, password, redirect_url, redirect_query, called_from}));
  },

  loginWithGoogle(idToken, accessToken, progress, redirect_url, redirect_query, exandroid, called_from=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", loginWithGoogleURL, progress, helper.createFormData({id_token: idToken, access_token: accessToken, redirect_url, redirect_query, exandroid, called_from}));
  },

  loginWithFacebook(accessToken, progress, redirect_url, redirect_query, called_from=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", loginWithFacebookURL, progress, helper.createFormData({ access_token: accessToken, redirect_url, redirect_query,called_from }));
  },

  logout(progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", logoutURL, progress);
  },

  register(firstname, lastname, username, email, password, redirect_url, redirect_query, progress, called_from=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", registerURL, progress, helper.createFormData({ firstname, lastname, username, email, password, redirect_url, redirect_query, called_from }));
  },

  activation(activationcode, uuid) {
    return helper.axiosWrapper(helper.axiosInstance, "post", activationURL, null, helper.createFormData({activation_code: activationcode, uuid}));
  },

  dl(code, uuid, type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", dlURL, null, helper.createFormData({code, uuid, type}));
  },

  resendActivation(email, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", resendActivationURL, progress, helper.createFormData({ email }));
  },

  forgotPassword(email, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", forgotPasswordURL, progress, helper.createFormData({ email }));
  },

  changePassword(code, uuid, password, passwordValidation, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", changePasswordURL, progress, helper.createFormData({password1: password, password2: passwordValidation, uuid, forgot_password_code: code}));
  },

  deleteUserAvatar(progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteUserAvatarURL, progress);
  },

  settingsEditName(firstname, lastname, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", settingsEditNameURL, progress, helper.createFormData({ firstname, lastname}));
  },

  settingsEditPassword(password, password1, password2, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", settingsEditPasswordURL, progress, helper.createFormData({ password, password1, password2}));
  },



  changeEmail(code, uuid) {
    return helper.axiosWrapper(helper.axiosInstance, "post", changeEmailURL, false, helper.createFormData({ code, uuid}));
  },


  editNotification(type, value, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  editNotificationURL, progress, helper.createFormData({ type, value}));
  },

  retrieveNotifications(limit, offset) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  retrieveNotificationsURL, false, helper.createFormData({ limit, offset}));
  },

  readNotification(id) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  readNotificationsURL, false, helper.createFormData({ id}));
  },

  retrieveTodos(limit, offset) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  retrieveTodosURL, false, helper.createFormData({ limit, offset}));
  },

  getTodoCount() {
    return helper.axiosWrapper(helper.axiosInstance, "post",  retrieveTodoCountURL, false);
  },

  getOpenOrders() {
    return helper.axiosWrapper(helper.axiosInstance, "post",  orderOrdersURL, false);
  },

  flushTodos() {
    return helper.axiosWrapper(helper.axiosInstance, "post",  flushTodosURL, false);
  },

  retrievePaymentHistory(limit, offset, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  retrievePaymentHistoryURL, progress, helper.createFormData({limit, offset}));
  },

  retrieveCGPaymentHistory(page, type, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  retrieveCGPaymentHistoryURL, progress, helper.createFormData({page, type}));
  },
  retrieveCGWallet(page, type, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  retrieveCGWalletURL, progress, helper.createFormData({page, type}));
  },
  retrieveCGWalletDetail(tx_id, type, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  retrieveCGWalletDetailURL, progress, helper.createFormData({tx_id, type}));
  },
  withdrawFromWallet(amount, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  withdrawFromWalletURL, progress, helper.createFormData({amount}));
  },
  cancelWithdraw(id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  cancelWithdrawURL, progress, helper.createFormData({id}));
  },

  retrieveRequests(limit, offset, status, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  retrieveRequestsURL, progress, helper.createFormData({limit, offset, status}));
  },

  createRequest(request, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  createRequestURL, progress, helper.createFormData(request));
  },

  changeRequestStatus(uuid, status, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  changeRequestStatusURL, progress, helper.createFormData({uuid, status}));
  },

  updateFollowStatus(uuid, action, page=null, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateFollowStatusURL, progress, helper.createFormData({ uuid, action, page }))
  },


  deleteUser() {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteUserURL, false)
  },

  requestOffers(slug) {
    return helper.axiosWrapper(helper.axiosInstance, "post", requestOffersURL, false, helper.createFormData({ request_uuid: slug}))
  },

  requestWithdraw(total, method, paypal_email) {
    return helper.axiosWrapper(helper.axiosInstance, "post", requestWithdrawURL, false, helper.createFormData({ total, method, paypal_email}))
  },

  createRequestFromModal(title, description, category_id, category_sub_id, request_budget_id, request_duration_id, request_service_types, request_service_kinds, budget=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createRequestFromModalURL, false, helper.createFormData({ title, description, category_id, category_sub_id, request_budget_id, request_duration_id, request_service_types, request_service_kinds, budget}))
  },

  editRequest(uuid,title, description, category_id, category_sub_id, request_budget_id, request_duration_id, budget=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", editRequestURL, false, helper.createFormData({uuid, title, description, category_id, category_sub_id, request_budget_id, request_duration_id, budget}))
  },

  dismissTodo(id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", dismissTodoURL, false, helper.createFormData({id}))
  },

  usersHold(message) {
    return helper.axiosWrapper(helper.axiosInstance, "post", usersHoldURL, false, helper.createFormData({message}))
  },


  initLight(deviceToken, appType, appVersion, referrer, current_url,  progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", initLightURL, progress, helper.createFormData({deviceToken, appType, appVersion, referrer, current_url }))
  },

  init(deviceToken, appType, appVersion, referrer, current_url,  progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", initURL, progress, helper.createFormData({deviceToken, appType, appVersion, referrer, current_url }))
  },

  speedInit(deviceToken, appType, appVersion, referrer, current_url,  progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", initSpeedURL, progress, helper.createFormData({deviceToken, appType, appVersion, referrer, current_url }))
  },

  settingsEditGSM(email, gsm, f_t_mode, f_t_status) {
    return helper.axiosWrapper(helper.axiosInstance, "post", settingsEditGSMURL, false, helper.createFormData({ email, gsm, f_t_mode, f_t_status }));
  },

  updateUserBasicInfo(title, brief, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateUserBasicInfoURL, progress, helper.createFormData({ title, brief }))
  },

  updateUserBasicInfo2(firstname, lastname, title, brief, describeYourself = null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateUserBasicInfoURL, false, helper.createFormData({ firstname, lastname, title, brief, describeYourself }))
  },

  // createRequestFromModal(title, description, category_id, category_sub_id, request_budget_id, request_duration_id) {
  //   return helper.axiosWrapper(helper.axiosInstance, "post", createRequestFromModalURL, false, helper.createFormData({ title, description, category_id, category_sub_id, request_budget_id, request_duration_id}))
  // },


  activateGSM(gsm) {
    return helper.axiosWrapper(helper.axiosInstance, "post", activateGSMURL, false, helper.createFormData({gsm}))
  },
  completeGSMActivation(gsm_activation_code) {
    return helper.axiosWrapper(helper.axiosInstance, "post", completeGSMActivationURL, false, helper.createFormData({gsm_activation_code}))
  },
  startLine(type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", startLineURL, false, helper.createFormData({type}))
  },
  changeLineSettings(mode,status) {
    return helper.axiosWrapper(helper.axiosInstance, "post", changeLineSettingsURL, false, helper.createFormData({mode,status}))
  },
  changeLine(line) {
    return helper.axiosWrapper(helper.axiosInstance, "post", changeLineURL, false, helper.createFormData({line}))
  },
  extendLine() {
    return helper.axiosWrapper(helper.axiosInstance, "post", extendLineURL, false)
  },
  useItem(itemId) {
    return helper.axiosWrapper(helper.axiosInstance, "post", useItemURL, false, helper.createFormData({itemId}))
  },

  tokenRedirect(t) {
    return helper.axiosWrapper(helper.axiosInstance, "post", tokenRedirectURL, false, helper.createFormData({t}))
  },

  editPlaySound(play_sound) {
    return helper.axiosWrapper(helper.axiosInstance, "post", editPlaySoundURL, false, helper.createFormData({play_sound}))
  },

  getUserCards() {
    return helper.axiosWrapper(helper.axiosInstance, "post", getUserCardsURL, false)
  },

  getUserSessions(limit, offset) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getUserSessionsURL, false,  helper.createFormData({limit, offset}))
  },

  deleteSession(id, password) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteSessionURL, false,  helper.createFormData({id, password}))
  },

  setClicked(type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", setClickedURL, false,  helper.createFormData({type}))
  },
}

