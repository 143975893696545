require('swiper/dist/css/swiper.css')
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import ProgressBar from 'vue-progressbar';
import Toasted from 'vue-toasted';
import FBSignInButton from 'vue-facebook-signin-button';
import StarRating from 'vue-star-rating';
import VueCarousel from 'vue-carousel';
const SocialSharing = require('vue-social-sharing');

import VTooltip from 'v-tooltip';
import VueAwesomeSwiper from 'vue-awesome-swiper'

import Toolbox from './pages/components/toolbox';

import router from './router';

import store from './store';
import Constants from './constants';

// Import Helpers for filters;
import customFilters from './filters';
import customDirectives from './directives';
import Helper from './pages/helper';
import globalMixin from './globalMixin';

import App from './App.vue'
import '@/styles/index.scss' // global css

// Import Install and register helper items
for(let key in customFilters) {
  Vue.filter(key, customFilters[key]);
}

// Import directives
for(let key in customDirectives) {
  Vue.directive(key, customDirectives[key]);
}

// global component registration
for(let key in Toolbox) {
  Vue.component(key, Toolbox[key]);
}

Vue.mixin(globalMixin);

const progressBarOptions = {
  color: '#fd4056',
  failedColor: '#be1238',
  thickness: '2px',
  transition: {
    speed: '0.3s',
    opacity: '0.5s',
    termination: 400
  },
  autoRevert: true,
  location: 'top',
  inverse: false
};




// https://github.com/Akryum/v-tooltip
Vue.use(VTooltip);

// https://github.com/hilongjw/vue-progressbar
Vue.use(ProgressBar, progressBarOptions);

// https://github.com/phanan/vue-facebook-signin-button
Vue.use(FBSignInButton);

// https://github.com/nicolasbeauvais/vue-social-sharing
Vue.use(SocialSharing);

// https://github.com/craigh411/vue-star-rating
Vue.component('star-rating', StarRating);

// https://github.com/surmon-china/vue-awesome-swiper
Vue.use(VueAwesomeSwiper);

Vue.use(VueCarousel);

// https://github.com/shakee93/vue-toasted
Vue.use(Toasted, {
  theme: "outline",
  position: "top-center",
  duration: 5000,
  globalToasts: {
    errorToast: function(payload, initiate) {
      return initiate(payload.description, {
        theme: "outline",
        className: 'toasted-error',
        position: "top-center",
        duration: 6000
      });
    },

    infoToast: function(payload, initiate) {
      return initiate(payload.description, {
        theme: "outline",
        className: 'toasted-info',
        position: "top-center",
        duration: 3000
      });
    }
  }
});

sync(store, router);

Vue.config.productionTip = false;

if (!Helper.isIndexerBotOrDevelopmentEnv()) {
  Helper.sentryInit(Vue, router);
}

export default new Vue({
  el: '#app',
  data() {
    return {
      ...Constants
    }
  },
  router,
  store,
  template: '<App/>',
  components: { App }
});
