/**
 * Created by bugra on 21.04.2017.
 */

import helper from './axiosHelper';

const uploadFileURL = "/general/upload/";
const getAllCategoriesURL = "/general/get_categories/";
const getAllSkillsURL = "/general/skills/";
const uploadRequestURL = "/general/upload_request/";
const uploadStatusURL = "/general/upload_status/";
const createTicketURL = "/general/new_ticket/";
const getTicketURL = "/general/ticket_detail/";
const retrieveTicketsURL = "/general/get_tickets/";
const deleteTicketURL = "/general/delete_ticket/";
const addMessageToTicketURL = "/general/new_ticket_message/";
const retrieveFavouritesURL = "/general/get_likes/";
const updateLikeStatusURL = "/general/like/";
const retrieveGigsURL = "/general/get_gigs/";
const retrieveUserURL = "/general/get_public_profile/";
const savePortfolioCommentURL = "/general/save_portfolio_comment/";
const getLikedUsersURL = "/general/get_liked_users/";
const updateVersionURL = "/general/updateVersion/";
const createOrderTicketURL = "/general/new_order_ticket_message/";
const checkEmailURL = "/general/checkEmail/";
const azureSearchURL = "/azure/search/users/";
const getAllGigsURL = "/general/get_all_gigs/";
const gigDetailURL = "/general/gig_detail/";
const getGigCommentsURL = "/general/get_gig_comments/";
const getGigsMainpageURL = "/general/get_gigs_mainpage/";
const getGigListURL = "/general/get_gig_lists/";
const getLikedGigsURL = "/general/get_liked_gigs/";
const checkCoverIsImageURL = "/general/check_cover_is_image/";
const getGigLikesURL = "/general/get_gig_likes/";
const getGigLikesV2URL = "/general/get_gig_likes_v2/";
const portfolioCommentLikeURL = "/general/comment_like/";
const portfolioCommentDeleteURL = "/general/comment_delete/";
const getAllGigsByUserURL = "/general/get_all_gigs_by_user/";
const getUserCommentsURL = "/general/get_user_comments/";
const reportURL = "/general/report/";
const crud_contestURL = "/content/crud_contest/";
const getFollowedUsersURL = "/general/get_followed_users/";
const getUserProfileCommentsURL = "/general/get_user_profile_comments/";
const crudListURL = "/content/crud_list/";
const getPublicListURL = "/content/get_public_list/";
const rateUsURL = "/content/score_ticket/";




export default {

  // type (string, şunlardan biri olabilir: request, avatar, message, portfolio, order)
  uploadRequest(type, filename, contentType, fileSize, externalID = null, source='azure') {
    return helper.axiosWrapper(helper.axiosInstance, "post", uploadRequestURL, false, helper.createFormData({ type, filename, content_type: contentType, filesize: fileSize, external_id: externalID, source }));
  },

  updateUploadStatus(uploadID, status, videoDuration = 0) {
    return helper.axiosWrapper(helper.axiosInstance, "post", uploadStatusURL, false, helper.createFormData({upload_id: uploadID, status, video_duration:videoDuration}));
  },

  uploadFile(type, file) {
    return helper.axiosWrapper(helper.axiosInstance, "post", uploadFileURL, false, helper.createFormData({ type, file }));
  },

  getAllCategories() {
    return helper.axiosWrapper(helper.axiosInstance, "post", getAllCategoriesURL);
  },

  getAllSkills() {
    return helper.axiosWrapper(helper.axiosInstance, "post", getAllSkillsURL);
  },

  createTicket(title, message, uploadIDs, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createTicketURL, progress, helper.createFormData({ title, message, upload_ids: uploadIDs }));
  },

  createTicketV2(title, message, uploadIDs, ticket_category_id, persona, persona_id, impression_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createTicketURL, progress, helper.createFormData({ title, message, upload_ids: uploadIDs, ticket_category_id, persona, persona_id, impression_id  }));
  },

  getTicket(uuid) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getTicketURL, false, helper.createFormData({ uuid }));
  },

  retrieveTickets() {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveTicketsURL, false);
  },

  deleteTicket(uuid, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteTicketURL, progress, helper.createFormData({ uuid }));
  },

  addMessageToTicket(uuid, message, uploadIDs, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", addMessageToTicketURL, progress, helper.createFormData({ uuid, message, upload_ids: uploadIDs }));
  },

  rateUs(uuid, score, comment, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", rateUsURL, progress, helper.createFormData({ uuid, score, comment }));
  },

  requestToEndPoint(endpoint, params, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", endpoint, progress, helper.createFormData(params));
  },

  retrieveFavourites(type, username, limit, offset, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveFavouritesURL, progress, helper.createFormData({ username, type, limit, offset }));
  },

  updateLikeStatus(type, action, external_id, requestFrom = null, page = null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateLikeStatusURL, false, helper.createFormData({ type, action, external_id, requestFrom, page}));
  },

  retrieveGigs(slug, term, limit, offset, username, bia = null, duration = null, max_price = null, min_price = null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveGigsURL, null, helper.createFormData({ slug, term, limit, username, offset, bia, duration, max_price, min_price }));
  },

  retrieveUser(username) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  retrieveUserURL, false, helper.createFormData({ username }));
  },

  savePortfolioComment(uuid, comment, comment_id=null, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  savePortfolioCommentURL, progress, helper.createFormData({ uuid, comment,comment_id }));
  },

  getLikedUsers(type, uuid, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  getLikedUsersURL, progress, helper.createFormData({ type, uuid }));
  },

  updateVersion(version, key) {
    return helper.axiosWrapper(helper.axiosInstance, "post",  updateVersionURL, false, helper.createFormData({ version, key }));
  },

  createOrderTicket(message, order_id, upload_ids, json_obj='', progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createOrderTicketURL, progress, helper.createFormData({ message, order_id, upload_ids, json_obj }));
  },

  checkEmailIfUserRegisteredOrNot(email,progress){
    return helper.axiosWrapper(helper.axiosInstance, "post", checkEmailURL, progress, helper.createFormData({ email }));
  },

  azureSearchUsers(searchCollection, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", azureSearchURL, progress, helper.createFormData(searchCollection));
  },

  getAllGigs(slug, limit, offset, duration) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getAllGigsURL, null, helper.createFormData({ slug, limit, offset, duration}));
  },

  getGigDetail(slug, username, package_id = '', super_fast = '') {
    return helper.axiosWrapper(helper.axiosInstance, "post", gigDetailURL, null, helper.createFormData({ slug, username, package_id, super_fast }));
  },

  getGigComments(slug, limit, offset, type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigCommentsURL, null, helper.createFormData({ slug, limit, offset, type }));
  },

  getGigsMainpage() {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigsMainpageURL, null);
  },


  getGigList() {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigListURL, null);
  },

  getLikedGigs(limit, offset, username) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getLikedGigsURL, null, helper.createFormData({limit, offset, username}));
  },

  checkCoverIsImage(content_type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", checkCoverIsImageURL, null, helper.createFormData({content_type}));
  },

  getGigLikes(idList,slug,firstGigImg, searchTerm) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigLikesURL, null, helper.createFormData({idList,slug,firstGigImg, searchTerm}));
  },

  getGigLikesV2(idList, slug, firstGigImg, searchTerm) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigLikesV2URL, null, helper.createFormData({idList, slug,firstGigImg, searchTerm}));
  },


  portfolioCommentLike(type, action, id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", portfolioCommentLikeURL, null, helper.createFormData({type, action, id}));
  },

  portfolioCommentDelete(type, id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", portfolioCommentDeleteURL, null, helper.createFormData({type, id}));
  },

  getAllGigsByUser(username, limit, offset, gig_id=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getAllGigsByUserURL, null, helper.createFormData({username, limit, offset, gig_id}));
  },

  getUserComments(username, limit, offset, type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getUserCommentsURL, null, helper.createFormData({username, limit, offset, type}));
  },

  report(type, external_id, report_type_id, text, upload_ids) {
    return helper.axiosWrapper(helper.axiosInstance, "post", reportURL, null, helper.createFormData({type, external_id, report_type_id, text, upload_ids}));
  },

  crud_contest(crud_type, filename='', url='', icon_png='') {
    return helper.axiosWrapper(helper.axiosInstance, "post", crud_contestURL, null, helper.createFormData({crud_type, filename, url, icon_png}));
  },

  getFollowedUsers(uuid, limit, offset, type='follower') {
    return helper.axiosWrapper(helper.axiosInstance, "post", getFollowedUsersURL, null, helper.createFormData({uuid, limit, offset, type}));
  },

  getUserProfileComments(username, limit, offset, type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getUserProfileCommentsURL, null, helper.createFormData({username, limit, offset, type}));
  },

  crudList(crud_type, title, id=null, external_id=null, progress, offset=0, page='', is_public=-1) {
    return helper.axiosWrapper(helper.axiosInstance, "post", crudListURL, progress, helper.createFormData({ crud_type, title, id, external_id, offset, page, is_public }));
  },
  getPublicList(id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getPublicListURL, progress, helper.createFormData({ id}));
  },
}
