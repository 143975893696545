import deploymentInfo from '../../deploymentInfo'
import Api from '../api'

export default {
  isLoggedIn: null,
  globalPusher: null,
  calledInit: false,
  isMobile: false,
  user: {
    uuid: null,
    status: null,
    firstname: null,
    lastname: null,
    username: null,
    email: null,
    channelID: null,
    todoCount: null,
    unreadMessageCount: 0,
    unreadMessages:[],
    unreadNotificationCount: 0,
    followerCount: null,
    followingCount: null,
    likeCount: null,
    requestCount: null,
    avatar_url: 'https://gcdn.bionluk.com/uploads/general/avatar_empty.png',
    seller: {
      isSeller: null,
      seller_categories: [],
      gigCount:null,
      portfolioCount:null,
      skillCount:null
    },
    info: null,
    created_at: null,
    socialAccounts: null,
    biaStatus: false,
    openOrders:[],
    editorRoles:[]
  },
  componentMap: null,
  previousComponentPage: null,
  modalComponentMap: null,
  activeModal: {
    modalType: null,
    description: null,
    info: null
  },
  routerMap: null,
  activeMessageComponent: null,
  axiosSource: null,
  axiosPath: null,
  percentCompleted: {
    portfolio: {},
    ticket: {},
    blog: {},
    order: {},
    request: {},
    'onboarding': {},
    'avatar': {},
    'test': {},
    'temp_icon_png':[],
    'fileNames' :[],
  },
  uploadStatus: {
    portfolio: {},
    ticket: {},
    blog: {},
    order: {},
    request: {},
    'onboarding': {},
    'avatar': {},
    'test': {}
  },
  referrer: null,
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  windowScrollTop: null,
  windowScrollHeight: null,
  prefixPath: '',
  routerParams: [],
  footer_links: [],
  footerLinks: {},
  units: {
    currency: {

    }
  },
  defaultTitle: 'Yetenekli Freelancer Topluluğu - Bir Freelancerla Çalışın | Bionluk',
  defaultDescription: 'Grafik Tasarım, seslendirme, mobil uygulama, Wordpress ve daha fazla hizmet için yetenekli freelancerlar sizi bekliyor.',
  indexHTMLTitle: 'Yetenekli Freelancer Topluluğu - Bir Freelancerla Çalışın | Bionluk',
  indexHTMLMetaDescription: 'Grafik Tasarım, seslendirme, mobil uygulama, Wordpress ve daha fazla hizmet için yetenekli freelancerlar sizi bekliyor.',
  categories: [],
  categoriesPromise: null,
  showCase: {
    to: null,
    from: null,
    byModal: null,
    data: null,
    activeStatus: null,
    query: null
  },
  redirectPreviousURL: {
    path: null,
    query: null,
    calledFrom:null,
  },
  currentVersion: deploymentInfo.version,
  newVersion: null,
  imageUrls: {
    likeIcon: {
      action: null,
      url: null,
      text: null
    },
    likedIcon: {
      action: null,
      url: null,
      text: null
    }
  },
  activePopSound: null,
  deviceType: null,
  mobileRoutes: [],
  isRoutedBack: false,
  deviceToken: null,
  chatDetail: {},
  visitorInfo: null,
  appType: null,
  appVersion: null,
  activeTabBar: 0,
  openedVersion: true,
  isDevelopmentEnv: ['http://127.0.0.1:8080', 'http://localhost:8080', 'http://10.132.0.28'].includes(window.location.origin),
  slog: false,
  request_durations:null,
  request_budgets:null,
  biaOnlineText: null,
  biaOnlineCount: null,
  lastSeenItems:[],

  lovedFreelancers:[],
  inspiringGigs:[],
  youMayNeedGigs:[],
  ownSimilarGigs:[],
  lastDonePortfolios:[],
  popularCategories:[],
  openBuyerRequest:[],

  todayBoxItems:[],

  userActionsForSegmentation:[],

  tempActions:{
    firstKeyupMainPageSearch: false
  },

  openConversations:[],

  clickedFrom:null,
  clickedProps:null,
  whereFrom:null,

  gigScrollTopPosition:0,
  portfolioScrollTopPosition:0,
  categoryScrollTopPosition:0,

  chatUsers:[],
  chatUsersOffset: 0,
  chatUsersSeemoreDiv: false,
  chatUserActiveTitle:'all',
  archivedChatUsers:[],
  chats:{},
  activeConversationId: null,
  isIdle:false,
  userInteractedWithDOM:false
}
