/**
 * Created by haluk on 5/11/17.
 */

import helper from './axiosHelper';

const getSellerURL = "/seller/get/";
const editSellerURL = "/seller/update/";
const retrieveGigsURL = "/seller/gig_get_all/";
const changeGigStatusURL = "/seller/gig_change_status/";
const getGigOptionsURL = "/seller/gig_create_options/";
const createGigURL = "/seller/gig_create/";
const getGigDetailURL = "/seller/gig_get_detail/";
const updateGigURL = "/seller/gig_update/";
const updateGigDescriptionURL = "/seller/gig_update_description/";
const updateSkillsURL = "/seller/skills_update/";
const updateSkills2URL = "/seller/skills_update_v2/";
const createPortfolioURL = "/seller/portfolio_create/";
const createPortfolioV2URL = "/seller/portfolio_create_v2/";
const getPortfolioURL = "/seller/portfolio_get_detail/";
const listPortfoliosURL = "/seller/portfolio_get_all/";
const basicProfileURL = "/general/get_public_profile_basic/";
const deletePortfolioURL = "/seller/portfolio_delete/";
const changeStatusPortfolioURL = "/seller/portfolio_change_status/";
const updatePortfolioURL = "/seller/portfolio_update/";
const updatePortfolioV2URL = "/seller/portfolio_update_v2/";
const retrieveGigPortfoliosURL = "/seller/gig_edit_portfolio/";
const updateGigPortfoliosURL = "/seller/gig_update_portfolio/";
const customOfferURL = "/seller/create_offer/";
const getOfferDetailURL = "/seller/offer_detail/";
const retrieveBuyerRequestsURL = "/seller/get_buyer_requests/";
const updateEducationHistoryURL = "/seller/update_education/";
const createEducationHistoryURL = "/seller/insert_education/";
const deleteEducationHistoryURL =  "/seller/delete_education/";
const updateWorkHistoryURL = "/seller/update_workhistory/";
const createWorkHistoryURL = "/seller/insert_workhistory/";
const deleteWorkHistoryURL = "/seller/delete_workhistory/";
const createSellerV5URL = "/seller/create_v5/";
const getPortfolioCommentsURL = "/feed/get_comments/";
const createNewPortfolioCommentURL = "/feed/new_comment/";
const getPortfolioLikesURL = "/general/get_liked_users/";
const addSocialAccountURL = "/seller/add_social_account/";
const deleteSocialAccountURL = "/seller/delete_social_account/";
const editDescribeYourselfURL = "/seller/edit_describe_yourself/";
const createApplicationURL = "/seller/create_application/";
const controlApplicationURL = "seller/control_application/";
const controlUpdateApplicationURL = "seller/control_update_application/";
const getGigCommentsURL = "/general/get_gig_comments/";
const getApplicationDetailURL = "/seller/application_detail/";
const updateApplicationURL = "/seller/update_application/";
const deleteApplicationURL = "/seller/delete_application/";
const iyziUpdateURL = "/seller/iyzi_update/";
const replyCommentURL = "/seller/reply_comment/";
const retrieveBuyerRequestInfoURL = "/seller/get_buyer_requests_info/";
const removeBiaStatusURL = "/seller/remove_bia/";
const addBiaStatusURL = "/seller/add_bia/";
const cancelOfferURL = "/seller/cancel_offer/";
const retrieveBicoinInfoURL = "/seller/bicoin_transactions/";
const useBicoinURL = "/seller/use_bicoin/";

const getBiaAnalytics = "/seller/get_bia_analytics/";
const getBicoinGigsURL = "/seller/get_gigs_v_light/";
const getFeaturedAnalyticsURL = "/seller/get_featured_analytics/";
const getAllOffersURL = "/seller/get_all_offers/";
const getStatisticsURL = "/seller/get_statistics/";
const getMainChartURL = "/seller/get_mainChart/";

const gigUpdateS1URL = "/seller/gig_update_s1/";
const gigUpdateS1URL_light = "/seller/gig_update_s1_light/";
const gigUpdateS2URL = "/seller/gig_update_s2/";
const gigUpdateS2_V2URL = "/seller/gig_update_s2_v2/";
const gigUpdateS3URL = "/seller/gig_update_s3/";
const gigUpdateS4URL = "/seller/gig_update_s4/";

const getGigPackageItemsURL = "/seller/get_gig_package_items/";
const getGigDefaultExtrasURL = "/seller/get_gig_default_extras/";

const changeAllGigStatusURL = "/seller/toplu_gig_change_status/";
const gigUpdateContentURL = "/seller/gig_update_content/";

export default {


  getSeller() {
    return helper.axiosWrapper(helper.axiosInstance, "post", getSellerURL, null);
  },

  editSeller(sentInfo, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", editSellerURL, progress, helper.createFormData( sentInfo ));
  },

  updateSkills(seller_info, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateSkillsURL, progress, helper.createFormData({ seller_info }));
  },

  updateSkills2(skills) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateSkills2URL, false, helper.createFormData({ skills }));
  },

  retrieveGigs(username, limit, offset, status, preventRedirect, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveGigsURL, progress, helper.createFormData({ username, limit, offset, status, preventRedirect }));
  },

  changeGigStatus(uuid, status, progress, forceControl=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", changeGigStatusURL, progress, helper.createFormData({ uuid, status, forceControl }));
  },

  getGigPriceTypes() {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigOptionsURL);
  },

  createGig(gig, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createGigURL, progress, helper.createFormData(gig));
  },

  getGig(uuid = null, slug = null, from = null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigDetailURL, false, helper.createFormData({ uuid, slug, from }));
  },

  updateGig(gig, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateGigURL, progress, helper.createFormData(gig));
  },

  getGigComments(slug, type, limit, offset) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigCommentsURL, false, helper.createFormData({ slug, type, limit, offset }));
  },

  updateGigDescription(uuid, description_textarea, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateGigDescriptionURL, progress, helper.createFormData({ uuid, description_textarea }));
  },




  createPortfolio(portfolio, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createPortfolioURL, progress, helper.createFormData(portfolio));
  },

  createPortfolioV2(portfolio, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createPortfolioV2URL, progress, helper.createFormData(portfolio));
  },

  getPortfolio(uuid, from = null, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getPortfolioURL, progress, helper.createFormData({ uuid, from }));
  },

  listPortfolios(username, status, limit, offset, progress, tag='') {
    return helper.axiosWrapper(helper.axiosInstance, "post", listPortfoliosURL, progress, helper.createFormData({ username, status, limit, offset, tag }));
  },

  basicProfile(username, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", basicProfileURL, progress, helper.createFormData({ username }));
  },

  deletePortfolio(uuid, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deletePortfolioURL, progress, helper.createFormData({ uuid }));
  },


  changePortfolioStatus(uuid, status, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", changeStatusPortfolioURL, progress, helper.createFormData({ uuid, status }));
  },

  updatePortfolio(portfolio, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updatePortfolioURL, progress, helper.createFormData(portfolio));
  },

  updatePortfolioV2(portfolio, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updatePortfolioV2URL, progress, helper.createFormData(portfolio));
  },

  retrieveGigPortfolios(uuid, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveGigPortfoliosURL, progress, helper.createFormData({ uuid }));
  },

  updateGigPortfolios(uuid, portfolioList, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateGigPortfoliosURL, progress, helper.createFormData({ uuid, portfolio: portfolioList }));
  },

  sendCustomOffer(customer_uuid, description, gig_uuid, duration, price, type, request_uuid = null, order_type, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", customOfferURL, progress, helper.createFormData({ customer_uuid, description, gig_uuid, duration, price, type, request_uuid, order_type }));
  },

  retrieveBuyerRequests(limit, offset, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveBuyerRequestsURL, progress, helper.createFormData({ limit, offset }));
  },

  getOfferDetail(id, progress, type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getOfferDetailURL, progress, helper.createFormData({ id, type }));
  },

  updateEducationHistory(educationInfo, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateEducationHistoryURL, progress, helper.createFormData(educationInfo));
  },

  createEducationHistory(educationInfo, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createEducationHistoryURL, progress, helper.createFormData(educationInfo));
  },

  deleteEducationHistory(id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteEducationHistoryURL, progress, helper.createFormData({ id }));
  },

  updateWorkHistory(workHistoryInfo, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateWorkHistoryURL, progress, helper.createFormData(workHistoryInfo));
  },

  createWorkHistory(workHistoryInfo, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createWorkHistoryURL, progress, helper.createFormData(workHistoryInfo));
  },

  deleteWorkHistory(id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteWorkHistoryURL, progress, helper.createFormData({ id }));
  },






  saveFreelancerInformationV5(describeYourself, brief, title, skills, birthDate, is_control, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createSellerV5URL, progress, helper.createFormData({describeYourself, brief, title, skills, birthDate, is_control}));
  },

  getPortfolioComments(type, id, limit, offset, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getPortfolioCommentsURL, progress, helper.createFormData({ type, id, limit, offset }))
  },

  createNewPortfolioComment(type, id, comment, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createNewPortfolioCommentURL, progress, helper.createFormData({ type, id, comment }))
  },

  getPortfolioLikes(type, uuid, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getPortfolioLikesURL, progress, helper.createFormData({ type, uuid }))
  },

  addSocialAccount(value, name, url) {
    return helper.axiosWrapper(helper.axiosInstance, "post", addSocialAccountURL, null, helper.createFormData({value, name, url }))
  },

  deleteSocialAccount(id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteSocialAccountURL, null, helper.createFormData({ id }))
  },

  editDescribeYourself(describeYourself) {
    return helper.axiosWrapper(helper.axiosInstance, "post", editDescribeYourselfURL, null, helper.createFormData({ describeYourself }))
  },

  createApplication(category_id, category_sub_id, category_service_types, category_service_kinds, description, upload_ids, category_application_links, is_fast_control, payment_id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", createApplicationURL, null, helper.createFormData({ category_id, category_sub_id, category_service_types, category_service_kinds, description, upload_ids, category_application_links, is_fast_control, payment_id }))
  },

  controlApplication(category_id, category_sub_id, category_service_types, category_service_kinds, description, upload_ids, category_application_links, is_fast_control) {
    return helper.axiosWrapper(helper.axiosInstance, "post", controlApplicationURL, null, helper.createFormData({ category_id, category_sub_id, category_service_types, category_service_kinds, description, upload_ids, category_application_links, is_fast_control }))
  },

  controlUpdateApplication(id,category_id, category_sub_id, category_service_types, category_service_kinds, description, upload_ids, category_application_links, is_fast_control) {
    return helper.axiosWrapper(helper.axiosInstance, "post", controlUpdateApplicationURL, null, helper.createFormData({id, category_id, category_sub_id, category_service_types, category_service_kinds, description, upload_ids, category_application_links, is_fast_control }))
  },

  getApplicationDetail(id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getApplicationDetailURL, null, helper.createFormData({ id }))
  },

  deleteApplication(id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", deleteApplicationURL, null, helper.createFormData({ id }))
  },

  updateApplication(id, category_id, category_sub_id, category_service_types, category_service_kinds, description, upload_ids, category_application_links, is_fast_control, payment_id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateApplicationURL, null, helper.createFormData({ id, category_id, category_sub_id, category_service_types, category_service_kinds, description, upload_ids, category_application_links,is_fast_control, payment_id }))
  },

  iyziUpdate(info) {
    return helper.axiosWrapper(helper.axiosInstance, "post", iyziUpdateURL, null, helper.createFormData( info ));
  },

  replyComment(comment_id, comment_reply) {
    return helper.axiosWrapper(helper.axiosInstance, "post", replyCommentURL, null, helper.createFormData({comment_id, comment_reply}));
  },

  retrieveBuyerRequestInfo() {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveBuyerRequestInfoURL, null);
  },

  removeBiaStatus(progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", removeBiaStatusURL, progress);
  },

  addBiaStatus(duration, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", addBiaStatusURL, progress, helper.createFormData({ duration }));
  },

  cancelOffer(offer_id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", cancelOfferURL, false, helper.createFormData({offer_id}));
  },

  retrieveBicoinTransactions(limit, offset, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveBicoinInfoURL, progress, helper.createFormData({ limit, offset }))
  },

  useBicoin(type, value, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", useBicoinURL, progress, helper.createFormData({ type, value }))
  },

  retrieveBiaAnalytics(limit, offset, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getBiaAnalytics, progress, helper.createFormData({ limit, offset }))
  },

  getBicoinGigs(progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getBicoinGigsURL, progress)
  },

  getFeaturedAnalytics(ID, type, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getFeaturedAnalyticsURL, progress, helper.createFormData({ ID, type }))
  },

  getAllOffers(limit, offset) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getAllOffersURL, false, helper.createFormData({ limit, offset }))
  },

  getStatistics() {
    return helper.axiosWrapper(helper.axiosInstance, "post", getStatisticsURL, false)
  },


  getMainChart(interval) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getMainChartURL, false, helper.createFormData({ interval }))
  },


  gigUpdateS1({uuid, title, category_id, category_sub_id, gig_service_kinds, gig_service_types, gig_service_kinds_json=null, gig_meta_options=null}, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", gigUpdateS1URL, progress, helper.createFormData({ uuid, title, category_id, category_sub_id, gig_service_kinds, gig_service_types,gig_service_kinds_json,gig_meta_options }))
  },

  gigUpdateS1_light({uuid, category_id, category_sub_id, gig_service_kinds, gig_service_types}, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", gigUpdateS1URL_light, progress, helper.createFormData({ uuid, category_id, category_sub_id, gig_service_kinds, gig_service_types }))
  },

  gigUpdateS2({uuid, gig_price_type_count, gig_price_type_id, gig_pricing_display_type, price, max_price, duration}, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", gigUpdateS2URL, progress, helper.createFormData({ uuid, gig_price_type_count, gig_price_type_id, gig_pricing_display_type, price, max_price, duration }))
  },



gigUpdateS2_V2({uuid, packageCount, basic, standard, premium, extras, voiceoverOptions}, progress) {
	return helper.axiosWrapper(helper.axiosInstance, "post", gigUpdateS2_V2URL, progress, helper.createFormData({ uuid,packageCount, basic, standard, premium, extras,voiceoverOptions }))
},

  /*

  $uuid = $this->super->post('uuid');
        $upload_id = $this->super->post('upload_id');
        $cover_id = $this->super->post('cover_id');
   */
  gigUpdateS3(uuid, description, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", gigUpdateS3URL, progress, helper.createFormData({uuid, description}))
  },

  gigUpdateContent(uuid, upload_id, cover_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", gigUpdateContentURL, progress, helper.createFormData({uuid, upload_id, cover_id}))
  },

  gigUpdateS4(uuid, requirements, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", gigUpdateS4URL, progress, helper.createFormData({uuid, requirements}))
  },

  getGigPackageItems(category_sub_id, category_service_type_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigPackageItemsURL, progress, helper.createFormData({category_sub_id, category_service_type_id}))
  },

  getGigDefaultExtras(category_sub_id, category_service_type_id, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGigDefaultExtrasURL, progress, helper.createFormData({category_sub_id, category_service_type_id}))
  },

  changeAllGigStatus(status, current_status, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", changeAllGigStatusURL, progress, helper.createFormData({status, current_status}))
  },

}
