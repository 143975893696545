<template>
  <div id="app">
    <vue-progress-bar v-if="!isMobile"/>
    <div v-if="!$store.state.calledInit">
      <div style=" width:0;margin: 0 auto; text-align: center; padding-top: 200px;">
        <div class="loader" style="text-align: center"></div>
      </div>
    </div>
    <router-view />
    <smart-banner v-if="showSmartBanner" @close="showSmartBanner = false" />
  </div>
</template>

<script>
 import Pusher from "pusher-js";
 import SmartBanner from "@/pages/components/SmartBanner"
 import Api from "@/api";
  Pusher.logToConsole = false;

  export default {
    name: "app",
    components: { SmartBanner },
    data() {
      return {
        mobilePopSound: null,
        totalUnreadNotificationCount: 0,
        showSmartBanner: false
      }
    },
    methods: {
      VueProgressStart() {
        if (!this.$Progress.state.timer) {
          // console.log("progress started", this.$Progress.$vm.RADON_LOADING_BAR.options.transition)
          this.$Progress.state.counter = 100;

          this.$Progress.$vm.RADON_LOADING_BAR.percent = 0; // this.$vm.RADON_LOADING_BAR.percent
          this.$Progress.$vm.RADON_LOADING_BAR.options.show = true;
          this.$Progress.$vm.RADON_LOADING_BAR.options.canSuccess = true;
          this.$Progress.state.timer = setInterval(() => {
            this.$Progress.$vm.RADON_LOADING_BAR.percent += 0.5;
            if (this.$Progress.$vm.RADON_LOADING_BAR.percent > this.$Progress.state.counter - 1) {
              this.$Progress.finish();
            }
          }, 100);
        }
      },
      VueProgressStop() {
        if (!!this.$Progress.state.timer) {
          this.$Progress.finish();
        }
      },
      VueProgressFail() {
        this.$Progress.fail();
      }
    },
    watch: {
      '$store.state.user.unreadMessageCount': function (newVal, oldVal) {
       this.totalUnreadNotificationCount = this.$store.state.user.unreadNotificationCount + newVal;
      },

      '$store.state.user.unreadNotificationCount': function (newVal, oldVal) {
        this.totalUnreadNotificationCount = this.$store.state.user.unreadMessageCount + newVal;
      },

      'totalUnreadNotificationCount': function (newVal, oldVal) {
        let splittedTitle = null;

        if (oldVal > 0) {
          splittedTitle = document.title.split('(' + oldVal + ') ');
        }
        if (oldVal && newVal > 0 && splittedTitle) {
          document.title = '(' + newVal + ') ' + splittedTitle[1];
        } else if (!oldVal && newVal > 0) {
          document.title = '(' + newVal + ') ' + document.title;
        } else if (newVal === 0 && splittedTitle) {
          document.title = splittedTitle[1];
        }
      },

      '$store.state.indexHTMLTitle': function (newVal, oldVal) {
        if (newVal) {
          document.title = this.totalUnreadNotificationCount > 0 ? '(' + this.totalUnreadNotificationCount + ') ' + newVal : newVal;
        } else {
          document.title = this.totalUnreadNotificationCount > 0 ? '(' + this.totalUnreadNotificationCount + ') ' + this.$store.state.defaultTitle : this.$store.state.defaultTitle;
        }
      },

      '$store.state.indexHTMLMetaDescription': function (newVal, oldVal) {
        if (newVal) {
          if(document.head.querySelector('meta[name=description]')){
            document.head.querySelector('meta[name=description]').content = newVal;
          }
        } else {
          if( document.head.querySelector('meta[name=description]')){
            document.head.querySelector('meta[name=description]').content = this.$store.state.defaultDescription;
          }
        }
      },

      '$store.state.user.uuid': function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.Helper.trackEvents.setUser();
        }
      },

      '$store.state.isIdle': function (newVal) {
        if (newVal && this.$store.state.globalPusher) {
          this.$store.state.globalPusher.disconnect();
        }
      },

      '$store.state.route.fullPath': function (newValue, oldValue) {
        // statik sayfalarda çalıştırma koşulu
        if (!this.$store.state.routerParams.length) {
          // search sayfası statik ama aldığı querystring ile dinamik bir sayfa, yani query dğeiştikçe farklı sonuç getiriyor. Bu nedenle tekrar pageView göndermesini istemiyoruz.

          if (newValue !== '/search' &&
            newValue !== '/500' &&
            !newValue.includes('/login/callback') &&
            !newValue.includes("/biyzib") &&
            !newValue.includes("/biyzibApplication") &&
            !newValue.includes("/search?term") &&
            !newValue.includes("/usersearch")) {
            this.Helper.trackEvents.pageView();
          }
        }

        // to manage mobile routing
        let mobileRoutes = this.$store.state.mobileRoutes;
        let from = this.$store.state.route.from;


        if (from && mobileRoutes[mobileRoutes.length - 1] !== from.fullPath && !this.isRoutedBack) {
          let pushvar = this.$store.state.route.from && this.$store.state.route.from.fullPath ? this.$store.state.route.from.fullPath : '/';
          this.$store.state.mobileRoutes.push(pushvar);
        }

        if (this.isRoutedBack) {
          this.$store.state.isRoutedBack = false;
        }
        //

        // path değiştiğinde modal'ı kapatıyor
        if (newValue !== oldValue && this.$store.state.activeModal.modalType) {
          this.$store.commit(this.types.DISABLE_MODAL);
        }
      },
    },

    created() {

      Api.user.getTodoCount()
        .then(({data}) => {
          this.user.todoCount = data.data.count
        })

      this.EventBus.$on("activateProgressBar", () => {
        this.VueProgressStart();
      });

      this.EventBus.$on("deactivateProgressBar", () => {
        this.VueProgressStop();
      });

      this.EventBus.$on("timeout", () => {
        this.VueProgressFail();
      });


      let isFromMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);

      let isMobileSafari = navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
      let pathname = window.location.pathname
      let firstSegmentOfPath = pathname.split('/')

      let firstSegmentsIncluded = ['orders', 'tickets', 'panel', 'settings', 'chat', 'freelancer-bul', 'liste', 'koleksiyon'];
      if (isMobileSafari && !this.Helper.isIndexerBotOrDevelopmentEnv() && firstSegmentsIncluded.includes(firstSegmentOfPath[1])) {
        this.showSmartBanner = true
        /*
        try {
          window.location.replace("bionluk:/"+window.location.pathname+location.search)
        } catch(err) {}
         */
      }

      this.EventBus.$on("listenPusherChannelToGetInfoForUser", () => {

        if (this.user.channelID && !isFromMobile) {

          this.$store.state.globalPusher = new Pusher('6f36267d14ebeca4652a', { cluster: 'eu', encrypted: true })

          let generalChannel = this.$store.state.globalPusher.subscribe("system");

          generalChannel.bind('frontendVersion', received => {
            this.$store.state.newVersion = received.version;
          });

          let channel = this.$store.state.globalPusher.subscribe(this.user.channelID);




	        channel.bind('userService', payload => {
            if(payload.payloadType === 'logout') {
              const token = localStorage.getItem("token");
              if( token === payload.token){
                setTimeout(() => {
                  location.reload();
                }, 1000)
              }
            }
            if(payload.payloadType === 'openOrders') {
              let openOrders = this.user.openOrders;
              let orderIndex = this.user.openOrders.findIndex(l => l.order_id === payload.order_id);
              if(orderIndex > -1){
                if(payload.action === 'delete'){
                  this.user.openOrders.splice(orderIndex, 1);
                } else {
                  openOrders[orderIndex] = payload.order;
                  this.user.openOrders = openOrders;
                }
              } else {
                if(payload.action !== 'delete'){
                  openOrders.unshift(payload.order);
                  this.user.openOrders = openOrders;
                }
              }
            }
            if(payload.payloadType === 'setTodoCount') {
              this.playNotificationSound();
              this.user.todoCount = payload.todoCount;
            }

            if(payload.payloadType === 'setRequestCount') this.user.requestCount = payload.requestCount;
            if(payload.payloadType === 'skillCount') this.user.seller = { ...this.user.seller, skillCount: payload.skillCount }
            if(payload.payloadType === 'gigCount')  this.user.seller = { ...this.user.seller, gigCount: payload.gigCount }
            if(payload.payloadType === 'portfolioCount') this.user.seller = { ...this.user.seller, portfolioCount: payload.portfolioCount }
            if(payload.payloadType === 'isRegistered') this.user.seller = { ...this.user.seller, isRegistered: payload.isRegistered }

            if(payload.payloadType === 'setNotificationCount') this.user.unreadNotificationCount = payload.unreadNotificationCount;
            if(payload.payloadType === 'reloadOrderPage') this.EventBus.$emit('reloadOrderPage', 'reload');
            if(payload.payloadType === 'newNotification') this.playNotificationSound();

	        });


	        channel.bind('chatService', payload => {
		        if(payload.payloadType === 'setReceivedMessagesCount'){
			        this.user.unreadMessageCount = payload.unreadMessageCount;
			        let index = this.user.unreadMessages.indexOf(payload.username);
			        if (index > -1) {
				        this.user.unreadMessages.splice(index, 1)
                this.EventBus.$emit('flashMessage', null);
			        }
			        //chatUsers'ı da güncelle
              let chatuser_index = this.$store.state.chatUsers.findIndex(m => m.r_username === payload.username);
			        if(chatuser_index > -1){
			          let cUser = this.$store.state.chatUsers[chatuser_index];
                cUser.isseen = true;
                const cloneMessageUsers = this.$store.state.chatUsers;
                cloneMessageUsers[chatuser_index] = cUser
                this.$store.state.chatUsers = cloneMessageUsers;
              }

		        } else if(payload.payloadType === 'new'){

              let c_index = this.$store.state.openConversations.findIndex(m => m.c_uuid === payload.c_uuid);
              if(payload.username !== this.user.username && c_index === -1 && this.$store.state.route.fullPath !== `/chat/${payload.r_username}`){

                this.EventBus.$emit('flashMessage', payload);
              }

              this.Helper.updateChatUserList(payload, this.user);

              this.EventBus.$emit('bindPusher', payload);
              this.EventBus.$emit('bindPusherChatDetail', payload);
            } else if(payload.payloadType === 'writing'){
              this.EventBus.$emit('bindPusher', payload);
              this.EventBus.$emit('bindPusherChatDetail', payload);
              let chatuser_index = this.$store.state.chatUsers.findIndex(m => m.c_uuid === payload.c_uuid);
              if(chatuser_index > -1){
                if(this.$store.state.chatUsers[chatuser_index].is_writing === false){
                  this.$store.state.chatUsers[chatuser_index].is_writing = payload.type;
                  this.$store.state.chatUsers[chatuser_index].is_writing_percentage = payload.percentage;
                  this.$store.state.chatUsers[chatuser_index].listenedWritingTimeout = setTimeout(() => {
                    if(this.$store.state.chatUsers[chatuser_index]){
                      this.$store.state.chatUsers[chatuser_index].is_writing = false;
                    }
                  }, 6000);
                } else {
                  clearTimeout(this.$store.state.chatUsers[chatuser_index].listenedWritingTimeout);
                  this.$store.state.chatUsers[chatuser_index].listenedWritingTimeout = null;
                  this.$store.state.chatUsers[chatuser_index].is_writing = payload.type;
                  this.$store.state.chatUsers[chatuser_index].is_writing_percentage = payload.percentage;
                  this.$store.state.chatUsers[chatuser_index].listenedWritingTimeout = setTimeout(() => {
                    if(this.$store.state.chatUsers[chatuser_index]){
                      this.$store.state.chatUsers[chatuser_index].is_writing = false;
                    }
                  }, 6000);
                }

              }

            } else if(payload.payloadType === 'chat_seen'){
              let chatuser_index = this.$store.state.chatUsers.findIndex(m => m.c_uuid === payload.c_uuid);
              if(chatuser_index > -1){
                this.$store.state.chatUsers[chatuser_index].is_receiver_seen = payload.created_at;
              }
              this.EventBus.$emit('bindPusher', payload);
              this.EventBus.$emit('bindPusherChatDetail', payload);
            } else if(payload.payloadType === 'blockActions'){
              this.EventBus.$emit('bindPusher', payload);
              this.EventBus.$emit('bindPusherChatDetail', payload);
            } else if(payload.payloadType === 'delete'){
              this.EventBus.$emit('bindPusher', payload);
              this.EventBus.$emit('bindPusherChatDetail', payload);
            }

	        });


	        channel.bind('setLastSeenItems', payload => {

	        	if(payload && payload.type){
	        		let type = payload.type;

	        		if(type === 'delete'){
	        			  if(payload.id === 'all'){
	        			  	let newList = [];
					          this.$store.state.lastSeenItems = newList;
                  } else {
					          let index = -1
					          index = this.$store.state.lastSeenItems.findIndex(l => l.id === payload.id);
					          if(index > -1){
						          this.$store.state.lastSeenItems.splice(index, 1);
                    }
                  }
              } else {
				        let index = -1
                if(this.$store.state.lastSeenItems.length > 0){
	                if(type === 'profile'){
		                index = this.$store.state.lastSeenItems.findIndex(l => (l.type === 'profile' && l.user_id === payload.user_id));
	                }
	                if(type === 'gig'){
		                index = this.$store.state.lastSeenItems.findIndex(l => (l.type === 'gig' && l.gig_id === payload.gig_id));
	                }
	                if(type === 'category'){
		                index = this.$store.state.lastSeenItems.findIndex(l => (l.type === 'category' && l.title === payload.title));
	                }
                }



				        if(index > -1){
					        let list = this.$store.state.lastSeenItems;
					        let newList = list.splice(index, 1)[0];
					        let savelist = [newList, ...list]
					        this.$store.state.lastSeenItems = savelist;
				        } else {
					        let list = this.$store.state.lastSeenItems;
					        let newList = [payload, ...list]
					        this.$store.state.lastSeenItems = newList;
				        }
              }
            }
	        });
        }
      });



      window.addEventListener('resize', result => {
        this.$store.state.windowHeight = result.target.innerHeight;
	      this.$store.state.windowWidth = result.target.innerWidth;
      });

	    // scrollTop bodydeki scrollun pozisyonunu alır, scrollHeight bodynin uzunluğunu verir.
      window.addEventListener("scroll", result => {
        this.$store.state.windowScrollHeight = document.body.offsetHeight || document.documentElement.offsetHeight;
        this.$store.state.windowScrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      });


      //user interacred
      window.addEventListener("click", result => {
        this.$store.state.userInteractedWithDOM = true
      });


      //pusher auto disconnect
      let timeoutId = null;
      window.addEventListener("mousemove", result => {
        if(timeoutId !== null) {
          window.clearTimeout(timeoutId);
        }
        startInactivityCheck();
      });
       let startInactivityCheck = () => {
        timeoutId = window.setTimeout(() =>{
          this.$store.state.isIdle = true;
          if(this.$store.state.globalPusher){
            this.$store.state.globalPusher.disconnect();
          }
        }, 180 * 60 * 1000); // called after 5 minutes 5 * 60 * 1000 , 360 yani 180 dakka = 3 saat inactive de suan
      }
      startInactivityCheck();


      // check if user comes from mobile
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        this.$store.state.isMobile = true;
      }
    }
  }
</script>

<style lang="scss">

.support-category-content-text{
  line-height: 1.88;
  div{
    img{
      width: 100%!important;
    }
  }
}

.star-detail-info-icon-wrapper{
  width: 12px;
  height: 12px;
  opacity: 0.4;
  border-radius: 5px;

  cursor: pointer;
  &:hover{
    opacity: 0.75;
  }
  .star-detail-info-icon /deep/{
    width: 12px;
    height: 12px;
    path{
      fill: #2d3640;
    }
  }
}

  /*bütün sayfalara scrollbar değişikliği*/
  body {
    overflow: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: #5e6b79;
    }
  }

  /* NAV ITEMS */

  .nav-menutext {
    font-size: 13px;
    font-weight: 600 !important;
    color: #242424;
    margin-bottom: 5px;

  }

  #toasted-container {
    z-index: 99999999999 !important;
  }

  .nav {
    padding-top: 40px;
    background-color: white;
    max-width: 220px;
    float: left;
    padding-bottom: 10px;
  }

  .nav-items {
    margin-bottom: 30px;
    text-align: left;
  }

  ul.nav-list {
    list-style-type: none;
    width: 220px;
  }

  .nav-list li a {
    text-decoration: none;
    cursor: pointer;
    padding: 10px 0 10px 0px;
    font-size: 14px;
    color: #8b95a1;
    display: block;
  }

  .nav-list li :hover {
    p {
      color: #000 !important;
    }
  }

  .nav-list li img {
    padding-right: 14px;
    vertical-align: middle;
  }

  .nav-list .active {
    color: #000 !important;
    border-right: 3px solid #fd4056;
  }


  .nav-left-footer {
    margin-right: 10px;
    border-top: 1px solid #dce1e1;


    /* position: fixed;  margin-top: 500px; */
  }

  .nav-left-footer ul {
    list-style-type: none;
  }

  .nav-left-footer li, .nav-left-footer p {
    display: inline-block;
    font-size: 12px;
    color: #868a8a;
    padding-top: 15px;
  }

  .nav-left-footer li:after {
    content: '•';
    padding: 0 7px;
  }

  .nav-left-footer li:last-child:after {
    content: '';
  }


  /* NAV ITEMS FINISHED */


  /* GENERAL */

  * {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'sofia-pro';
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 16px;
    color: #5E6B79;
    -webkit-overflow-scrolling: touch;
  }


  input {
    font-family: 'sofia-pro';
    font-style: normal;
    font-weight: 400;
    color: #2d3640;
    background-color: #fefdfd;
    border: solid 1px #bfc8d2;
    border-radius: 2px;
    font-size: 14px;
    vertical-align: middle;
    padding: 9px 10px 11px;
  }

  input:focus {
    outline: none;
    border: solid 1px #2d3640;


  }

  input[readonly] {
    color: gray;
  }


  textarea:focus {
    outline: none;
    border: solid 1px #2d3640;
  }

  select:focus {
    outline: 0;
  }



  .cuper-input-normal{
    font-size:18px;font-weight:normal;
    color:#2d3640; border: solid 1px #bfc8d2;
    padding: 0 8px 0 25px;
    height: 77px;
    display: flex;
    align-items: center;

    &:placeholder-shown {

    }

    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {

      &:placeholder-shown {

      }
    }}




    &:focus{
      border: solid 1px #00a575;
    }


    &::placeholder {
      font-size: 18px;
      font-weight: normal;
      color: #8b95a1;
    }
  }


  .cuper-input{
    color:#2d3640; font-size:30px; font-weight:600;border: solid 1px #bfc8d2;width: 393px;
    padding: 9px 8px 11px 25px;
    height: 57px;

    &:placeholder-shown {
      padding: 0 8px 11px 25px;
      height: 66px;
    }

    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {

      &:placeholder-shown {
       display: flex;
        padding: 20px 8px 0 25px;
        height: 57px;
      }
    }}




    &:focus{
      border: solid 1px #00a575;
    }


    &::placeholder {
      font-size: 18px;
      font-weight: normal;
      color: #8b95a1;
    }
  }
  textarea {
    border-radius: 2px;
    background-color: #fefdfd;
    border: solid 1px #bfc8d2;
    resize: none;
    padding: 10px 10px;
    font-size: 14px;
    font-family: "sofia-pro";
    color: #2d3640;
  }


  .hiddendiv {
    height: 30px;
    visibility: hidden;
  }

  .hiddendiv20 {
    height: 20px;
    visibility: hidden;
  }

  .hiddendiv10 {
    height: 10px;
    visibility: hidden;
  }


  ul {
    list-style: none
  }

  .posrelative {
    position: relative
  }


  button:focus {
    outline: none;
  }


  button:disabled,
  button[disabled] {
    background-color: #bfc8d2 !important;
    border-color: #bfc8d2!important;

  }

  select[disabled] {
    background-color: #DDE3EB !important;
    color: #5E6B79 !important;
  }


  button[disabled]:hover {
    cursor: default;
    background-color: #bfc8d2 !important;

  }

  button:hover {
    background-color: #37414b !important;
    color: white;
  }


  button {
    cursor: pointer;
    font-family: "sofia-pro";
  }


  ::-webkit-input-placeholder {
    color: #8b95a1;
  }

  ::-moz-placeholder {
    color: #8b95a1;
  }

  label.saveButton {

    width: 70px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    margin-top: 0px;
    padding-top: 0;
    font-weight: 500;
    height: 30px;
    border-radius: 100px;
    background-color: #2d3640;
    border: none;
  }


  button.saveButton {

    width: 70px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    padding: 6px 7px 9px;
    font-weight: 500;
    height: 30px;
    border-radius: 100px;
    background-color: #2d3640;
    border: none;
  }


  .editButton {

    width: 70px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #ffffff;
    padding: 6px 7px 9px;
    font-weight: 500 !important;
    height: 30px;
    border-radius: 100px;
    background-color: #8b95a1;
    border: none;


  }


  .editButton:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #5E6B79 !important;
  }


  .editGreenButton {


    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #fff;
    padding: 3px 15px 5px;
    font-weight: 500 !important;
    height: 24px;
    border-radius: 100px;
    background-color: #00a575;
    border: none;


  }


  .editGreenButton:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #2cad87 !important;
  }


  label {


    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #2d3640;

    margin-bottom: 5px;
    display: block;
  }


  .content {
    min-width: 890px;
    bottom: 0;
    height: 100%;
    background-color: #f4f5f7 !important;
    float: right;
  }

  .content-white {
    width: 845px;
    padding: 30px;
    margin-left: 30px;
    background-color: #ffffff;
    border: solid 1px #eaedf2;
  }


  .content-ustband {
    width: 845px;
    padding: 0 30px;
    margin-left: 30px;
    background-color: #ffffff;
    height: 60px;
  }


  .content-ustband-text {

    font-size: 16px;
    color: #a1a9a9;

  }

  .content-ustband-text.active {

    font-weight: 600;
    color: #4b4f52;
  }

  .cancelclick {
    cursor: pointer;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 12px;
  }

  .cancelclick:hover {
    text-decoration: underline;
  }


  .linkclick {
    cursor: pointer;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 12px;
    color: #2d3640;
  }

  .linkclick:hover {
    text-decoration: underline;
  }


  .title-text {

    font-weight: 500;
    font-size: 16px;
    color: #5E6B79;

  }

  .subheading2-text {

    font-size: 16px;
    font-weight: 600;
    color: #5e6b79;
  }

  .subheading1-text {

    font-size: 18px;
    font-weight: 500;
    color: #5e6b79;
  }


  .skilltag {
    height: 20px;
    border-radius: 4px;
    background-color: #fff;
    font-family: "sofia-pro";
    font-size: 14px;
    color: #2d3640;
    border: 1px solid #2d3640;
    padding: 3px 6px;
    margin-right: 10px;
  }


  .u_tooltip {
    position: relative;
    display: inline-block;

  }

  .u_tooltip .u_tooltiptext {
    visibility: hidden;

    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 6px 8px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    opacity: .8;

    margin-left: -60px;
    font-size: 13px;
  }

  .u_tooltip .u_tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;

    border-color: black transparent transparent transparent;
  }

  .u_tooltip:hover .u_tooltiptext {
    visibility: visible;
  }


  button[data-balloon] {
    overflow: visible
  }

  [data-balloon] {
    position: relative;
    cursor: pointer
  }

  [data-balloon]:after {
    font-family: "sofia-pro"!important;
    filter: alpha(opactiy=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.18s ease-out 0.18s;
    -moz-transition: all 0.18s ease-out 0.18s;
    -ms-transition: all 0.18s ease-out 0.18s;
    -o-transition: all 0.18s ease-out 0.18s;
    transition: all 0.18s ease-out 0.18s;
    font-weight: normal !important;
    font-style: normal !important;
    text-shadow: none !important;
    font-size: 12px !important;
    background: rgba(17, 17, 17, 0.9);
    border-radius: 4px;
    color: #fff;
    content: attr(data-balloon);
    padding: .5em 1em;
    position: absolute;
    white-space: nowrap;
    z-index: 10
  }

  [data-balloon]:before {
    background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17,17,17,0.9)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
    background-size: 100% auto;
    width: 18px;
    height: 6px;
    filter: alpha(opactiy=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.18s ease-out 0.18s;
    -moz-transition: all 0.18s ease-out 0.18s;
    -ms-transition: all 0.18s ease-out 0.18s;
    -o-transition: all 0.18s ease-out 0.18s;
    transition: all 0.18s ease-out 0.18s;
    content: '';
    position: absolute;
    z-index: 10
  }

  [data-balloon]:hover:before,[data-balloon-visible]:before, [data-balloon-visible]:after, [data-balloon]:hover:after, [data-balloon][data-balloon-visible]:before, [data-balloon][data-balloon-visible]:after {
    filter: alpha(opactiy=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    pointer-events: auto
  }

  [data-balloon].font-awesome:after {
    font-family: FontAwesome
  }

  [data-balloon][data-balloon-break]:after {
    white-space: pre
  }

  [data-balloon][data-balloon-blunt]:before, [data-balloon][data-balloon-blunt]:after {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none
  }

  [data-balloon][data-balloon-pos="up"]:after, [data-balloon-visible]:after {
    bottom: 100%;
    left: 50%;
    margin-bottom: 11px;
    -webkit-transform: translate(-50%, 10px);
    -moz-transform: translate(-50%, 10px);
    -ms-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px!important;
    font-weight: 500!important;
    color: #ffffff;
    min-width: 130px;
  }

  [data-balloon][data-balloon-pos="up"]:before, [data-balloon-visible]:before {
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
    -webkit-transform: translate(-50%, 10px);
    -moz-transform: translate(-50%, 10px);
    -ms-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top
  }

  [data-balloon][data-balloon-pos="up"]:hover:after, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:after {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
  }

  [data-balloon][data-balloon-pos="up"]:hover:before, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:before {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
  }

  [data-balloon][data-balloon-pos="up-left"]:after {
    bottom: 100%;
    left: 0;
    margin-bottom: 11px;
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top
  }

  [data-balloon][data-balloon-pos="up-left"]:before {
    bottom: 100%;
    left: 5px;
    margin-bottom: 5px;
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top
  }

  [data-balloon][data-balloon-pos="up-left"]:hover:after, [data-balloon][data-balloon-pos="up-left"][data-balloon-visible]:after {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
  }

  [data-balloon][data-balloon-pos="up-left"]:hover:before, [data-balloon][data-balloon-pos="up-left"][data-balloon-visible]:before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
  }

  [data-balloon][data-balloon-pos="up-right"]:after {
    bottom: 100%;
    right: 0;
    margin-bottom: 11px;
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top
  }

  [data-balloon][data-balloon-pos="up-right"]:before {
    bottom: 100%;
    right: 5px;
    margin-bottom: 5px;
    -webkit-transform: translate(0, 10px);
    -moz-transform: translate(0, 10px);
    -ms-transform: translate(0, 10px);
    transform: translate(0, 10px);
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top
  }

  [data-balloon][data-balloon-pos="up-right"]:hover:after, [data-balloon][data-balloon-pos="up-right"][data-balloon-visible]:after {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
  }

  [data-balloon][data-balloon-pos="up-right"]:hover:before, [data-balloon][data-balloon-pos="up-right"][data-balloon-visible]:before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
  }

  [data-balloon][data-balloon-pos='down']:after {
    left: 50%;
    margin-top: 11px;
    top: 100%;
    -webkit-transform: translate(-50%, -10px);
    -moz-transform: translate(-50%, -10px);
    -ms-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px)
  }

  [data-balloon][data-balloon-pos='down']:before {
    background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17,17,17,0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
    background-size: 100% auto;
    width: 18px;
    height: 6px;
    left: 50%;
    margin-top: 5px;
    top: 100%;
    -webkit-transform: translate(-50%, -10px);
    -moz-transform: translate(-50%, -10px);
    -ms-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px)
  }

  [data-balloon][data-balloon-pos='down']:hover:after, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:after {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
  }

  [data-balloon][data-balloon-pos='down']:hover:before, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:before {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
  }

  [data-balloon][data-balloon-pos='down-left']:after {
    left: 0;
    margin-top: 11px;
    top: 100%;
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    transform: translate(0, -10px)
  }

  [data-balloon][data-balloon-pos='down-left']:before {
    background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17,17,17,0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
    background-size: 100% auto;
    width: 18px;
    height: 6px;
    left: 5px;
    margin-top: 5px;
    top: 100%;
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    transform: translate(0, -10px)
  }

  [data-balloon][data-balloon-pos='down-left']:hover:after, [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:after {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
  }

  [data-balloon][data-balloon-pos='down-left']:hover:before, [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
  }

  [data-balloon][data-balloon-pos='down-right']:after {
    right: 0;
    margin-top: 11px;
    top: 100%;
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    transform: translate(0, -10px)
  }

  [data-balloon][data-balloon-pos='down-right']:before {
    background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17,17,17,0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
    background-size: 100% auto;
    width: 18px;
    height: 6px;
    right: 5px;
    margin-top: 5px;
    top: 100%;
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    -ms-transform: translate(0, -10px);
    transform: translate(0, -10px)
  }

  [data-balloon][data-balloon-pos='down-right']:hover:after, [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:after {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
  }

  [data-balloon][data-balloon-pos='down-right']:hover:before, [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
  }

  [data-balloon][data-balloon-pos='left']:after {
    margin-right: 11px;
    right: 100%;
    top: 50%;
    -webkit-transform: translate(10px, -50%);
    -moz-transform: translate(10px, -50%);
    -ms-transform: translate(10px, -50%);
    transform: translate(10px, -50%)
  }

  [data-balloon][data-balloon-pos='left']:before {
    background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17,17,17,0.9)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
    background-size: 100% auto;
    width: 6px;
    height: 18px;
    margin-right: 5px;
    right: 100%;
    top: 50%;
    -webkit-transform: translate(10px, -50%);
    -moz-transform: translate(10px, -50%);
    -ms-transform: translate(10px, -50%);
    transform: translate(10px, -50%)
  }

  [data-balloon][data-balloon-pos='left']:hover:after, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:after {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
  }

  [data-balloon][data-balloon-pos='left']:hover:before, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:before {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
  }

  [data-balloon][data-balloon-pos='right']:after {
    left: 100%;
    margin-left: 11px;
    top: 50%;
    -webkit-transform: translate(-10px, -50%);
    -moz-transform: translate(-10px, -50%);
    -ms-transform: translate(-10px, -50%);
    transform: translate(-10px, -50%)
  }

  [data-balloon][data-balloon-pos='right']:before {
    background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17,17,17,0.9)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
    background-size: 100% auto;
    width: 6px;
    height: 18px;
    left: 100%;
    margin-left: 5px;
    top: 50%;
    -webkit-transform: translate(-10px, -50%);
    -moz-transform: translate(-10px, -50%);
    -ms-transform: translate(-10px, -50%);
    transform: translate(-10px, -50%)
  }

  [data-balloon][data-balloon-pos='right']:hover:after, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:after {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
  }

  [data-balloon][data-balloon-pos='right']:hover:before, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:before {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
  }

  [data-balloon][data-balloon-length='small']:after {
    white-space: normal;
    width: 80px
  }

  [data-balloon][data-balloon-length='medium']:after {
    white-space: normal;
    width: 150px
  }

  [data-balloon][data-balloon-length='large']:after {
    white-space: normal;
    width: 260px
  }

  [data-balloon][data-balloon-length='xlarge']:after {
    white-space: normal;
    width: 380px
  }

  @media screen and (max-width: 768px) {
    [data-balloon][data-balloon-length='xlarge']:after {
      white-space: normal;
      width: 90vw
    }
  }

  [data-balloon][data-balloon-length='fit']:after {
    white-space: normal;
    width: 100%
  }


  .cuper-red-button{
    outline: none;
    width: 178px;
    height: 46px;
    border:none;

    background-color: #fd4056;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.3px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      background-color: #fd4056!important;
      opacity: 0.9;
    }
    &:disabled {
      background-color: #999 !important;
    }
  }


  .cuper-green-button{
    outline: none;
    width: 220px;
    height: 54px;
    border:none;

    background-color: #00a575;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.3px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      background-color: #00a575!important;
      opacity: 0.9;
    }
    &:disabled {
      background-color: #bfc8d2 !important;
    }
  }


  .cuper-white-button{

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding-bottom: 1px;
    font-size: 14px;
    border: solid 1px #eaedf2;
    background-color: #ffffff;



    font-weight: 500;
    text-align: center;
    color: #2d3640;
    &:hover{
      opacity: 0.85;
      color: #2d3640!important;
      background-color: #ffffff!important;
    }
  }


  .cuper-black-button{

    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.3px;
    text-align: center;


    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding-bottom: 1px;

    border: solid 1px #eaedf2;
    background-color: #2d3640;


    color: #fff;
    &:hover{
      opacity: 0.9;
      color: #fff!important;
      background-color: #fd4056!important;
    }
  }


  .super-button {
    border-radius: 2px;
    border: none;
    background-color: #2d3640;
    font-size: 15px;
    padding: 3px 10px 5px;
    font-weight: 500;
    color: #ffffff;
    height: 44px;
    min-width: 200px;
  }

  .super-button:hover {
    background-color: #242424 !important;
  }

  .super-button-green {
    border-radius: 2px;
    border: none;
    background-color: #26CA9A;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    height: 44px;
    min-width: 200px;
  }

  .super-button-green:hover {
    border-radius: 2px;
    border: none;
    background-color: #00a575 !important;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    height: 44px;
    min-width: 200px;
  }

   .bionluk-red {
        color:#fd4056 !important
   }

  .green-button {

    background-color: #26ca9a !important;
  }

  .green-button:hover {

    background-color: #2cad87 !important;
  }


  .red-tansel-button {

    background-color: #ff0042 !important;
  }

  .ted-tansel-button {

    background-color: #ff0042 !important;
  }


  .input-super-button-rounded-light {
    cursor: pointer;
    height: 30px;
    margin: 0 auto;
    text-align: center;
    border-radius: 15px;
    width: auto;
    max-width: 100px;
    background-color: #ffffff;
    line-height: 30px;
    font-size: 12px;
    font-weight: 600;
    color: #2d3640;
    border: solid 1px #2d3640;
    padding: 0px 15px;
  }

  .input-super-button-rounded-light:hover {
    background-color: #37414b !important;
    color: white;
  }

  .super-button-light-rounded {
    height: 30px;
    border-radius: 15px;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    color: #2d3640;
    border: solid 1px #2d3640;
    padding: 0px 15px;

  }


  .super-button-small {
    height: 35px;
    border-radius: 2px;
    background-color: #2d3640;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    border: solid 1px #2d3640;
    padding: 0px 15px 2px;

  }


  .super-button-light {
    margin-top: 12px;
    border-radius: 2px;
    background-color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    color: #2d3640;
    height: 44px;
    border: solid 1px #2d3640;
    padding: 9px 36px 13px;

  }

  .super-button-light:hover {

    background-color: #ffffff !important;
    color: #2d3640 !important;
  }


  .or {
    width: 344px;
    text-align: center;
    border-bottom: solid 1px #8b95a1;
    line-height: 0.1em;
    margin: 0 auto;
  }


  .or span {
    background: #fff;
    padding: 0 20px;
    font-size: 16px;
    color: #8b95a1;
  }


  .modal-container {

    width: 850px !important;

  }

  @media only screen and (max-width: 780px) {

    .modal-container {

      width: 90% !important;

    }

    .or {
      width: 90%;
      text-align: center;
      border-bottom: solid 1px #8b95a1;
      line-height: 0.1em;
      margin: 0 auto;
    }


    .or span {
      background: #fff;
      padding: 0 20px;
      font-size: 16px;
      color: #8b95a1;
    }

    button, button:hover, li:hover, a:hover, li, a, *:hover, * {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }

  }


  ul {
    list-style: none
  }


  .pageContainer {

    width: 1140px !important;
    margin: 0 auto;


  }


  .line {

    border-top: 1px solid #eaedf2;
  }

  .nav-list-new {
    background-color: #fd4056;
    color: #FFF;
    border-radius: 10px;
    font-size: 11px;
    padding: 2px 8px 4px;
    position: absolute;
    right: 10px;
    margin-top: 3px;
  }

  .oval-number {
    margin-top: 3px;
    float: right;
    margin-right: 20px;
    text-align: center;
    padding: 2px 12px 3px;
    border-radius: 10.5px;
    background-color: #2d3640;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;

    &:hover {
      color: #FFF !important;
    }
  }

  /* GENERAL FINISHED*/


  .h1_2 {

    font-family: "sofia-pro";
    font-size: 28px;
    font-weight: 500;
    color: #4b4f52 !important;
  }


  h1 {
    font-weight: 400;
    font-size: 44px;
    color: #5e6b79;
  }

  h2 {
    font-weight: 400;
    font-size: 34px;
    color: #5e6b79;
  }


  h3 {
    font-weight: 400;
    font-size: 24px;
    color: #5e6b79;
  }

  h4 {
    font-size: 20px;
    color: #5e6b79;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    color: #000000;
  }

  a:hover {
    text-decoration: underline;
    color: #000000;
  }


.blogContentMobile ul {
  list-style-type: inherit!important;
  padding-left: 32px;
  li{
    margin-bottom: 12px;
  }
}

.blogContentMobile ol {

  padding-left: 32px;
  li{
    margin-bottom: 14px;
  }
}

.blogContentMobile h2 {
  font-weight: 600;
  font-size: 21px !important;
  color: #2d3640 !important;
  margin-bottom: 5px;
  text-align: left;

  line-height: 27px;
}

.blogContentMobile h3 {
  font-weight: 500;
  font-size: 18px !important;
  color: #2d3640 !important;
  opacity: 0.9;
  line-height: 25px;


}

.blogContentMobile a {
  font-weight: 800;
  color: #00a575;
  text-decoration: underline;
}

.blogContentMobile strong {
  font-weight: 600;
  color: #2D3640;
}

  .blogContent h2 {
    font-weight: 600;
    color: #2d3640 !important;
    margin-bottom: 5px;
    text-align: left;
    font-size: 26px!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

  }

  .blogContent h3 {
    opacity: 1;
    font-weight: 600;
    color: #2d3640 !important;
    margin-bottom: 5px;
    text-align: left;
    font-size: 22px!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }


  .blogContent h1 {
    font-weight: 600;
    font-size: 32px !important;
    color: #2d3640 !important;
    margin-bottom: 5px;
    text-align: center;

  }

  .blogInside strong {
    font-weight: 600;
    color: #2D3640;
  }

.blogInside a {
  font-weight: 800;
  color: #00a575;
  text-decoration: underline;
}


.blogInside ul {
  list-style-type: inherit!important;
  padding-left: 60px;
  li{
    margin-bottom: 12px;
  }
}

.blogInside ol {

  padding-left: 60px;
  li{
    margin-bottom: 12px;
  }
}

  .ticketDescription a {
    color: #fd4056;
  }


  .blogContent img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100% !important;
  }

.blogContentMobile img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100% !important;
}

  .black {
    color: #2D3640
  }

  .black:hover {
    text-decoration: underline;
  }


  .page-header {
    margin: 40px 30px 20px;
    font-size: 24px;
    font-weight: 600;
    color: #4b4f52;
  }


  .avatar_image {
    object-fit: cover;
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }


  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #fd4056; /* Blue */
    border-radius: 50%;
    animation: spin 0.7s linear infinite;
    width: 60px;
    height: 60px;
    margin: 0 auto;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  progress {
    border-radius: 2px;
    width: 100px;
    height: 6px;
    margin-left: 30px;
    margin-top: 15px;
  }

  progress::-webkit-progress-bar {
    background-color: #DDE3EB;
    border-radius: 2px;
  }

  progress::-webkit-progress-value {
    border-radius: 2px;

    background-color: #2d3640;
  }

  progress::-moz-progress-bar {
    /* style rules */
  }


  .super-drop-c {
    font-family: 'sofia-pro';
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    font-size:18px;
    width: 393px;background-position-x: 353px; height: 79px;
    border-radius: 5px;
    -moz-appearance: none;
    min-width: 250px;
    appearance: none;
    background-color: #ffffff;

    color: #8b95a1;

    border: solid 1px #bfc8d2;

    padding: 9px 25px 11px;
  }

  .super-drop-c:hover {

    border: solid 1px #00a575;
    color: #2d3640;
    cursor: pointer;
  }



  .super-drop {
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 225px;
    -moz-appearance: none;
    min-width: 250px;
    appearance: none;
    border-radius: 2px;
    font-family: 'sofia-pro';

    background-color: #fefdfd;
    color: #5e6b79;

    border: solid 1px #bfc8d2;

    font-size: 14px;
    padding: 9px 10px 11px;
  }

  .super-drop:hover {

    border: solid 1px #2d3640;
    color: #2d3640;
    cursor: pointer;
  }


  .super-drop.small {
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 210px;
    -moz-appearance: none;
    min-width: 250px;
    appearance: none;
    border-radius: 2px;
    padding: 13px;
    background-color: #fefdfd;
    color: #5e6b79;
    border: solid 1px #bfc8d2;
    font-family: "sofia-pro";
    font-size: 16px;
    line-height: 18px;
    height: 44px;
  }


  .super-action-button-blue {
    width: 160px;
    height: 44px;
    margin-top: 12px;
    border-radius: 2px;
    border: none;
    background-color: #2d3640;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
  }


  .tag_small {
    height: 24px;
    padding: 5px 18px;
    border-radius: 2px;
    background-color: #eaedf2;
  }


  .circularavatar {
    border-radius: 50% !important;
    border: 1px solid #8b95a1;
  }


  .t_follow_button {
    width: 100px;
    height: 40px;
    border-radius: 100px;
    background-color: #2d3640;
    border: none;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
  }


  .t_unfollow_button {
    width: 100px;
    height: 40px;
    border-radius: 100px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border-color: #2d3640;
    color: #2d3640;
    background: transparent none !important;
  }


  .unfollow-button {
    cursor: pointer;
    text-shadow: none !important;
    background: transparent none !important;
    font-size: 12px;
    line-height: 16px;
    border-color: #2d3640;
    color: #2d3640;
    min-width: 89px;
    border-radius: 100px;
    border: 1px solid;
    padding: .5em 0.7em .6em;
    font-weight: 500;
  }


  .follow-button:hover {
    text-shadow: none !important;

    font-size: 12px;
    line-height: 16px;

    border-color: #2d3640;
    color: #2d3640;
    min-width: 89px;
    border-radius: 100px;
    background-color: rgba(101, 196, 219, 0.2) !important;

  }


  .follow-button {
    cursor: pointer;
    text-shadow: none !important;
    background: #2d3640 !important;
    font-size: 12px;
    line-height: 16px;
    border-color: #2d3640;
    color: white;
    min-width: 89px;
    border-radius: 100px;
    border: 1px solid;
    padding: .5em 0.7em .6em;
    font-weight: 500;
  }


  .unfollow-button:hover {
    text-shadow: none !important;

    font-size: 12px;
    line-height: 16px;

    border-color: #2d3640;
    color: #D03548;
    min-width: 89px;
    border-radius: 100px;
    background-color: rgba(101, 196, 219, 0.2) !important;

  }


  .basic-button {
    background: transparent none !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-weight: normal;
    border-radius: 0.28571429rem;
    text-transform: none;
    text-shadow: none !important;
    box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
    transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
    border: none;
    font-size: 14px;

    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em 1em;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
  }


  .basic-button:hover {
    background: transparent none !important;
    color: rgba(0, 0, 0, 0.70) !important;
    font-weight: normal;
    border-radius: 0.28571429rem;
    text-transform: none;
    text-shadow: none !important;
    box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.45) inset;
  }


  .basic-button-blue {
    background: transparent !important;
    color: rgba(101, 196, 219, 1) !important;
    font-weight: normal;
    border-radius: 0.28571429rem;
    text-transform: none;
    text-shadow: none !important;
    box-shadow: 0px 0px 0px 1px rgba(101, 196, 219, 0.8) inset;
    transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
    border: none;
    font-size: 14px;

    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    margin: 0 .25em 0 0;
    padding: .78571429em 1.5em 1em;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
  }


  .basic-button-blue:hover {
    background: transparent !important;
    color: rgba(101, 196, 219, 1) !important;
    font-weight: normal;
    border-radius: 0.28571429rem;
    text-transform: none;
    text-shadow: none !important;
    box-shadow: 0px 0px 0px 1px rgba(101, 196, 219, 1) inset;
  }


  .super-label {
    font-size: 11px;
    max-width: 70px;
    padding: 1px 0px 3px;
    border-radius: 3px;
    text-align: center;
    margin-bottom: 5px;
    border: 1px solid;
  }

  .super-label.yellow {
    background-color: #f2b46d !important;
    border-color: #f2b46d !important;
    color: #fff !important;
  }

  .super-label.green {

    background-color: #21ba45 !important;
    border-color: #21ba45 !important;
    color: #fff !important;
  }


  .super-label.gray {

    background-color: #767676 !important;
    border-color: #767676 !important;
    color: #fff !important;
  }

  .super-label.red {

    background-color: #d0011b !important;
    border-color: #d0011b !important;
    color: #fff !important;
  }

  .super-label.lightblue {

    background-color: #2d3640 !important;
    border-color: #2d3640 !important;
    color: #fff !important;
  }


  .super-text {
    margin-bottom: 1px;
    font-weight: 600;
    font-size: 11px;
    text-align: left
  }

  .super-text.yellow {

    color: #f2b46d !important;
  }

  .super-text.green {


    color: #21ba45 !important;
  }


  .super-text.gray {


    color: #767676 !important;
  }

  .super-text.red {


    color: #d0011b !important;
  }

  .super-text.lightblue {


    color: #2d3640 !important;
  }


  .delete_text {

    color: red;
    cursor: pointer;
  }

  .delete_text:hover {

    color: red;
    text-decoration: underline;
  }


  .nounderlinehover:hover {
    text-decoration: none !important;
  }


  .skilltagbig {
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    display: inline-block;
    position: relative;
    min-width: 40px;
    text-align: center;
    padding: 5px 12px 7px;
    margin: 0 10px 10px 0;
    font-size: 14px;
  }


  .currencygig {
    font-weigth: 500;
    font-size: 14px;
    text-indent: 2px;
  }


  .cancel-text {
    font-size: 14px;
    padding-left: 20px;
    cursor: pointer;
  }

  .cancel-text:hover {
    font-size: 14px;
    padding-left: 20px;
    text-decoration: underline;
  }


  .nohover:hover {
    text-decoration: none !important;
  }

  .hoveropa:hover {
    opacity: .8;
  }


  .iconshadow {
    background-color: #f4f5f7;
    border-radius: 5px;
    padding: 3px 8px;
  }


  /* home


   */


  .homeHeaderLink {
    font-size: 20px;
    font-weight: 300 !important;
    color: #4b4f52;
    cursor: pointer;
  }

  .homeHeaderLink:hover {
    font-size: 20px;
    font-weight: 300 !important;
    color: #2d3640;
    cursor: pointer;
    text-decoration: none !important;
  }


  .cuper-drop-slim{

    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;

    height: 60px;
    -moz-appearance: none;
    width: 330px;
    background-position-x: 305px;
    appearance: none;
    font-family: 'sofia-pro';
    position: relative;
    background-color: #fff;


    border-radius: 5px;
    border: solid 1px #bfc8d2;


    color:#8b95a1;
    font-size: 18px;
    padding: 4px 5px 6px 25px;
  }


  .super-drop-tek-cizgi {
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 150px;
    -moz-appearance: none;
    min-width: 170px;
    appearance: none;
    border-radius: 2px;
    font-family: 'sofia-pro';

    position: absolute;;


    background-color: #fefdfd;
    color: #2d3640;

    border: none;
    border-bottom: solid 1px #bfc8d2;

    font-size: 14px;
    padding: 4px 5px 6px;
    padding-left: 10px;
  }

  .super-drop-tek-cizgi:hover {

    border: none;
    border-bottom: solid 1px #2d3640;
    color: #2d3640;
    cursor: pointer;
  }


  .cat_label {
    font-weight: 300 !important;
    font-size: 13.5px;
    color: #8b95a1;
  }


  .what-you-need {
    opacity: .9;

    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4f52;
  }

  .what-you-need .text-style-1 {
    font-weight: 600;
  }

  .light-button {
    width: 160px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    background: transparent;
    color: #2d3640 !important;
    font-size: 18px;
    font-weight: 500;
    padding: 9px 10px 11px;

  }

  .light-button:hover {


    border: solid 1px #37414b;
    background: white !important;
    color: #37414b !important;

  }


  .light-button-small {
    width: 128px;
    height: 38px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    background: transparent;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #2d3640;
  }

  .light-button-small:hover {

    border-radius: 2px;
    border: solid 1px #37414b;
    background: white !important;
    color: #37414b !important;
  }

  .mainH2 {
    font-size: 32px;
    font-weight: 600;
    color: #4b4f52;
  }


  .i-can-design-logo-wi {
    width: 274px;
    height: 64px;

    font-size: 27px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4f52;
  }

  .i-can-design-logo-wi .text-style-1 {
    font-weight: bold;
    color: #2d3640;
    text-decoration: underline;
  }


  .mask {
    width: 428px;
    height: 225px;
    background-color: #ffffff;
    box-shadow: 0 15px 23px 0 rgba(0, 0, 0, 0.2);

  }


  .hover-zoom {
    float: left;
    margin: 0;
    height: 180px;
    width: 300px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-size: 110%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: gray;
    position: relative;
  }

  .hover-zoom:hover {
    background-size: 100%;
  }


  .mobileArea {

    padding-top: 56px;

  }

  .mobileAvatar {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #bfc8d2;
  }

  .mobileLikeIcon {
    height: 30px;
    align-content: center;
    align-items: center;
    align-self: center;
  }

  .mobileUsername {
    height: 30px;
    align-content: center;
    align-items: center;
    align-self: center;
  }


  .mobileNavBar {
    z-index: 99999;
    color: #2d3640;
    font-weight: 600;
    font-size: 17px;
    height: 44px;
    top: 0;
    margin-top: 0;
    padding: 0;
    position: fixed;
    width: 100%;
    display: flex;
    align-self: center;
    align-content: center;
    align-items: center;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0 5px 10px 0;
  }

  .mobileNavBar.android {

    height: 56px;

    background-color: #f5f6f8;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;

    color: #2d3640;
    font-weight: 500;
    font-size: 18px;

  }


  .mobileNavBar .goBack {
    flex: 1;
    height: 22px;
  }

  .mobileNavBar .goBack .backText {
    height: 22px;
    position: absolute;
    margin-top: -1px;
    line-height: 22px;
    color: #2d3640;
    font-size: 17px;
    margin-left: 30px;
    font-weight: 400;
    letter-spacing: -0.41px !important;
    line-height: 22px;
  }

  .mobileNavBar.android .goBack {
    flex: 1;
    height: 18px;
  }

  .mobileNavBar .title {
    flex: 220;
    text-align: center;
  }

  .mobileNavBar.android .title {
    flex: 20;
    text-align: left;
    padding-left: 32px;
    line-height: 24px;
    font-size: 20px;
    font-weight: 500;
  }

  .mobileNavBar .options {
    display: flex;
    height: 22px;
  }

  .mobileNavBar.android .options {
    flex: 1;
    height: 18px;
    display: flex;
  }


  .mobileNavBarBottom {
    padding-top: 48px;
  }

  .mobileNavBarBottom.android {
  }


  .appBar {

    height: 56px;
    background-color: #f5f6f8;
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
    border: none;
    position: fixed;
    width: 100%;
    z-index: 9;
    display: flex;
    align-content: center;
    align-items: center;
    align-self: center;
    width: 100%;


  }

  .appBar.ios {
    height: 48px;
    text-align: center;
    background-color: #f5f6f8;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
    position: fixed;
    width: 100%;
    z-index: 9;
    display: flex;
    align-content: center;
    align-items: center;
    align-self: center;
    width: 100%;

  }

  .appBar .appBarLogoText {
    width: 100%;
    color: #fff !important;

    font-size: 24px;
    display: flex;
    height: 56px;
    align-self: center;
    align-content: center;
    align-items: center
  }

  .mobileContent {
    width: 100%;
    background-color: #fff
  }


  .appBar.android .appBarLogoText {

    color: #fff !important;
    width: 100%;
    text-align: center;
    font-size: 24px;
    display: inline-block;
    height: 32px
  }


  .appBar.ios .appBarLogoText {
    color: #2d3640 !important;
    width: 100%;
    text-align: center;
    font-size: 24px;
    display: flex;
    display: inline-block;
    height: 32px

  }

  .underAppBar {
    padding-top: 56px;
  }

  .underAppBar.ios {
    padding-top: 48px;
  }


  .super-button-grays {

    border-radius: 2px;
    background-color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    color: #5e6b79;

    border: solid 1px #bfc8d2;
    padding: 4px 2px 6px !important;

  }


  .super-button-grays:hover {

    border-radius: 2px;
    background-color: #ffffff !important;
    font-size: 12px;
    font-weight: 400;
    color: #fd4056;
    border: solid 1px #8b95a1;

  }

  .super-button-light-small {


    border-radius: 2px;
    border: none;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    color: #2d3640;
    height: 36px;
    border: solid 1px #2d3640;
    padding: 6px 16px 13px;
  }


  .super-button-light-small:hover {


    border-radius: 2px;
    border: none;
    background-color: #ffffff !important;
    font-size: 14px;
    font-weight: 500;
    color: #37414b;
    height: 36px;
    border: solid 1px #37414b;
    padding: 6px 16px 13px;
  }

  /*tokBox*/
  .OT_widget-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .OT_video-poster {
    border-radius: 50%;
    opacity: 1;
    background-size: cover !important;
    height: 75px;
    width: 75px;
    display: block;
  }

  .OT_mute {
    background-color: red;
  }

  /* chat */
  .chatbubble.right {
    word-break: break-word;
  }

  .chatbubble.left {
    word-break: break-word;
  }

  .ql-align-center {
    width: 100%;
    text-align: center;
  }

  /*carusel css*/
  .VueCarousel-dot .VueCarousel-dot-inner {
    background: #bfc8d2 !important;
  }

  .VueCarousel-dot--active .VueCarousel-dot-inner {
    background: #2d3640 !important;
  }

  .VueCarousel-navigation-next::after {
    background-size: 42px 31px !important;
    height: 31px !important;
    width: 42px !important;
  }

  .VueCarousel-navigation-prev::after {
    background-size: 42px 31px !important;
    height: 31px !important;
    width: 42px !important;
  }


  /*crop css*/
  .cropper-container {
    //width: 650px !important;
    //height: 400px !important;
  }


  //butonlar v2 aug 2018 utku edit
  .blackButtonAction {
    background-color: #2d3640;
    width: 170px;
    height: 50px;
    border-radius: 2px;
  }

  .pinkButtonAction {
    background-color: #242424;

  }

  /*toasted css*/

.toasted {
  padding: 10px 30px 13px!important;
  border-radius: 5px!important;
}
  .toasted-info {
    background-color: #00a575 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #fff !important;
    border: none !important;
  }

  .toasted-error {
    background-color: #fd4056 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #fff !important;
    border: none !important;


  }

  .toasted-container{
    z-index: 9100001!important;
  }


  .c-text-area{
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2);
    background-color: #ffffff;

    font-size: 16px;
    font-weight: 500;

    line-height: 1.88;
    color: #2d3640;
    padding: 23px 25px;

    &:focus{
      border: solid 1px #00a575;
    }
  }





  .progressbar-purple{
    width: 115px;
    height: 4px;
    border-radius: 7px;

  }

  .progressbar-purple::-webkit-progress-bar {
    border-radius: 7px;
    background-color: #bfc8d2!important;
  }

  .progressbar-purple::-webkit-progress-value {
    border-radius: 7px;

    background-image: linear-gradient(88deg, #71d9cf 6%, #54aeb8 97%)!important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }

  .progressbar-purple::-moz-progress-bar {
    /* style rules */
  }





  .start-upload-text{
    cursor: pointer;
    color: #8b95a1; font-size: 14px; margin-left: 5px;
    &:hover{
      text-decoration: underline;
      color: #2d3640;
    }
  }

  .container-emoji{
    height: 200px!important;
  }
  #EmojiPicker{
    width: 300px!important;
  }
  #emojiCategories{
    overflow-x: hidden!important;
  }

</style>
