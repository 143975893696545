import TEXTS from './texts'
export default {
  TEXTS,
  CDN_SITE : "https://gcdn.bionluk.com/site/",
  CDN_UPLOADS_G: "https://gcdn.bionluk.com/uploads/",
  UPLOAD_TYPES: {
    AVATAR: 'avatar',
    REQUEST: 'request',
    MESSAGE: 'message',
    PORTFOLIO: 'portfolio',
    ORDER: 'order',
    TICKET: 'ticket',
    APPLICATION: 'application',
    STORY: 'story',
    BLOG: 'blog'
  },
  UPLOAD_STATUSES: {
    DELETE: -1
  },
  CUSTOM_OFFER_TYPES: {
    REQUEST: "request",
    OFFER: "offer"
  },
  ORDER_TYPES: {
    OFFER: "offer",
    GIG: "gig",
    BUYING: "buying",
    SELLING: "selling"
  },
  MESSAGE_TYPES: {
    OFFER: "offer",
    UPLOAD: "upload"
  },
  PORTFOLIO_STATUSES: {
    ACTIVE: 1,
    DENIED: -1,
    INACTIVE: 0
  },
  PAYMENT_TYPES: {
    CREDIT_CARD: "cc",
    CREDIT: "credit"
  },
  FAV_TYPES: {
    GIG: "gig",
    PORTFOLIO: "portfolio"
  },
  FAV_STATUS: {
    DELETE: -1
  }
}
