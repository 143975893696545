import store from '../store';
import * as Types from '../store/mutation-types';
import Api from '../api';

// import LogRocket from 'logrocket';


import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";



const sentryInit = function (Vue, router) {
  Sentry.init({
    Vue,
    dsn: 'https://c2a087022e184c1eb9cd4481bc6521d0@sentry.io/264543',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["bionluk.com", /^\//],
        attachProps: true,
        logErrors: true,
        trackComponents: true
      }),
    ],
    tracesSampleRate: 0.01,
    release: 'bionluk@3.2.2'
  });
}

const sentryConfig = function (type, props) {
  if(type === 'setUser' && props && props.user_id){
    Sentry.configureScope(function(scope) {
      scope.setUser({
        id: props.user_id
      });
    });
  }
}



const reject = function (array, object) {
  return array.filter(function (element) {
    let rejected = false;
    for (let key in object) {
      rejected = element[key] !== object[key]
    }
    return rejected;
  });
};

const generateUUID = function () {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

const setInitialValuesBeforeInit = function (router) {
  store.commit(Types.SET_COMPONENT_MAP, {body: "loading/v1", footer: '', header: ''});
};

const arrangeVisitorInfo = function (received, router, fullPath, from) {

  return {

    id: received.visitor_id ? received.visitor_id : window.localStorage.getItem("visitor_id"),
    device: received.data.visitorInfo.device,
    platform: received.data.visitorInfo.platform,
    deviceFamily: received.data.visitorInfo.deviceFamily,
    ip: received.data.visitorInfo.ip,
    language: 'tr_TR',
    deviceToken: store.state.deviceToken ? store.state.deviceToken : undefined, // app will send
    initial_referrer: sessionStorage.getItem('referrer'),
    referrer: from.fullPath,
    utm_campaign: router.query.utm_campaign,
    utm_medium: router.query.utm_medium,
    utm_source: router.query.utm_source,
    utm_content: router.query.utm_content,
    utm_term: router.query.utm_term,
    userAgent: navigator.userAgent,
    createdAt: new Date(),
    currentURL: fullPath,
    payloadType: 'visitor'
  }
};

const setInitialValuesAfterInit = function (received, router, from) {
  store.commit(Types.SET_MODAL_COMPONENT_MAP, received.data.modalComponents);
  store.commit(Types.SET_MESSAGE_COMPONENT, received.data.messageComponent);
  store.state.routerMap = received.data.routerMap;
  store.state.prefixPath = received.data.prefix;
  store.state.footer_links = received.data.footer_links;
  store.state.footerLinks = received.data.footerLinks;
  store.state.units = received.data.units;
  // store.state.categories = received.data.categories;
  store.state.newVersion = received.data.version;
  store.state.imageUrls = received.data.imageUrls;
  store.state.isMobile = received.data.isMobile;
  store.state.deviceType = received.data.deviceType;
  store.state.visitorInfo = arrangeVisitorInfo(received, router, window.location.origin + router.fullPath, from);
  store.state.request_durations = received.data.request_durations;
  store.state.request_budgets = received.data.request_budgets;


  //canonical tag
  if (received.data.canonical) {
    let canonical = received.data.canonical;
    //change it if exists
    if (!!document.querySelector('link[rel="canonical"]')) {
      document.querySelector('link[rel="canonical"]').href = canonical;
    } else {
      //create and save
      let ele = document.createElement('link');
      ele.setAttribute('rel','canonical');
      ele.setAttribute('href',canonical)
      document.getElementsByTagName('head')[0].appendChild(ele);
    }
  } else {
    //remove it
    let element = document.querySelector('link[rel="canonical"]');
    if(element){
      element.parentNode.removeChild(element);
    }
  }


  if (received.data.user) {
    store.commit(Types.SET_USER, received.data.user);
    store.commit(Types.SET_USER_PUSHER_INFO, received.data.user);

    if(received.data.user.lastSeenItems){
      store.state.lastSeenItems = received.data.user.lastSeenItems;
    }

    if(received.data && received.data.user && received.data.user.slog){
      store.state.slog = true;
    }

  }

};

const createHireBestFreelancersSearchPageProps = function (categoryName, minPrice, maxPrice, deliveryTime, count) {
  // null olarak gittiğinde mongoya null olarak kayıt oluyor.
  if (!deliveryTime) {
    deliveryTime = undefined;
  }
  return {categoryName, minPrice, maxPrice, deliveryTime, count}
};

const createUserPageProps = function (user) {
  return {
    username: user.username,
    userID: user.user_id,
    userUUID: user.uuid,
  }
};

const createProjectShowcaseDetailPageProps = function (portfolio) {
  return {
    username: portfolio.user.username,
    userID: portfolio.user.user_id,
    title: portfolio.name,
    uuid: portfolio.uuid,
    portfolio_id: portfolio.id,
    totalLikes: portfolio.fav_count,
    totalComments: portfolio.comment_count,
    categoryName: portfolio.category_name,
    categorySlug: portfolio.category_slug,
    subCategoryName: portfolio.category_sub_name,
    subCategorySlug: portfolio.category_sub_slug,
    categoryID: portfolio.category_id,
    subCategoryID: portfolio.category_sub_id
  }
};

const projectShowcaseAttachmentPageProps = function (portfolio) {
  return {
    username: portfolio.user.username,
    userUUID: portfolio.user.uuid,
    userID: portfolio.user.user_id,
    title: portfolio.name,
    portfolio_id: portfolio.id,
    uuid: portfolio.uuid,
    totalLikes: portfolio.fav_count,
    totalComments: portfolio.comment_count,
    categoryName: portfolio.category_name,
    categorySlug: portfolio.category_slug,
    subCategoryName: portfolio.category_sub_name,
    subCategorySlug: portfolio.category_sub_slug,
    categoryID: portfolio.category_id,
    subCategoryID: portfolio.category_sub_id,
    whereFrom:store.state.componentMap.page

  }
};

const projectShowcaseAttachmentPagePropsModal = function (portfolio, gigId=null) {
  return {
    username: portfolio.user.username,
    userUUID: portfolio.user.uuid,
    userID: portfolio.user.user_id,
    title: portfolio.name,
    portfolio_id: portfolio.id,
    uuid: portfolio.uuid,
    totalLikes: portfolio.fav_count,
    totalComments: portfolio.comment_count,
    categoryName: portfolio.category_name,
    categorySlug: portfolio.category_slug,
    subCategoryName: portfolio.category_sub_name,
    subCategorySlug: portfolio.category_sub_slug,
    categoryID: portfolio.category_id,
    subCategoryID: portfolio.category_sub_id,
    whereFrom: store.state.componentMap.page,
    gig_id: gigId || null

  }
};

const blogDetailPageProps = function (blogId, slug) {
  return {

    blogSlug: slug,
    blogID: blogId,

  }
};

const blogDetailModalProps = function (blogTopic) {
  return {
    blogSlug: blogTopic.slug,
    blogID: blogTopic.blog_id,
    whereFrom: store.state.componentMap.page
  }
};

const supportTopicPageProps = function (supportTopic,slug) {
  return {
    title: supportTopic.name,
    slug: slug,
    categoryName: supportTopic.support_category_name,
    categorySlug: supportTopic.support_category_slug,
    subCategoryName: supportTopic.support_sub_category_name,
    subCategorySlug: supportTopic.support_sub_category_slug
  }
};

const supportCategoryPageProps = function (supportCategory) {
  return {
    name: supportCategory.name
  }
};

const staticPageProps = function (page, slug) {
  return {
    title: page.name,
    slug: slug
  }
};

//hangi durumda gönderilmesi gerektiği tartışılacak
const chatPageProps = function (conversationInfo) {

  let userName;
  let userID;
  let userUUID;
  let placement;
  let fromWhere;
  let gigID;
  let blogID;
  let categoryId;
  let whereFrom;


  if (conversationInfo.r_username && conversationInfo.r_uuid) { // alttaki chatbox açılınca gelen response
    userName = conversationInfo.r_username;
    userID = conversationInfo.r_id;
    userUUID = conversationInfo.r_uuid;
    placement = "chatBox"
  }
  if (conversationInfo.username && conversationInfo.uuid) { // headerdaki chat açılınca gelen response
    userName = conversationInfo.username;
    userID = conversationInfo.id;
    userUUID = conversationInfo.uuid;
    placement = "chatDetail"
  }



  if(conversationInfo.fromWhere && conversationInfo.fromWhere === 'Buyer Requests'){
    placement = "chatBox";
  }

  fromWhere = conversationInfo.fromWhere;
  whereFrom = conversationInfo.whereFrom;
  gigID = conversationInfo.gigID;
  blogID = conversationInfo.blogID;
  categoryId = conversationInfo.categoryID;

  if(conversationInfo.fromWhere && conversationInfo.fromWhere === 'Bia'){
    return {
      userName,
      userID,
      userUUID,
      placement,
      fromWhere,
      whereFrom,
      cat_id:conversationInfo.cat_id,
      sub_cat_id:conversationInfo.sub_cat_id,
      selectedTypes:conversationInfo.selectedTypes
    }
  } else if(gigID && userName && userID){
    return {
      userName,
      userID,
      userUUID,
      placement,
      fromWhere,
      whereFrom,
      gigID
    }

  } else if(blogID && userName && userID){
    return {
      userName,
      userID,
      userUUID,
      placement,
      fromWhere,
      whereFrom,
      blogID
    }

  } else if(categoryId && userName && userID){
    return {
      userName,
      userID,
      userUUID,
      placement,
      fromWhere,
      whereFrom,
      categoryId
    }

  } else if(userName && userID){
    return {
      userName,
      userID,
      userUUID,
      placement,
      fromWhere,
      whereFrom
    }
  }
};

const chatDetailPageProps = function (conversationInfo) {
  return {
    username: conversationInfo.username,
    userID: conversationInfo.uuid,
    placement: "chatDetail"
  }
};

const editGigPageProps = function (gig) {
  return {
    title: gig.title,
    uuid: gig.uuid
  }
};

const gigDetailPageProps = function (gig) {
  return {
    title: gig.title,
    slug: gig.gig_slug_url,
    gigId: gig.uuid,
    categoryId: gig.gig_category.id,
    categoryName: gig.gig_category.name,
    categorySlug: gig.gig_category.slug,
    subCategoryId: gig.gig_category_sub.id,
    subCategoryName: gig.gig_category_sub.name,
    subCategorySlug: gig.gig_category_sub.slug,
    username: gig.seller.username,
    userID: gig.seller.uuid,
    price: gig.price.price,
    duration: gig.duration,
    totalLikes: gig.likeStatus.action,
    totalComments: gig.comments,
    hasVideo: gig.portfolios[0].fileType !== "image",
    totalPortflioProjects: gig.portfolios.length
  }
};

const gigDetailPagePropsV2 = function (gig) {

  return {
    title: gig.title,
    slug: gig.gig_slug_url,
    gigId: gig.gig_id,
    gigUUID: gig.uuid,
    categoryId: gig.gig_category.id,
    categoryName: gig.gig_category.name,
    categorySlug: gig.gig_category.slug,
    subCategoryId: gig.gig_category_sub.id,
    subCategoryName: gig.gig_category_sub.name,
    subCategorySlug: gig.gig_category_sub.slug,
    username: gig.seller.username,
    userID: gig.seller.id,
    userUUID: gig.seller.uuid,
    price: gig.priceInfo.price,
    duration: gig.duration,
    totalLikes: gig.likeInfo.likeStatus.action,
    totalComments: gig.commentInfo.commentCount,
    hasVideo: gig.portfolios[0].fileType !== "image",
    totalPortflioProjects: gig.portfolios.length,
    selectedTypes: gig.service_types,
    selectedKinds: gig.service_kinds,
    isFeatured: gig.isFeatured ? 1 :  -1
  }
};

const orderDetailPageProps = function (order) {
  return {
    orderID: order.order_id,
  }
};

const checkoutPageProps = function (checkOut) {

  if(checkOut.type === 'fast_application'){

    return {

      type: checkOut.type,
      total: checkOut.total,
    }

  } else {

    return {
      orderID: checkOut.order_id,
      type: checkOut.type,
      total: checkOut.total,
      duration: checkOut.duration,
      gigID: checkOut.gig_id,
      gigUUID: checkOut.gig_uuid,
      gigTitle: checkOut.title,
      gigSlug: checkOut.slug,
      categoryId: checkOut.category_id,
      categoryName: checkOut.category_name,
      categorySlug: checkOut.category_slug,
      subCategoryId: checkOut.category_sub_id,
      subCategoryName: checkOut.category_sub_name,
      subCategorySlug: checkOut.category_sub_slug,
      username: checkOut.seller_name,
      userID: checkOut.seller_id,
      userUUID:checkOut.seller_uuid,
      whereFrom:checkOut.whereFrom,
    }
  }

};

const checkoutPageSinglePayProps = function (checkOut) {
  return {
    categoryId: checkOut.categoryId,
    categoryName: checkOut.categoryName,
    categorySlug: checkOut.categorySlug,
    subCategoryId: checkOut.subCategoryId,
    subCategoryName: checkOut.subCategoryName,
    subCategorySlug : checkOut.subCategorySlug,
    userID: checkOut.userID,
    username: checkOut.userName,
    type: checkOut.type,
    total: checkOut.total
  }
};

const categoryListPageProps = function (category, totalCount) {
  let props = {};


  if (!category.parent_id) {
    props.categoryId = category.id;
    props.categoryName = category.name;
    props.categorySlug = category.slug;
    props.parentCategoryId = null;
    props.parentCategoryName = null;
    props.parentCategorySlug = null;
  } else {
    let foundParentCategory = store.state.categories.find(({ id }) => id === category.parent_id)
    props.categoryId = category.id;
    props.categoryName = category.name;
    props.categorySlug = category.slug;
    props.parentCategoryId = foundParentCategory.id;
    props.parentCategoryName = foundParentCategory.name;
    props.parentCategorySlug = foundParentCategory.slug;
    props.selectedKinds = category.selectedKinds || null;
    props.selectedTypes = category.selectedTypes ?  parseInt(category.selectedTypes) : null; //şimdilik type hep tek gidiyor

  }

  props.totalGigs = totalCount;
  return props;

};

const ticketDetailPageProps = function (ticket, slug) {
  return {
    ticketId: ticket.ticket.uuid,
    id:ticket.ticket.id,
    ticketSlug: slug,
    type: ticket.ticket?.showRateUsBox ? 'with_rate_box' : ''
  }
};

const searchPageProps = function (totalCount, query, foundCategory={}) {
  for (let a in query) {
    query[a] = (query[a]  !== 'undefined' && query[a]  !== "null") ? query[a]  : null;
  }

  foundCategory = {...foundCategory, ...query, totalGigs: totalCount};
  return foundCategory;

};




const userSearchPageProps = function (totalCount, query) {
  for (let a in query) {
    query[a] = (query[a]  !== 'undefined' && query[a]  !== "null") ? query[a]  : null;
  }
  let obj = {...query, totalGigs: totalCount};
  return obj
};
const sendMessageProps = function (placement, receiver) {
  if(receiver && !receiver.fromWhere){
    receiver.fromWhere = 'Chat Detail'
  }
  if(receiver.gigID && receiver.gigID !== 'undefined'){
    return {
      placement: placement,
      userID: receiver.id,
      userUUID: receiver.uuid,
      username: receiver.username,
      fromWhere: receiver.fromWhere,
      whereFrom: receiver.whereFrom,
      clickedFrom: receiver.clickedFrom,
      gigID:receiver.gigID
    }
  } if(receiver.blogID && receiver.blogID !== 'undefined'){
    return {
      placement: placement,
      userID: receiver.id,
      userUUID: receiver.uuid,
      username: receiver.username,
      fromWhere: receiver.fromWhere,
      whereFrom: receiver.whereFrom,
      blogID:receiver.blogID,
      clickedFrom: receiver.clickedFrom,
    }
  } if(receiver.request_id && receiver.request_id !== 'undefined'){
    return {
      placement: placement,
      userID: receiver.id,
      userUUID: receiver.uuid,
      username: receiver.username,
      fromWhere: receiver.fromWhere,
      whereFrom: receiver.whereFrom,
      request_id:receiver.request_id,
      request_uuid:receiver.request_uuid,
      clickedFrom: receiver.clickedFrom,
    }
  } else {
    return {
      cat_id: receiver.cat_id || null,
      sub_cat_id: receiver.sub_cat_id || null,
      selectedTypes: receiver.selectedTypes || null,
      placement: placement,
      userID: receiver.id,
      userUUID: receiver.uuid,
      username: receiver.username,
      fromWhere: receiver.fromWhere,
      whereFrom: receiver.whereFrom,
      clickedFrom: receiver.clickedFrom,
    }
  }
};

const portfolioOpennedProps = function (username, userID, userUUID, gig_id = '') {
  return {
    username: username,
    userID: userID,
    userUUID: userUUID,
    gig_id
  }
};

const goLandingPageProps = function (id,slug, title ) {
  return {
    id,
    slug,
    title
  }
};



const biaListPageProps = function (trackInfo) {
  return {
    categoryId:trackInfo.categoryId,
    categoryName:trackInfo.categoryName,
    categorySlug:trackInfo.categorySlug,
    parentCategoryId:trackInfo.parentCategoryId,
    parentCategorySlug:trackInfo.parentCategorySlug,
    parentCategoryName:trackInfo.parentCategoryName
  }
};


const arrangePageViewToSaveAsRawEvent = function (page, version, props) {

      let temp = store.state.visitorInfo ? JSON.parse(JSON.stringify(store.state.visitorInfo)) : {};

      if(page === 'today' && version === 'v3' && store.state.visitorInfo && store.state.visitorInfo.device === 'desktop' && store.state.user && store.state.user.uuid &&  store.state.user.avatar_url === 'https://gcdn.bionluk.com/uploads/general/No_Avatar.png'){
        props = {title:'with_no_avatar'}
      }

      // store && store.state && store.state.route && store.state.route.from && store.state.route.from.fullPath ?

      let event_referrer = store.state.route.from && store.state.route.from.fullPath ? store.state.route.from.fullPath : '/';
      if(sessionStorage.getItem('temp_referrer')){
        event_referrer = sessionStorage.getItem('temp_referrer');
        sessionStorage.removeItem('temp_referrer');
      }

      temp.payloadType = 'event';
      temp.page = page;
      temp.pageVersion = version;
      temp.event = "pageView";
      temp.props = props;
      temp.userID = store.state.user.id;
      temp.userUUID = store.state.user.uuid;
      temp.username = store.state.user.username;
      temp.currentURL = window.location.origin + store.state.route.fullPath;
      temp.referrer = event_referrer;
      return temp;


};

const arrangeOpenedModalToSaveAsRawEvent = function (type, version, props=null) {
  let temp = JSON.parse(JSON.stringify(store.state.visitorInfo));

  temp.createdAt = new Date();
  temp.payloadType = 'event';
  temp.page = type;
  temp.pageVersion = version;
  temp.event = "openedModal";
  temp.userID = store.state.user.id;
  temp.userUUID = store.state.user.uuid;
  temp.username = store.state.user.username;
  temp.currentURL = window.location.origin + store.state.route.fullPath;
  temp.referrer = store.state.route.from.fullPath;

  if(props){
    temp.props = props;
  } else {
    temp.props = {};
  }

  if(!temp.props.whereFrom){
    if(store.state.componentMap){
      temp.props.whereFrom = store.state.componentMap.page;
    } else {
      temp.props.whereFrom = null;;
    }
  }

  if(store.state.clickedFrom){
    let clickFrom = store.state.clickedFrom;
    temp.props.clickedFrom = clickFrom;
    store.state.clickedFrom = null;
  } else {
    temp.props.clickedFrom = null;
  }
  return temp;
};


const arrangeSendMessageToSaveAsRawEvent = function (props) {
  let temp = JSON.parse(JSON.stringify(store.state.visitorInfo));
  temp.createdAt = new Date();
  temp.payloadType = 'event';
  temp.event = "sendMessage";
  temp.props = props;
  temp.userID = store.state.user.id;
  temp.userUUID = store.state.user.uuid;
  temp.username = store.state.user.username;
  temp.currentURL = window.location.origin + store.state.route.fullPath;
  temp.referrer =  store.state.route.from && store.state.route.from.fullPath ? store.state.route.from.fullPath : '/';
  return temp;
};


const arrangeCustomEventToSaveAsRawEvent = function (event, props, forceMerge = null, page=null) {
  let temp = JSON.parse(JSON.stringify(store.state.visitorInfo));

  temp.payloadType = 'event';
  temp.event = event;
  if(page){
    temp.page = page;
  }
  temp.props = props;
  temp.userID = store.state.user.id;
  temp.userUUID = store.state.user.uuid;
  temp.currentURL = window.location.origin + store.state.route.fullPath;
  temp.referrer = store.state.route.from && store.state.route.from.fullPath ? store.state.route.from.fullPath : '/';

  if(forceMerge){
    temp.userID = forceMerge.userID;
    temp.userUUID = forceMerge.userUUID;
    temp.username = forceMerge.username;

  }
  return temp;
};



const arrangePageViewToSendToAmplitude = function (page, version, props) {
  let temp = {};
  let visitorInfo = store.state.visitorInfo ? store.state.visitorInfo : {};

  temp.visitor_utm_campaign = visitorInfo.utm_campaign;
  temp.visitor_utm_medium = visitorInfo.utm_medium;
  temp.visitor_utm_source = visitorInfo.utm_source;
  temp.visitor_utm_content = visitorInfo.utm_content;
  temp.visitor_utm_term = visitorInfo.utm_term;
  temp.language = 'tr_TR';
  temp.visitor_deviceFamily = visitorInfo.deviceFamily;
  temp.visitor_platform = visitorInfo.platform;
  temp.visitor_device = visitorInfo.device;
  temp.deviceToken = visitorInfo.deviceToken;
  temp.visitor_referer = store.state.route.from && store.state.route.from.fullPath ? store.state.route.from.fullPath : '/';
  temp.visitor_initial_referrer = visitorInfo.initial_referrer ? visitorInfo.initial_referrer : '';
  temp.userID = store.state.user.id;
  temp.userUUID = store.state.user.uuid;
  temp.username = store.state.user.username;
  if (store.state.user.created_at) {
    temp.visitor_created_at = Math.round(store.state.user.created_at / 1000);
  }
  temp.page = page;
  temp.pageVersion = version;
  temp.currentURL = window.location.origin + store.state.route.fullPath;

  // iki objeyi de birleştirip temp'e kaydeder.
  if (props) {
    Object.assign(temp, props);
  }
  // tempteki undefined ve null değerleri temizlemesi için.
  for (let key in temp) {
    if (temp[key] === null || temp[key] === undefined) {
      delete temp[key];
    }
  }
  return temp;
};

const isIndexerBotOrDevelopmentEnv = function () {
  return /bot|crawl|slurp|spider|HeadlessChrome|Mediapartners|uptime|validator|fetcher|cron|checker|reader|extractor|monitoring|analyzer|prerender/i.test(navigator.userAgent) || store.state.isDevelopmentEnv || store.state.slog;
};

const setUser = function () {

  if (!isIndexerBotOrDevelopmentEnv()) {


    sentryConfig('setUser',{user_id:store.state.user.id})

    ga('set', 'userId', store.state.user.uuid);

    /*
    window.Intercom('boot', {
      app_id: 'grg6cvsq',
      email: store.state.user.email,
      user_id: store.state.user.uuid,
      created_at: Math.round(store.state.user.created_at / 1000)
    });
    */

    amplitude.getInstance().setUserId(store.state.user.id);

    //amplitude.getInstance().setUserProperties(userProperties);


    let defaultValues ={
      username: store.state.user.username,
      email: store.state.user.email,
      isFreelancer: store.state.user.seller.isSeller,
      created_at: Math.round(store.state.user.created_at / 1000),
    };

    let props = store.state.user.info.dynamic_segment_id ? {dynamic_segment_id:store.state.user.info.dynamic_segment_id} : {};
    // const logRocketObj = {...defaultValues, ...props }
    // LogRocket.identify(store.state.user.id, logRocketObj);

  }


};


const recordUserEventsForSegmentation = function (event, props, page=null ) {

  if(event === 'pageView' && page && page === 'deeddetail'){
    if(props.subCategoryId && props.subCategoryId === '61'){
      let initialStoreValues = store.state.userActionsForSegmentation;
      initialStoreValues.push(props.userID)
      let uniqueValues = [...new Set(initialStoreValues)];
      store.state.userActionsForSegmentation = uniqueValues;
    }
  }
}

const pageView = function (props={}, comingPage, comingVersion) {
  if(!props){ props = {} };
  let page = comingPage ? comingPage : store.state.componentMap.page;
  let version = comingVersion ? comingVersion : store.state.componentMap.pageVersion;

  let from = store.state.route.from || {};

  if(!props.whereFrom){
    if(store.state.previousComponentPage){
      props.whereFrom = store.state.previousComponentPage;
    } else {
      if(from && from.fullPath === '/' && !from.params && !from.params.length){
        let r = document.referrer;
        let w = window.localStorage.getItem("whereFrom");
        if(r && w){
          props.whereFrom = w;
        } else {
          props.whereFrom = null;
        }
      } else {
        props.whereFrom = null;
      }
    }
  }

  if(store.state.clickedFrom){
    let clickFrom =  store.state.clickedFrom;
    props.clickedFrom = clickFrom;
    store.state.clickedFrom = null;
  } else {
    if(from && from.fullPath === '/' && !from.params && !from.params.length){
      let r = document.referrer;
      let c = window.localStorage.getItem("clickedFrom");
      if(r && c){
        props.clickedFrom = c;
      } else {
        props.clickedFrom = null;
      }
    } else {
      props.clickedFrom = null;
    }
  }

  if(store.state.clickedProps){
    props = {...props, ...store.state.clickedProps};
    store.state.clickedProps = null;
  }


  window.localStorage.removeItem("clickedFrom");
  window.localStorage.removeItem("whereFrom");


  if (!isIndexerBotOrDevelopmentEnv()) {
    ga('send', {
      hitType: "pageview",
      title: document.title,
      location: window.location.origin + store.state.route.fullPath,
      page: store.state.route.fullPath
    });

    fbq('track', 'PageView');


    recordUserEventsForSegmentation('pageView', props, page);
    Api.external.trackEvents(arrangePageViewToSaveAsRawEvent(page, version, props));

    let propsWithUnderscore = {};
    for (let key in props) {
      propsWithUnderscore[`props_${key}`] = props[key];
    }
    amplitude.getInstance().logEvent(`pageView_${page}`, arrangePageViewToSendToAmplitude(page, version, propsWithUnderscore));
  }
};

const initLogrocketManual = function(props={}) {
  /*
  if (!isIndexerBotOrDevelopmentEnv()) {
    LogRocket.init('bjngqg/bionluk-yoxaf', {
      console: {
        isEnabled: {
          log: true,
          debug: false,
        },
      }
    });

    let defaultValues ={
      username: store.state.user.username,
      email: store.state.user.email,
      isFreelancer: store.state.user.seller.isSeller,
      created_at: Math.round(store.state.user.created_at / 1000),
    };

    const logRocketObj = {...defaultValues, ...props }
    LogRocket.identify(store.state.user.id, logRocketObj);
  }
  */
}
const sendMessage = function (props) {
  if (!isIndexerBotOrDevelopmentEnv()) {
    Api.external.trackEvents(arrangeSendMessageToSaveAsRawEvent(props));

    if(!store.state.user.seller.isSeller && store.state.userActionsForSegmentation && store.state.userActionsForSegmentation.length && store.state.userActionsForSegmentation.length > 2 && store.state.userActionsForSegmentation.indexOf(props.userID) !== -1){
      store.state.userActionsForSegmentation = [];
      Api.feeds.addDynamicSegment();
      /*
      LogRocket.init('bjngqg/bionluk-yoxaf', {
        console: {
          isEnabled: {
            log: true,
            debug: true,
          },
        }
      });
       */


      /*LogRocket.identify(store.state.user.id, {
        username: store.state.user.username,
        email: store.state.user.email,
        isFreelancer: store.state.user.seller.isSeller,
        created_at: Math.round(store.state.user.created_at / 1000),
        dynamic_segment_id:"1"
      });
      */
    }
    let propsWithUnderscore = {};
    for (let key in props) {
      propsWithUnderscore[`props_${key}`] = props[key];
    }
    //  amplitude.getInstance().logEvent("sendMessage", arrangePageViewToSendToAmplitude(null, null, propsWithUnderscore));
    if(window && window.google_trackConversion){
      window.google_trackConversion({
        google_conversion_id: 962810877,
        google_conversion_language: "tr",
        google_conversion_format: "3",
        google_conversion_color: "ffffff",
        google_conversion_label: "drLnCKrPnmsQ_aeNywM",
        google_remarketing_only: false
      });

      ga('send', 'event', {
        'eventCategory': 'users',
        'eventAction': 'message send'
      });


      if(!store.state.user.seller.isSeller){
        ga('send', 'event', {
          'eventCategory': 'users',
          'eventAction': 'message send(buyer)'
        });
        fbq('trackCustom', 'sendMessageBuyer', props);
      }

      props['isSeller'] = store.state.user.seller.isSeller;
      fbq('trackCustom', 'sendMessage', props);

    }


  }
};

const customEvent = function (event, props, forceMerge = null, page=null) {
  if (!isIndexerBotOrDevelopmentEnv()) {

    Api.external.trackEvents(arrangeCustomEventToSaveAsRawEvent(event, props, forceMerge, page));

    let propsWithUnderscore = {};
    for (let key in props) {
      propsWithUnderscore[`props_${key}`] = props[key];
    }
    //  amplitude.getInstance().logEvent(event, arrangePageViewToSendToAmplitude(page, null, propsWithUnderscore));

  }
};

const modalView = function () {
  if (!isIndexerBotOrDevelopmentEnv()) {
    let type = store.state.activeModal.modalType;
    let version = store.state.modalComponentMap[type];
    let props = null;

    if(store.state.activeModal.info && store.state.activeModal.info.props){
      props = store.state.activeModal.info.props;
    }
    Api.external.trackEvents(arrangeOpenedModalToSaveAsRawEvent(type, version, props));
    //  amplitude.getInstance().logEvent(`openedModal_${type}`, arrangePageViewToSendToAmplitude(type, version));
  }
};


const setChatUsers = function (users) {
  store.state.chatUsers = [...users];
};

const setChat = function (uuid, object) {
  const key = `u_${uuid}`;
  store.state.chats = {
    ...store.state.chats,
    [key]: object,
  };
}

const getChat = function (uuid) {
  const key = `u_${uuid}`;
  if (uuid && store.state.chats.hasOwnProperty(key)) {
    return store.state.chats[key];
  } else {
    return {messages: []};
  }

}




const updateChatUserList = function (data, me ) {

  function playNotifSound() {
    if(store.state.isMobile) return false;
    const playSound = store.state.user.info.permissions.play_sound;
    if (playSound) {
      let snd = new Audio("https://gcdn.bionluk.com/site/general/gelen.mp3");
      let playPromise = snd.play();
      if (playPromise !== undefined) {
        playPromise.then(function() {

        }).catch(function(error) {
          console.error(error + 'Failed to start your sound, retrying.');
        });
      }
    }
  }

  const senderUsername = data.username;

  //mesaji gonderen ben isem , mesaji gordum diye guncelle ve son mesaji gonderen kisi id'si kendimi ekle
  data.isseen = senderUsername === me.username;
  if(store.state.activeConversationId === data.c_uuid){
    data.isseen = true;
  }
  if(senderUsername === me.username){
    data.last_message_user_id = me.id;
  }


  if(data.username !== me.username && store.state.activeConversationId !== data.c_uuid && store.state.user.unreadMessages.indexOf(data.r_username) === -1){

    let fullPath = store.state.route.fullPath;
    let firstSegmentOfPath = fullPath.split('/')[1];

    if(fullPath !== `/chat/${data.r_username}`){
      store.state.user.unreadMessageCount = store.state.user.unreadMessages.length + 1;
      store.state.user.unreadMessages.push(data.r_username);
      if(firstSegmentOfPath !== 'chat'){
        playNotifSound();
      }
    }

  }


  data.last_message_created_at = data.created_at; // chat/getmessages servisinden "last_message_created_at" keyi geliyor ama burda created_at geliyor.

  //sonra update et
    const cloneMessageUsers = [...store.state.chatUsers];

  let index = store.state.chatUsers.findIndex(m => m.c_uuid === data.c_uuid);
    // konusma var ise
    if (index > -1) {
      //guncelle ve konusmayi en başa çek
      cloneMessageUsers.splice(index, 1); //arrayden elementi sil
      cloneMessageUsers.splice(0, 0, data); // yeni gelen elementi ekle
      store.state.chatUsers = [...cloneMessageUsers];

    }
    // konusma yok ise
    else {
      data.isseen = false;
      cloneMessageUsers.unshift(data);
      store.state.chatUsers = [...cloneMessageUsers];
    }


}

export default {
  setChat,
  setChatUsers,
  getChat,
  updateChatUserList,
  sentryInit,
  sentryConfig,
  reject,
  setInitialValuesBeforeInit,
  setInitialValuesAfterInit,
  arrangeVisitorInfo,
  createUserPageProps,
  createHireBestFreelancersSearchPageProps,
  createProjectShowcaseDetailPageProps,
  isIndexerBotOrDevelopmentEnv,
  projectShowcaseAttachmentPageProps,
  blogDetailPageProps,
  blogDetailModalProps,
  staticPageProps,
  supportTopicPageProps,
  supportCategoryPageProps,
  chatPageProps,
  editGigPageProps,
  chatDetailPageProps,
  gigDetailPageProps,
  gigDetailPagePropsV2,
  orderDetailPageProps,
  checkoutPageProps,
  checkoutPageSinglePayProps,
  categoryListPageProps,
  ticketDetailPageProps,
  searchPageProps,
  userSearchPageProps,
  sendMessageProps,
  portfolioOpennedProps,
  goLandingPageProps,
  biaListPageProps,
  initLogrocketManual,
  projectShowcaseAttachmentPagePropsModal,
  generateUUID,
  trackEvents: {
    setUser,
    pageView,
    modalView,
    sendMessage,
    customEvent
  }
}

