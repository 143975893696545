/**
 * Created by haluk on 6/9/17.
 */

import helper from './axiosHelper';

const getContentURL = "/content/static_page/";
const getBlogURL = "/content/blog_topic/";

const saveCommentToBlogURL = "/content/save_blog_comment/";
const retrieveSupportCategoriesURL = "/content/get_support_categories/";
const retrieveSupportCategoriesURLv2 = "/content/get_support_categories_v2/";
const retrieveSupportTopicsURL = "/content/get_support_topics/";
const retrieveSupportTopicsURLV2 = "/content/get_support_topics_v2/";
const retrieveSupportTopicDetailURL = "/content/get_support_topic/";
const retrieveSupportTopicDetailURLv2 = "/content/get_support_topic_v2/";

const getBlogImageURL = "/content/getBlogImage/";
const getBlogURLv2 = "/content/blog_topic_v2_1/";

const getBlogListURLv4 = "/content/blog_list_v4/";
const getTicketCategoriesURL = "/content/get_ticket_categories/";
const getTicketCategoriesURLv2 = "/content/get_ticket_categories_v2/";
const getGoPageURL = "/content/get_go_page/";

const updateStoryImageURL = "/content/update_story_image/";
const changeStoryInfoURL = "/content/change_story_info/";

const renderListURL = "/content/render_list/";
const getTestURL = "/content/get_test/";
const completeTestURL = "/content/complete_test/";

const newEditorTaskURL = "/content/assign_task/";
const unassignEditorTaskURL = "/content/unassign_task/";
const initEditorURL = "/content/editor_init/";
const completeTaskEditorURL = "/content/complete_task/";
const getEditorLogsURL = "/content/get_editor_logs/";
const seoboyPushURL = "/content/seoboypush/";

const userAkbankURL = "/content/user_akbank/";

export default {
  getStaticPage(page) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getContentURL, false, helper.createFormData({page: page}));
  },

  getBlogListv4(offset = null, limit = 20, category = '', sub_category = '') {
    return helper.axiosWrapper(helper.axiosInstance, "post", getBlogListURLv4, false, helper.createFormData({offset, limit, category, sub_category}));
  },

  getBlog(page) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getBlogURL, false, helper.createFormData({page: page}));
  },

  getBlogImage(id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getBlogImageURL, false, helper.createFormData({id: id}));
  },

  getBlogv2(page) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getBlogURLv2, false, helper.createFormData({page: page}));
  },

  saveBlogComment(topicID, comment, lang) {
    return helper.axiosWrapper(helper.axiosInstance, "post", saveCommentToBlogURL, false, helper.createFormData({blog_topic_id: topicID, comment: comment, lang: lang}));
  },

  retrieveSupportCategories() {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveSupportCategoriesURL);
  },

  retrieveSupportCategoriesv2() {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveSupportCategoriesURLv2);
  },

  retrieveSupportTopics(slug = null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveSupportTopicsURL, false, helper.createFormData({page: slug}));
  },

  retrieveSupportTopicsv2(slug = null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveSupportTopicsURLV2, false, helper.createFormData({page: slug}));
  },

  retrieveSupportTopicDetails(slug) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveSupportTopicDetailURL, false, helper.createFormData({page: slug}));
  },
  retrieveSupportTopicDetailsv2(slug, referrer=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", retrieveSupportTopicDetailURLv2, false, helper.createFormData({page: slug, referrer}));
  },

  getTicketCategories(parent_id, type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getTicketCategoriesURL, false, helper.createFormData({parent_id, type}));
  },
  getTicketCategoriesv2(parent_id, type, current_persona_id=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getTicketCategoriesURLv2, false, helper.createFormData({parent_id, type, current_persona_id}));
  },

  getGoPage(slug) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getGoPageURL, false, helper.createFormData({slug}));

  },

  updateStoryImage(uploadName,externalID,blog_id,type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", updateStoryImageURL, false, helper.createFormData({uploadName:uploadName, externalID:externalID, blog_id:blog_id, type:type}));

  },
  changeStoryInfo(blog_id,color, style_type) {
    return helper.axiosWrapper(helper.axiosInstance, "post", changeStoryInfoURL, false, helper.createFormData({blog_id:blog_id,color:color, style_type:style_type}));

  },


  renderList(type,list) {
    return helper.axiosWrapper(helper.axiosInstance, "post", renderListURL, false, helper.createFormData({type:type, list:list}));

  },

  getTest(id,list) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getTestURL, false, helper.createFormData({id: id}));

  },

  completeTest(id,answers) {
    return helper.axiosWrapper(helper.axiosInstance, "post", completeTestURL, false, helper.createFormData({id: id, answers: answers}));

  },


  newEditorTask() {
    return helper.axiosWrapper(helper.axiosInstance, "post", newEditorTaskURL);
  },

  unassignEditorTask(id, comment) {
    return helper.axiosWrapper(helper.axiosInstance, "post", unassignEditorTaskURL, false, helper.createFormData({id, comment}));
  },

  initEditor() {
    return helper.axiosWrapper(helper.axiosInstance, "post", initEditorURL);
  },

  completeTaskEditor(id, result, selectedDisapprovalIds, selectedScoreDisapprovalId, selectedPortfolioScoreId, json_obj=null) {
    return helper.axiosWrapper(helper.axiosInstance, "post", completeTaskEditorURL, false, helper.createFormData({id, result, selectedDisapprovalIds, selectedScoreDisapprovalId, selectedPortfolioScoreId, json_obj}));
  },

  seoboyPush() {
    return helper.axiosWrapper(helper.axiosInstance, "post", seoboyPushURL, false);

  },

  userAkbank() {
    return helper.axiosWrapper(helper.axiosInstance, "post", userAkbankURL, false);

  },

	getEditorLogs(offset = null) {
		return helper.axiosWrapper(helper.axiosInstance, "post", getEditorLogsURL, false, helper.createFormData({offset: offset}));
	},

}
