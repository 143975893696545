<template>
      <div class="loader" style="text-align: center;"></div>
</template>

<script>

	export default {
		name: 'loader',
		data() {
			return {}
		}
	}

</script>

<style scoped>

</style>
