
import helper from './axiosHelper';


const createURL = "/request/create/";
const myRequestsURL = "/request/my_requests/";
const listURL = "/request/list_all/";
const getURL = "request/get/";
const editURL = "request/edit/";

export default {

  edit(request_uuid, title, description, category_id, category_sub_id, request_budget_id, request_duration_id, request_service_types, request_service_kinds, status, request_skills, upload_ids, budget=null, wordCount=null, is_plus='-1',request_meta_options='[]') {
    return helper.axiosWrapper(helper.axiosInstance, "post", editURL, false, helper.createFormData({request_uuid, title, description, category_id, category_sub_id, request_budget_id, request_duration_id, request_service_types, request_service_kinds, status, request_skills, upload_ids, budget, wordCount,is_plus,request_meta_options }))
  },

  create(title, description, category_id, category_sub_id, request_budget_id, request_duration_id, request_service_types, request_service_kinds, status, request_skills, upload_ids, budget=null, wordCount=null, is_plus=null, request_meta_options='[]') {
    return helper.axiosWrapper(helper.axiosInstance, "post", createURL, false, helper.createFormData({ title, description, category_id, category_sub_id, request_budget_id, request_duration_id, request_service_types, request_service_kinds, status, request_skills, upload_ids,budget, wordCount, is_plus, request_meta_options}))
  },

  myRequests(limit, offset) {
    return helper.axiosWrapper(helper.axiosInstance, "post", myRequestsURL, false, helper.createFormData({limit, offset}))
  },

  list(limit, offset, query_type='all', buyer_username= '') {
    return helper.axiosWrapper(helper.axiosInstance, "post", listURL, false, helper.createFormData({limit, offset,query_type, buyer_username}))
  },

  get(request_uuid) {
    return helper.axiosWrapper(helper.axiosInstance, "post", getURL, false, helper.createFormData({request_uuid}))
  },

}

