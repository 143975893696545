/**
 * Created by haluk on 4/16/17.
 */

import helper from './axiosHelper';

const chatOpenURL = '/chat/open/';
const sendMessageURL = '/chat/send/';
const seenMessageURL = '/chat/mark_seen/';
const sendWritingURL = '/chat/writing/';
const blockUserURL = '/chat/block/';
const unblockUserURL = '/chat/unblock/';
const getMessagesURL = '/chat/getmessages/';


// do not touch it!
const disabledProgress = false;

export default {
  chatOpen(receiverUsername, offset, limit, last_message_id) {
    return helper.axiosWrapper(helper.axiosInstance, "post", chatOpenURL, disabledProgress, helper.createFormData({ r_username: receiverUsername, offset, limit, last_message_id }));
  },

  sendMessage(type, conversationID, message, externalID, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", sendMessageURL, progress, helper.createFormData({type, c_uuid: conversationID, message, external_id: externalID}));
  },

  sendMessageV2(type, conversationID, message, externalID, forceBicoin, payload_id = null, last_item_id = '', props = null, progress) {
    return helper.axiosWrapper(helper.axiosInstance, "post", sendMessageURL, progress, helper.createFormData({type, c_uuid: conversationID, message, external_id: externalID, forceBicoin, payload_id, last_item_id, props}));
  },

  seenMessage(conversationID, receiver_username) {
    return helper.axiosWrapper(helper.axiosInstance, "post", seenMessageURL, disabledProgress, helper.createFormData({c_uuid: conversationID,receiver_username}));
  },

  sendWriting(conversationID, type, percentage, r_id, r_uuid) {
    return helper.axiosWrapper(helper.axiosInstance, "post", sendWritingURL, disabledProgress, helper.createFormData({c_uuid: conversationID, type, percentage, r_id, r_uuid}));
  },

  blockUser(uuid) {
    return helper.axiosWrapper(helper.axiosInstance, "post", blockUserURL, disabledProgress, helper.createFormData({r_uuid: uuid}));
  },

  unblockUser(uuid) {
    return helper.axiosWrapper(helper.axiosInstance, "post", unblockUserURL, disabledProgress, helper.createFormData({r_uuid: uuid}));
  },

  getConversations(filter, searchTerm = "", limit = null, offset = null, comingFrom) {

    return helper.axiosWrapper(helper.axiosInstance, "post", getMessagesURL, disabledProgress, helper.createFormData({ filter, offset, limit, search_term: searchTerm, comingFrom}));
  }

}
